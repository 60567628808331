/* eslint-disable jsx-a11y/anchor-is-valid */
import { ProColumns, EditableProTable, ActionType, ProCard, ProTable, ProDescriptions, ProFormText } from "@ant-design/pro-components";
import { useParams } from "react-router-dom";
import { positionProxyAPI } from "../../../api";
import React, { useState, useRef } from "react";
import { Button, Badge, message, Input, Space } from "antd";
import { CUSTODIAN_MAPPING } from "../../../utils/custodianMapping";
import TextArea from "antd/es/input/TextArea";
import { PositionGridTable } from "./PositionGridTable";
import { POSITION_COLUMNS } from "../../../constants/positionColumns";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/app/store";
import { fetchtableData, TableDataSelector } from "../../../redux/features/Tables/tableDataSlice";

interface DataProps {
  setCols?: any,
  type: string,
  data: any,
  rowId: number;
  drawerVisit: boolean;
  onChange: (id: number) => void;
  // exceptionCases: any;
  // openStatementCreateForm: (value: boolean) => void;
  setDrawerVisit: (value: boolean) => void;
  exceptionCases: any;
  setRefresh: (data: any) => void;
  onSave?: () => void;
  id: any;
  cols: any;
  custodianId: string
  assetTypeList: any;
  valObj: any;
}

const PositionStatementTables = (props: DataProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const tableData = useSelector(TableDataSelector);
  const map : any = {};
  CUSTODIAN_MAPPING.forEach((custodian) => {
    map[custodian.custodian_id] = custodian.custodian_code;
  })
  const { key } = useParams();
  let [dataSource, setDataSource] = useState<any>(props.data.Data);
  const [columns, setColumns] = useState<any>([]);

  React.useEffect(() => {
    setDataSource(props.data.Data);
  }, [props.data.Data,tableData])

  React.useEffect(() => {

    setDataSource(props.data.Data);
  }, [props.exceptionCases,tableData])

  React.useEffect(() => {
    setDataSource(props.data.Data);
  }, [props.data.Data])

  React.useEffect(() => {
    const allCols = Object.keys(POSITION_COLUMNS);
    const newColumns = allCols.map((col: any) => {
        return({
          columnId: col,
          width: 150,
        })
    })
    setColumns(newColumns);
  }, [props?.type]);


  const saveData = async (data: any) => {
   try{
    const tableData = {
      Key: props.type,
      DATA:data
    }

    await positionProxyAPI.editProxyData(key,tableData);
    dispatch(fetchtableData(key));
    if(props.onSave){
      props.onSave();
    }
    message.success("Data saved successfully");
   }catch(err){
    console.log(err);
    message.error("Error saving data");
   }
  }

  return (
    <>
      <PositionGridTable
        columns={columns}
        dataSource={dataSource}
        saveData={saveData}
      />
    </>
  )
}
export default PositionStatementTables;