import { EditOutlined } from "@ant-design/icons";
import { ActionType, ModalForm, ProFormGroup, ProFormText } from "@ant-design/pro-components"
import { useForm } from "antd/es/form/Form";
import { blotterDataAPI } from "../../api";
import { selectUser } from "../../redux/features/userSlice";
import { useSelector } from "react-redux";

interface IEditBlotterModalProps {
    record: any;
    actionRef: React.MutableRefObject<ActionType | undefined>;
}

const EditBlotterModal = (props:IEditBlotterModalProps) => {
    const ethanToken= useSelector(selectUser);
    const {record,actionRef} = props;
    const [form] = useForm();
    return(
        <ModalForm
        form={form}
        trigger={
            <EditOutlined  style={{marginLeft:"1rem", color:"#00cefff7"}} /> 
       }
       onFinish={async (values) => {
            await blotterDataAPI.updateBlotterRow(record?.trade_id,values,ethanToken);
            actionRef.current?.reload();
            return true;
       }}
        >
            <ProFormGroup>
            {
                Object.keys(record).map((key) => {
                    return(
                        <ProFormText
                            width="sm"
                            name={key}
                            label={key}
                            initialValue={record[key]}
                        />
                    )
                }
            )}
            </ProFormGroup>
        </ModalForm>
    )
}

export default EditBlotterModal;