
import type { ProCardTabsProps } from '@ant-design/pro-components';
import { ProCard } from '@ant-design/pro-components';
import { Select, Space } from 'antd';
import { useState } from 'react';
import PositionParsingComponent from './PositionParsing.componenent';
import PositionParsingEdit from './PositionParsingEdit';

const { Option } = Select;

enum POSITION_TABS {
    EDIT = 'Edit',
    ALL = 'ALL'
}

const  PositionParsing =()=> {
  const [tab, setTab] = useState(POSITION_TABS.EDIT);

  return (
    <div>
      
      <ProCard
        tabs={{
          tabPosition: "top",
          activeKey: tab,
          items: [{
            label : ` Edit Data` , 
            key: POSITION_TABS.EDIT,
            children : <PositionParsingEdit/> , 
          },
            {
              label : ` Approved Data` , 
              key: POSITION_TABS.ALL,
              children : <PositionParsingComponent/>, 
            },
          ],
          onChange: (key:any) => {
            setTab(key);
          },
        }}
      />
    </div>
  );
};

export default PositionParsing