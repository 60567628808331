

import { EditOutlined } from '@ant-design/icons';
import {
  ModalForm,
  ProForm,
  ProCard,
  ProFormText,
} from '@ant-design/pro-components';
import { Button, Form, message,Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { statementAPI} from '../../../api';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import StatementDrawer from '../StatementDrawer/StatementDrawer';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
/* @ts-ignore */
import demo from './Demo.pdf';
interface DataModalProps {
  columns: string[];
  record: any; 
  setRefresh: (data: any) => void;
}

const DataModal = (props: DataModalProps) => {
    const [form] = Form.useForm<any>();
    const [loading,setLoading] = useState<boolean>(false);
    const [columns, setColumns] = useState<string[]>(props.columns);
    const [url,setUrl] = useState<any>('');
    const [hasPdf,setHasPdf] = useState<any>(false);
    const {key} = useParams();
  
    useEffect(() =>{
        form.setFieldsValue({
          ...props.record,
        })
    }, [props?.record])

    // const getStatement = async() => {
    //   setLoading(true);
    //   const {file} = await statementAPI.getDetails(key);
    //   const data = {
    //     file_path: file.path
    //   }
    //   const {url} = await statementAPI.getPdf(data,key)
    //   if(url){
    //     setLoading(false);
    //     const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    //     const pdfDoc = await PDFDocument.load(existingPdfBytes)
    //     const pdfBytes = await pdfDoc.save()
    //     setUrl(pdfBytes);
    //     setHasPdf(true);
    //   }else{
    //     setUrl(true);
    //   }
    // }


  const rotatePluginInstance = rotatePlugin();
  const { RotateBackwardButton, RotateForwardButton } = rotatePluginInstance;
    // const [form] = Form.useForm<any>();
    // const [url,setUrl] = useState<any>('');
    // const [hasPdf,setHasPdf] = useState<any>(false);
    // const {key} = useParams();


    const getStatement = async() => {
      const {file} = await statementAPI.getDetails(key);
      const data = {
        // file_path: file.path
        file_path: file.path
      }
      // console.log(data);
      // console.log("file",file);
      const {url} = await statementAPI.getPdf(data,key);
      if(url){
        // const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
        // const pdfDoc = await PDFDocument.load(existingPdfBytes)
        // const pdfDoc = await PDFDocument.load(url)
        // const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
        // const pages = pdfDoc.getPages()
        // const firstPage = pages[0]
        // const { width, height } = firstPage.getSize();
        // firstPage.translateContent(0,height)
        // const pdfBytes = await pdfDoc.save()
        setUrl(url);
        setHasPdf(true);
      }
    }

    useEffect(() =>{
      getStatement();
    }, [])




    return (
        <ModalForm<{
          name: string;
          company: string;
          _id: string
        }>
          width='90%'
          style={{
            height:'100%',
          }}
          title="Edit Refinery Data"
          trigger={
               <EditOutlined  style={{marginLeft:"1rem", color:"#00cefff7"}} /> 
          }
          form={form}
          autoFocusFirstInput
          modalProps={{
            // onCancel: () => console.log('run'),
          }}
          submitTimeout={2000}
          onFinish={async (values) => {
            // console.log(values);
            await statementAPI.modify(values?._id,`${key}`,values)
            await props?.setRefresh(values)
            return true;
          }}
        >
           <ProCard split="vertical">
              <ProCard title = "Edit Data" colSpan = "30%" >  
                <ProForm.Group>
                  <ProFormText width="sm" name="_id" label="ID" disabled={true} />
                    {columns?.length === 0 ? null : (
                      columns?.map?.((column:any) => {
                        if(column !="_id"){
                          return (
                            <ProFormText width="sm" name={column} label={column} />
                           )                          
                        }

                    })
                 )}
                </ProForm.Group>
              </ProCard>
              <ProCard title = "Statement View" >
              {
              hasPdf && <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
              <div
            className="rpv-core__viewer"
            style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '4px',
                }}
            >
                <div style={{ padding: '0px 2px' }}>
                    <RotateBackwardButton />
                </div>
                <div style={{ padding: '0px 2px' }}>
                    <RotateForwardButton />
                </div>
            </div>
            <div
                style={{
                    flex: 1,
                    overflow: 'hidden',
                }}
            >

                <Viewer fileUrl={url} plugins={[rotatePluginInstance]}/>
            </div>
        </div>
            </Worker>
            }
              {/* <StatementDrawer /> */}
                {/* <Button type='primary' onClick={getStatement} style={{marginBottom: '1em'}}>Request statement</Button>
                {
                  loading && <Spin tip="Loading" size="large" style={{margin:'10em auto'}} delay={2000}/>
                }
                {
                  !loading &&
                  hasPdf && <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
                  <div style={{overflowY:'scroll',height: '700px',width: '100%'}}>
                    
                    <Viewer fileUrl={url} />
                    
                  </div>
                </Worker>
                }   */}
              </ProCard>
            </ProCard>
        </ModalForm>
    );
}
export default DataModal;