/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom';
import { ProCard } from '@ant-design/pro-components'
import PositionStatementTables from './PositionStatementTables';
import type { ProCardTabsProps } from '@ant-design/pro-components';
import { useState } from 'react';
import { positionProxyAPI, validationPositionAPI } from '../../../api';
import PositionDrawerDetails from './PositionDrawerDetails';
import { Button, Modal, message } from 'antd';
import type { AppDispatch } from '../../../redux/app/store';

import { CUSTODIAN_MAPPING } from '../../../utils/custodianMapping';
import { useDispatch, useSelector } from 'react-redux';
import {fetchtableData, TableDataSelector} from '../../../redux/features/Tables/tableDataSlice';

interface DataProps {
  custodianId: string
  resData: any
  setRefresh: (data: any) => void;
}

function PositionStatements(props: DataProps) {
  const map: any = {};
  CUSTODIAN_MAPPING.forEach((custodian) => {
    map[custodian.custodian_id] = custodian.custodian_code;
  })
  const { key } = useParams();
  const [cols, setCols] = useState([]);
  const [tabPosition, setTabPosition] = useState<ProCardTabsProps['tabPosition']>('top');
  const [id, setId] = useState(0);
  const [drawerVisit, setDrawerVisit] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [exceptionCases, setExceptionCases] = useState<any>();
  const [refresh, setRefresh] = useState<Boolean>();
  const [deleted, setDeleted] = useState(false);
  const [valObj, setValObj] = useState<any>({});
  const [assetTypeList, setAssetTypeList] = useState<any>([]);
  const [validation, setValidation] = useState<string>(new Date().toISOString());
  const tableData = useSelector(TableDataSelector);
  const [data, setData] = useState<any>(JSON?.parse(JSON?.stringify?.(tableData?.mapped)));
  const [tab, setTab] = useState(tableData?.mapped?.[0]?.Key);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchtableData(key));
    const fetchData = async () => {
      const { data } = await positionProxyAPI.getMappings();
      const custodianCode = map[props.custodianId];
      const valObj = data[custodianCode]?.mapping;
      setValObj(valObj);
      const val = Object.keys(data[custodianCode].mapping);
      setAssetTypeList(val);
    }
    fetchData();
    setExceptionCases(tableData.exceptions);
  }, []);

  useEffect(() => {
    // dispatch(fetchtableData(key));
    setData(JSON?.parse(JSON?.stringify?.(tableData?.mapped)));
    const fetchData = async () => {
      const { data } = await positionProxyAPI.getMappings();
      const custodianCode = map[props.custodianId];
      const valObj = data[custodianCode]?.mapping;
      setValObj(valObj);
      const val = Object.keys(data[custodianCode].mapping);
      setAssetTypeList(val);
    }
    fetchData();
    setExceptionCases(tableData.exceptions);
    // globalThis.window?.location.reload();
  }, [tableData]);

  useEffect(() => {
    dispatch(fetchtableData(key));
  },[]);


  const onClick = () => {
    setIsModalOpen(true);
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  }

  const deleteException = async () => {
    try {
      const status = await positionProxyAPI.deleteException(key);
      if (status.status === 'success') {
        messageApi.open({
          type: 'success',
          content: 'Successfully Deleted',
        });
        handleCancel();
        setDeleted(true);
      } else {
        messageApi.open({
          type: 'error',
          content: 'Could not Delete',
        });
      }
    } catch (err) {
      message.warning('Could not delete');
    }
  }

  const ignoreAll = async () => {
    try {
      const data = {
        ignore: true,
      }
      const status = await positionProxyAPI.ignoreAllExceptions(key, data);
      // fetchPositionTableData();
      
      if (status.status_message === 'Success') {
        dispatch(fetchtableData(key));
        message.success('ignored all');
      }
    } catch (err) {
      message.error('could not ignore all');
    }
  }
  
  const runValidation = async (key: String) => {
    const response = await positionProxyAPI.getMappedData(key);
    if (response._key == key) {
      try {
        const obj = {
          id: key,
          stage: "STAGE_1",
        }
        // props.setRefresh(response._key);
        await validationPositionAPI.create(obj);
        dispatch(fetchtableData(key));

        // fetchPositionTableData();
        message.success("Validation Successful");

      } catch (error) {
        // console.log(error)
        message.error("Validation Failed");
      }
    }
  }

  const cleanData = async () => {
      try {
        await positionProxyAPI.cleaningPosition(key);
        message.success("Cleaning Successful");
      } catch (error) {
        message.error("Cleaning Failed");
      }
  }

  return (
    <div>
      <Modal title="Warning!" open={isModalOpen} onCancel={handleCancel} onOk={deleteException}>
        <p>This Position statement has exceptions, Do you still want to delete?</p>
      </Modal>
      <ProCard
        style={{
          overflowX: 'scroll',
          display: `${deleted ? 'none' : 'block'}`
        }}
        extra={
          <>
            <Button
              type="primary"
              key="save"
              onClick={async () => {
                runValidation(key as string);
              }}
            >
              Run Validation
            </Button>
            <Button
              type="dashed"
              key="save"
              onClick={async () => {
                cleanData();
              }}
            >
              Clean Data
            </Button>
            <Button onClick={onClick} danger style={{ margin: '0 1em' }}>Delete</Button>
            <Button danger onClick={ignoreAll}>Ignore all exceptions</Button>
          </>
        }
        tabs={{
          tabPosition,
          activeKey: tab,
          items: data?.map((table: any) => {
            return {
              label: table.Key,
              key: table.Key,
              children: (
                <PositionStatementTables
                  key={table.Key}
                  type={table.Key}
                  data={table}
                  onChange={(item: any) => setId(item)}
                  rowId={id} setDrawerVisit={setDrawerVisit}
                  drawerVisit={drawerVisit} setCols={setCols}
                  exceptionCases={exceptionCases}
                  setRefresh={() => setRefresh((prev) => !prev)}
                  onSave={() => {
                    // fetchPositionTableData();
                    dispatch(fetchtableData(key));
                    runValidation(key as string);

                  }}
                  id={id}
                  cols={cols}
                  custodianId={props.custodianId}
                  assetTypeList={assetTypeList}
                  valObj={valObj}
                />
              )
            }
          }),
          onChange: (key) => {
            setTab(key);
          },
        }}
      />
      {/* <PositionDrawerDetails
        id={id}
        drawerVisit={drawerVisit}
        setDrawerVisit={setDrawerVisit}
        cols={cols}
        onSave={fetchPositionTableData}
      /> */}
    </div>
  )
}

export default PositionStatements