import { ProFormSelect } from "@ant-design/pro-components"
import { CURRENCY_CODES } from "./currencyList";

export const CURRENCY_OPTIONS = CURRENCY_CODES.map((currency: string) => ({
  label: currency,
  value: currency,
}));

interface IProps {
  rules?: any;
  label?: string;
  name?: string;
  placeholder?: string;
  showSearch?: boolean;
}

const CurrencyFormSelect = (props: IProps) => {
  const { rules } = props;
    return (
      <ProFormSelect 
          name={props.name || "currency"}
          label={props.label || "Currency"}
          rules={rules}
          options={CURRENCY_OPTIONS}
          placeholder={props.placeholder || "Select Currency"}
          showSearch={props.showSearch || true}
        />
    )
}

export default CurrencyFormSelect;

