import { ActionType, ProColumns } from '@ant-design/pro-components';
// import { Table } from 'antd'
import { SortOrder } from 'antd/es/table/interface';
import React, { useRef, useState } from 'react'
import Table from "../../Refinary/common/Table";
import request from 'axios';


type TickerTableData = {
price_factor: number,
lot_size: number,
ticker: String,
value: number, 
price_source: String,
price_ticker: String,
}

const columns: ProColumns<TickerTableData>[] =[
    {
        dataIndex: 'price_factor',
        title: "Price Factor",
        search: false,
        width: 100,
        key: 'price_factor',
    },
    {
        dataIndex: 'lot_size',
        title: "Lot Size",
        search: false,
        key: "lot_size"
    },
    {
        dataIndex: 'ticker',
        title:"Ticker",
        fixed: 'left',
        copyable: true,
        key: 'ticker'

    },
    {
        dataIndex: 'value',
        title:"value",
        search: false,
        copyable: true,
        key: 'value'
    },
    {
        dataIndex: 'price_source',
        title:"price_source",
        search: false,
        key: 'price_source'
    },
    {
        dataIndex: 'price_ticker',
        title:"price_ticker",
        search: false,
        key: 'price_ticker'
    },
];

function TickerStatements() {

    const [isLoading,setIsLoading]=useState<Boolean>(true);
    const pageSize= 10;
    const Ref = useRef<ActionType>();

  return (
        <Table<TickerTableData>
            columns={columns}
            bordered
            actionRef={Ref}
        request={async (params = {}, sortObject: Record<string, SortOrder>) => {
            const ordering = Object.keys(sortObject).map((key) => {
              const value = sortObject[key];
              return `${value === "ascend" ? "" : "-"}${key}`;
            })?.join();
  
            const { pageSize: pageSizeLimit, current, ...customParams} = params;
            const response = await request.get(`/securityMaster/ticker-mapping`, {
              params: {
                ...customParams,
                limit: pageSizeLimit,
                ordering,
                offset: ((current as number) - 1) * (pageSizeLimit as number),
              },
            })
              const obj = {
                total: response.data.length,
                data: response.data,
            }
            setIsLoading(false);
            return obj;
          }}
          editable={{
            type: 'multiple',
          }}
          columnsState={{
            persistenceKey: 'ticker-table',
            persistenceType: 'localStorage',
          }}
        //   rowKey="id"
          options={{
            setting: {
              listsHeight: 400,
            },
          }}
          form={{
            syncToUrl: (values, type) => {
              if (type === 'get') {
                return {
                  ...values,
                  created_at: [values.startTime, values.endTime],
                };
              }
              return values;
            },
          }}
          pagination={{
            pageSize,
          }}
          search={{
            filterType: 'light',
            collapsed: false,
            collapseRender: false,
          }}
        scroll={{ x: 1200 }}
       
            />
    )
}

export default TickerStatements