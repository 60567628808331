/* eslint-disable */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
// eslint-disable-file no-use-before-define 
/* eslint no-use-before-define: 0 */  // --> OFF
/* eslint-disable no-use-before-define */

import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { positionProxyAPI } from "../../../api";
import type { PayloadAction } from '@reduxjs/toolkit'

const initialTableData = {
  tableLoader: false,
  table: [],
  error: "",
};

export const fetchtableData = createAsyncThunk(
  "tableData/data",
  async(key :any ) => {
    const res= await positionProxyAPI.getMappedData(key);
    return {res}
  }
);
const tableDataSlice = createSlice({
    name: "tableData",
    initialState: initialTableData,
    extraReducers: (builder) => {
        builder.addCase(fetchtableData.pending, (state) => {
            state.tableLoader = true;
        });
        builder.addCase(fetchtableData.fulfilled, (state, action:PayloadAction<any>) => {
            console.log(action)
            state.tableLoader = false;
            state.table = action.payload?.res;
            state.error = "";
        });
        builder.addCase(fetchtableData.rejected, (state, action:PayloadAction<any>) => {
            state.tableLoader = false;
            state.table = [];
            state.error = action.payload;
        });
    },
    //Write reducer according to above action
    reducers: {
        tableDataReducer: (state, action) => {
            state.table = action.payload;
        },
    },

});
const table = (state:any) => state.tableData;

export const TableDataSelector = createSelector([table],(data)=> data.table);
export default tableDataSlice.reducer;