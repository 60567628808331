/* eslint-disable import/no-anonymous-default-export */
import { Tag, Tooltip } from "antd";

export enum PROCESS_STATUS {
  PENDING = "PENDING",
  UPLOAD_FAILED = "UPLOAD_FAILED",
  UPLOADED = "UPLOADED",
  RETRY_UPLOAD = "RETRY_UPLOAD",
  PROCESSING = "PROCESSING",
  PROCESSING_FAILED = "PROCESSING_FAILED",
  RETRY_PROCESSING = "RETRY_PROCESSING",
  PROCESSED = "PROCESSED",
  SUCCESS = "SUCCESS",
  COMPLETED = "COMPLETED",
}

export const processStatus = {
  [PROCESS_STATUS.PENDING]: {
    color: "cyan",
    text: "Pending Upload",
  },
  [PROCESS_STATUS.UPLOAD_FAILED]: {
    color: "red",
    text: "Upload Failed",
  },
  [PROCESS_STATUS.UPLOADED]: {
    color: "yellow",
    text: "Uploaded",
  },
  [PROCESS_STATUS.RETRY_UPLOAD]: {
    color: "cyan",
    text: "Retry Upload",
  },
  [PROCESS_STATUS.PROCESSING]: {
    color: "blue",
    text: "Processing",
  },
  [PROCESS_STATUS.PROCESSING_FAILED]: {
    color: "red",
    text: "Processing Failed",
  },
  [PROCESS_STATUS.RETRY_PROCESSING]: {
    color: "cyan",
    text: "Retry Processing",
  },
  [PROCESS_STATUS.PROCESSED]: {
    color: "lime",
    text: "Processed",
  },
  [PROCESS_STATUS.SUCCESS]: {
    color: "green",
    text: "Success",
  },
  [PROCESS_STATUS.COMPLETED]: {
    color: "green",
    text: "Completed",
  },
};


export default (props: { status: PROCESS_STATUS, message?: string }) => {
  const { status } = props;
  if(!processStatus?.[status]){
    return <p>{status}</p>;
  }
  // First 250 characters of the message
  return (
    <Tooltip title={props?.message?.slice(0, 250)}>
      <Tag color={processStatus?.[status]?.color}>
        {processStatus?.[status]?.text}
      </Tag>
    </Tooltip>
  );
}
