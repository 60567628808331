import { ProForm, ProFormInstance, ProFormSelect } from '@ant-design/pro-components'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customerAPI } from '../../api';
import { client, selectUser } from '../../redux/features/userSlice';

interface ShowProp {
  show: Boolean
}
const SelectCustomer = (props: ShowProp) => {
  const {show}= props; 
  const navigate= useNavigate();
  const dispatch=useDispatch();
  const selected= useSelector(selectUser);
  const [customerList, setCustomerList] = useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      const fetchCustomerList = await customerAPI.getCustomers();

      setCustomerList(fetchCustomerList);
    };
    fetchData();
  }, []);
  const ref= useRef<ProFormInstance>();
  const Options= customerList?.map((data: {
    name: string;
    access_token: string;
  })=>({
    label: data.name,
    Value: data.access_token,
  })) || [];

    const changeName = (value: number,data:any) => {
      dispatch(client((value)));
      navigate('/statements')
    }

    useEffect(()=>{
      ref.current?.resetFields()
    },[selected])
    return (
    <div >
<ProForm
 formRef={ref}
 initialValues={{
  select : selected

}}
submitter={{
  resetButtonProps:{
    style:{
      display: 'none',
    }
  },
  submitButtonProps:{
    style:{
      display: 'none',
    }
  },
}}
>
{show && <h1 style={{
  marginTop:'15em'
}}>Please Select a Client</h1>}
<ProFormSelect  options={Options.map((data)=>({label: data.label, value: data.Value}))} 
        name='select'
        fieldProps={{
          onChange:(_,x) => changeName(_,x),
        }}
        allowClear={false}
        />
        </ProForm>
    </div>
  )
}

export default SelectCustomer