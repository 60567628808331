import { DrawerForm, ProFormDatePicker } from "@ant-design/pro-components";
import { Button, message } from "antd";
import React from "react";
import { fxAPI, IPatchStatement, statementAPI } from "../../api";
import { EditOutlined } from "@ant-design/icons";

interface IProps {
  statementId: string;
}


const patchStatement = async (statementId: string, values: IPatchStatement) => {
  const hide = message.loading('Action in progress..');
  try {
    await statementAPI.patchStatement(statementId, values);
    hide();
    message.success('Action successful.');
    return true;
  } catch (error) {
    hide();
    message.error('Action failed, please try again.');
    return false;
  }
}

const StatementForm = (props: IProps) => {
  const { statementId } = props;
  const [initialValues, setInitialValues] = React.useState<IPatchStatement>();
  const getStatementById = async (statementId: string) => {
    const payload = await statementAPI.getDetails(statementId);
    setInitialValues(payload as IPatchStatement);
  }
  React.useEffect(() => {
    if (statementId) {
      getStatementById(statementId);
    }
  }, [statementId]);
  return (
    <>
    <DrawerForm<IPatchStatement>
      title="Update Statement"
      initialValues={initialValues}
      trigger={
        <Button>
          Statement
          <EditOutlined />
        </Button>
      }
      onFinish={async (values) => {
        const success = await patchStatement(statementId, values);
        if (success) {
          fxAPI.getFXRate(statementId);
          return true;
        }
        return false;
      }}
    >
      <ProFormDatePicker
        name="report_date"
        label="Report Date"
        rules={[{ required: true, message: 'Report Date is required' }]}
        />
    </DrawerForm>
    </>
  )
}

export default StatementForm;