import { useEffect,useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { tradeProxyAPI, positionProxyAPI, statementAPI, fxAPI, tableMappingAPI } from "../../api";
import { PageContainer, ProCardTabsProps } from '@ant-design/pro-components' ;  
import { ProDescriptions,ProCard } from "@ant-design/pro-components";
import ProcessingExceptionTable, { ProcessingExceptions } from "./ExceptionProcessing";
import { Badge, Button, message } from "antd";
import PositionsValidation from '../../Routes/PositionsValidation/PositionsValidation';
import DataListTable from "./Tables/DataTable";
import FxRatesDrawerForm from "./FxRatesDrawerForm";
import FxRateDetails from './Tables/FxRateDetails';
import StatementForm from "./StatementForm";
import RefineData from "./RefineData";
import StatementDrawer from "./StatementDrawer/StatementDrawer";
import RefinaryExe from "../RefinaryInitiate/RefinaryExe";
import { ConsoleSqlOutlined, PlayCircleFilled} from "@ant-design/icons";
import { tab, selectTab } from '../../redux/features/tabSlice';
import { useDispatch, useSelector } from "react-redux";
import { processStatus } from "../RefinaryInitiate/components/ErrorBadge";
import { fetchtableData } from "../../redux/features/Tables/tableDataSlice";
import { AppDispatch } from "../../redux/app/store";
import PositionDataTable from "./PositionDataTable";

enum StatementTabs {
  DETAILS= "DETAILS",
  STATUS_MESSAGE = "STATUS_MESSAGE",
  DATA = "DATA",
  PROCESSING_EXCEPTIONS = "PROCESSING_EXCEPTIONS"
}

const StatementDetailsPage = () => {
    // const dispatch = useDispatch();
    const selected= useSelector(selectTab);
    const {key,id} = useParams();
    const [payload,setPayload] = useState<any>({});
    const [statusMessage, setStatusMessage] = useState<string>("");
    const [reportDate, setReportDate] = useState<string>("");
    const [data, setData] = useState<any>();
    const [hasExceptions,setHasExceptions] = useState(false);
    const [positionExceptions,setPositionExceptions] = useState<boolean>(false);
    const [configurationMapping, setConfigurationMapping] = useState<any>([]);
    const [outputTable, setOutputTable] = useState<any>({});
    const [positionProcessingErrors, setPositionProcessingErrors] = useState<ProcessingExceptions[]>([]);
    const [positionStatusMessage, setPositionStatusMessage] = useState<string>("");
    const [ dataTab , setDataTab ] = useState<any>() ;
    const [ statementTab , setStatementMainTab ] = useState ( 'status message' ) ;
    const [processingError, setProcessingError] = useState<any>([]);
    const [show,handleShow] = useState(false);
    const [disabled,setDisabled] = useState(false);
    const [uniqueId,setUniqueId] = useState<any>({});
    const [posValData,setPosValData] = useState<any>([]);
    const [tradeData,setTradeData] = useState<any>([]);
    const [fxData,setFxData] = useState<any>("");
    const [resData, setResData] = useState<any>();
    // const [positionValidated,setPositionValidated] = useState<boolean>(false);

    const mapping = {
        tableName: "Table Name"
    }

    const fetchTableMapping = async(id: string, custodian_id: string) => {
      const response = await tableMappingAPI.getTableMappingByCustodianId(custodian_id);
      setConfigurationMapping(response);
      const outputTable = await tableMappingAPI.get_table_mapping_by_refineryId(id);
      setOutputTable(outputTable);
    }

    const fetchData = async() => {
      const response = await statementAPI.getDetails(key);
      setProcessingError(response?.processing_error);
      setStatusMessage(response?.status_message);
      setData(response?.data);
      setPayload(response);
      setReportDate(response?.report_date || response?.statement?.report_date);
      fetchTableMapping(response?._key, response?.custodian_id);
      if(response?.data?.Data?.length>0){
        setDataTab(response?.data?.Data?.[0]?.["Table Name"]);
        setDisabled(false)
      }
      else{
        message.error("No Refinery Data");
        setDisabled(true);
      }
    }

    // const fetchStagingData = async() => {
    //   const response = await positionProxyAPI.getStageData(key);
    //   if(response?.status === 'Validated'){
    //     setPositionValidated(true);
    //   }else{
    //     setPositionValidated(false);
    //   }
    // }

    // useEffect(() => {
    //   fetchStagingData();
    // },[])

    const startMapping = async (data:any) => {
      try{
        await positionProxyAPI.postMapping(key, {});
        window.location.reload();
      }catch(errorResponse: any){
        message.error(errorResponse?.response?.data?.detail);
      }
    };
    const [refresh,setRefresh] =useState<Boolean>(); 

    const fetchExceptionData = async() => {
      try{
        const res = await positionProxyAPI.getMappedData(key);
        console.log(res);
        setPosValData(res?.mapped);
        setResData(res);
        if(res.exceptions){
        setHasExceptions(true);
        }
        setPositionStatusMessage(res?.status_message);
        setPositionProcessingErrors(res?.processing_exceptions)
      }catch(err){
        console.log(err);
        setPositionProcessingErrors([]);
      }
    }

    useEffect(() => {
      fetchExceptionData();
    },[]);

    useEffect(() => {
        fetchData()
    },[]);

    useEffect(() => {
      const fetchTrades = async() => {
        const res = await tradeProxyAPI.getProxybyId(`${key}`);
        setTradeData(res)
      }
      fetchTrades();
    },[]);


    useEffect(() => { 
      const fetchFx = async() => {
        try{
          const data = await fxAPI.getFXRate(`${key}`);
          if(data.status_code == '404'){
            setFxData("disabled")
          }
        }catch(err){
          console.log(err);
            setFxData("disabled")
        }
      }
      fetchFx();
    },[])


    useEffect(() => {
        fetchData()
    },[refresh]);

  const onClick = async() => {
    const res = await statementAPI.addId(key);
    if(res){
      message.success('unique id added');
    }
    setUniqueId(res);
  }
  useEffect(() => {
    fetchData()
  },[uniqueId])

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(fetchtableData(key));
  },[]);

    return(
      <>
      <PageContainer
          header={{
            title: 'Statement Details',
            ghost: true,
            breadcrumb: {
              routes: [
                {
                  path: '/statements',
                  breadcrumbName: 'Statements',
                }
              ],
            } as any,
            extra: [
              <StatementDrawer />,
              <FxRatesDrawerForm statementId={key as string} />,
              <StatementForm statementId={key as string} />,
              <Button onClick={onClick} disabled={disabled}  type="link"><PlayCircleFilled />Start Refinery Process</Button>
              ,
              <Button type="link" onClick={startMapping} disabled={disabled}><PlayCircleFilled />Start Position Validation</Button>,
              <RefineData/>
            ],
          }}
        >
        <ProDescriptions  column = { 3 } >
            {
              Object.keys(payload).map((item :any) => {
                if(item === 'client_name' || item === 'custodian_name' || item === 'report_date'){
                  return <ProDescriptions.Item label={item}>{JSON.stringify(payload[item])}</ProDescriptions.Item>
                }
              })
            }
        </ProDescriptions>
        <ProCard
          tabs={{
            tabPosition: "left",
            activeKey: statementTab,
            items:[
              {
                label : <Badge status={payload ? 'success': 'error'} text="Details"/>, 
                key: StatementTabs.DETAILS,
                children : <ProDescriptions column = { 1 } >
                  {
                    Object.keys(payload).map((item :any) => {
                      if(item === 'status' || item === 'client_id' || item === 'statement_id' || item === '_key' || item === 'custodian_id' || item === "statement"){
                        return <ProDescriptions.Item label={item}>{JSON.stringify(payload[item])}</ProDescriptions.Item>
                      }
                      if(item === 'file'){
                         const name = payload[item];
                         return <ProDescriptions.Item label={item}>{name.path}</ProDescriptions.Item>
                      }
                    })
                  }
                </ProDescriptions>
              } ,
              {
                label : <Badge status={!statusMessage ? 'success': 'error'} text="Status Message"/>, 
                key:StatementTabs.STATUS_MESSAGE, 
                children : <>
                  <ProDescriptions>
                    <ProDescriptions.Item label="Refinery Message" valueType="code">{statusMessage}</ProDescriptions.Item>
                  </ProDescriptions>
                  <ProDescriptions>
                    <ProDescriptions.Item label="Position Status Message" valueType="code">{positionStatusMessage}</ProDescriptions.Item>
                  </ProDescriptions>
                </>,
              },
              {
                label : <Badge status={data ? 'success': 'error'} text="Refinery Data"/>,
                key:StatementTabs.DATA,
                children : <ProCard
                style={{
                  overflowY: 'scroll'
                }}
                colSpan={ 24 }
                tabs = {
                  {
                  tabPosition: "top" ,
                  activeKey : selected.tab || dataTab,
                  items :
                  data?.Data?.map((tableData:any, index: number) => {
                    return {
                        label: tableData?.[mapping?.["tableName"]],
                        key: tableData?.[mapping?.["tableName"]],
                        children: <DataListTable key={index} outputTable={outputTable[tableData?.[mapping?.["tableName"]]]} tableName={tableData?.[mapping?.["tableName"]]} configuration={configurationMapping} data={tableData} setRefresh={()=>setRefresh((prev)=>!prev)}/>
                    }
                  }),
                  onChange : ( key ) => {
                    setDataTab( key );
                    dispatch(tab(key))
                  } ,
                } }
              /> ,
              },
              {
                label : <Badge status={processingError ? 'error': 'success'} text="Processing Exceptions"/> , 
                key:StatementTabs.PROCESSING_EXCEPTIONS,
                children : <ProcessingExceptionTable processingMessages={[...(processingError || []),...(positionProcessingErrors || [])]} payload={payload}/> , 
              },
              {
                label : <Badge status={fxData === 'disabled' ? 'default' : 'success'} text="Fx Rates"/> ,
                key:"Fx Rates",
                children : <FxRateDetails  statementId={key as string} reportDate={reportDate} />,
                disabled: fxData === 'disabled'
              },
              {
                label: <Badge status={posValData.length < 1 ? 'default' : hasExceptions ? 'error' : 'success'} text="Position Validation" />,
                key:"Position Validation",
                children : <PositionsValidation custodianId={payload?.custodian_id} resData={resData} setRefresh={()=>setRefresh((prev)=>!prev)} />,
                disabled: posValData.length < 1
              },
              {
                label: <Badge status={tradeData.length < 1 ? "default" : 'success'} text="Trades Validation" />,
                key:"Trades Validation",
                children : <RefinaryExe />,
                disabled: tradeData.length < 1
              }
            ],
            onChange:(key)=>{
              setStatementMainTab(key);
            },
          }}
        />
        </PageContainer>
      </>
    )
};

export default StatementDetailsPage;
