import { FC, useMemo } from "react";
import styled from 'styled-components';
import * as _ from "radash";
import { Droppable } from "../premitives";
import { DraggableElement } from "./DraggableElement";
import { ProCard } from "@ant-design/pro-components";
import { Tag } from "antd";

// Extend IElement with additional data field from <T>
export interface IElement<T = any> {
  id: string;
  content: string;
  column: string;
  data?: T;
}

export interface IColumnConfig {
  name: string;
  color: string;
}

interface IColumn {
  heading: string;
  columnConfig: { [key: string]: IColumnConfig } | undefined ;
  elements: IElement[];
  cardRender: (element: IElement, elemIndex: number, columnIdentifier: string) => JSX.Element;
}

export const Column: FC<IColumn> = ({ heading, elements, columnConfig, cardRender }) => {
  const columnIdentifier = useMemo(() => _.snake(heading).toUpperCase(), [heading]);

  const amounts = useMemo(
    () => elements.filter((elm) => elm.column === columnIdentifier).length,
    [elements, columnIdentifier]
  );
  const background = (variant: string) => {
    return columnConfig?.[variant] ? columnConfig?.[variant].color : "#FFF"
  };
  return (
    <ProCard bordered  ghost>
      <ColumnHeaderWrapper>
        <Heading><Tag color={background(columnIdentifier)}> {heading} </Tag></Heading>
        <ColumnTasksAmout><Tag color="black">{amounts}</Tag></ColumnTasksAmout>
      </ColumnHeaderWrapper>
      <Droppable id={columnIdentifier}>
        <>
        {elements.map((elm, elmIndex) => {
          return (<DraggableElement
            key={`draggable-element-${elmIndex}-${columnIdentifier}`}
            identifier={elm.id}
            content={elm.content}
          >
            {cardRender(elm, elmIndex, columnIdentifier)}
          </DraggableElement>)
        })}
        <DropPlaceholder />
        </>
      </Droppable>
    </ProCard>
  );
};

const Heading =  styled.h3`
  color: #FFF;
`



const DropPlaceholder = styled.div`
  height: 35px;
  background-color: transparent;
  margin-top: 15px;
`

const ColumnHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 0px 10px;
  border-radius: 10px;
`


const ColumnTasksAmout = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  color: #FFF;
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 255, 255, 255, 0.18 );
  backdrop-filter: blur(5px);
  border: 1px solid rgba( 255, 255, 255, 0.18 );
`