import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import enUSIntl from 'antd/es/locale/en_US';
import { ConfigProvider } from "antd";
import { Provider } from 'react-redux';
// import { store } from './redux/app/store';
import { persistor, store } from './redux/app/store';
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>

   <PersistGate persistor={persistor}>
  <ConfigProvider locale={enUSIntl}>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </ConfigProvider>
  </PersistGate>

  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
