/* eslint-disable import/no-anonymous-default-export */
import { Badge, Tag } from "antd";

export enum EXCEPTION_KEYS {
    // NOT_STARTED = "NOT_STARTED",
    // PENDING = "PENDING",
    // IN_PROGRESS = "IN_PROGRESS",
    // COMPLETED = "COMPLETED",
    // FAILED = "FAILED",

  ISIN_MISSING = 'NULL',
  ISIN_NULL='null',
//   NULL= "NULL",
  TICKER_MISSING= 'TICKER'
  }
  
  
  
  export const processStatus = {
    [EXCEPTION_KEYS.ISIN_MISSING]: {
      color: 'volcano',
      text: 'Isin Missing',
    },
    [EXCEPTION_KEYS.TICKER_MISSING]: {
      color: 'blue',
      text: 'Ticker Missing'
      ,
    },

    [EXCEPTION_KEYS.ISIN_NULL]: {
      color: 'volcano',
      text: 'Isin Null',
    },
    // [EXCEPTION_KEYS.COMPLETED]: {
    //   color: 'green',
    //   text: 'Completed',
    // },
    // [EXCEPTION_KEYS.FAILED]: {
    //   color: 'red',
    //   text: 'Failed',
    // },
  };
  
  
  export default (props: { status: EXCEPTION_KEYS }) => {
    const { status } = props;
    if(!processStatus?.[status]){
      return <p>{status}</p>;
    }
    return (
      <Badge color={processStatus?.[status]?.color} text={processStatus?.[status]?.text}/>
    );
  }