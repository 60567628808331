import axios, { AxiosRequestConfig } from "axios";
import process from "process";

interface ApiConfig {
  location: string;
  transactionAPI: string;
  wranglerAPI: string;
}

const configs: { [key: string]: ApiConfig } = {
  local: {
    location: 'http://localhost:4300',
    transactionAPI: 'http://localhost:4000',
    wranglerAPI: 'http://localhost:4300/api/wrangler',
  },
  dev: {
    location: 'https://wrangler-backend-dev.ethan-ai.com',
    transactionAPI: 'https://dev-transaction.ethan-ai.com',
    wranglerAPI: 'https://wrangler-backend-dev.ethan-ai.com/api/wrangler',
  },
  prod: {
    location: 'https://wrangler-backend.ethan-ai.com',
    transactionAPI: 'https://transactions.ethan-ai.com',
    wranglerAPI: 'https://wrangler-backend.ethan-ai.com/api/wrangler',
  },
};


const env = process.env.REACT_APP_ENV || 'local';
const configs2 = configs[env];

// Create base wrangler api with axios
const wranglerApi = axios.create({
  baseURL: configs2.wranglerAPI,
});

const transactionApi = axios.create({
  baseURL: configs2.transactionAPI,
});

const post = async (url: string, data?: any) => {
  const response = await wranglerApi.post(url, data);
  return response.data;
};

const patch = async (url: string, data?: any) => {
  const response = await wranglerApi.patch(url, data);
  return response.data;
};

const get = async (url: string, config?: AxiosRequestConfig) => {
  const response = await wranglerApi.get(url, config);
  return response.data;
};

const put = async (url: string, data: any) => {
  const response = await wranglerApi.patch(url, data);
  return response.data;
};

const del = async (url: string) => {
  const response = await wranglerApi.delete(url);
  return response.data;
};


// const delforRefinary = async (url: string,data:any) => {
//   const response = await wranglerApi.delete(url,data);
//   return response.data;
// };

const getProxybyId= async(url:string) =>{
  const response = await wranglerApi.get(url)
  return response.data;
}


export const URL_PREFIX = {
  TRADE_PROXY: '/trade/proxy',
  POSITION_PROXY: '/position/proxy',
  STATEMENT: '/statement',
  CUSTOMER: '/customer',
  VALIDATION: '/validation',
  POSITION: '/validation/position',
  FX_RATE:  '/fx',
  SECURITY: '/security',
  PARSING: '/parsing',
  TABLE_MAPPING: '/table_mapping',
}

export interface PositionProxyValidation {
  id: string;
  stage: string;
}

export const validationAPI = {
  create: async(data: any) => {
    return await post(URL_PREFIX.VALIDATION,data);
  },
  positionValidation: async (data: PositionProxyValidation) => {
    return await post(URL_PREFIX.VALIDATION + '/position', data);
  }
}

// {
//   "id":"64332",
//   "stage": "STAGE_1"
// }
export const validationPositionAPI = {
  create: async(data:any) => {
    return await post(URL_PREFIX.POSITION,data);
  }
}

export const positionProxyUrl = {
 download: (id: string) => `${configs2.wranglerAPI}${URL_PREFIX.POSITION_PROXY}/download/${id}`,
}

export const positionProxyAPI = {
  getAllStageData: async (proxyId:any) => {
    return await get(`${URL_PREFIX.POSITION_PROXY}/stage/get_data/${proxyId}/`)
  },
  getStageData: async (proxyId: any) => {
    return await get(`${URL_PREFIX.POSITION_PROXY}/stage/${proxyId}`);
  },
  editProxyData: async(proxyId:any, data:any) => {
    return await patch(`${URL_PREFIX.POSITION_PROXY}/modify_all/${proxyId}`,data);
  },
  ignoreAllExceptions: async(proxyId: any, data:any) => {
    return await patch(`${URL_PREFIX.POSITION_PROXY}/${proxyId}/ignoreAllExceptions`,data);
  },
  validateBeforePush: async(proxyId:any) => {
    return await post(`${URL_PREFIX.POSITION_PROXY}/${proxyId}/valid_status`);
  },
  pushToSystem: async(proxyId:any) => {
    return await post(`${URL_PREFIX.POSITION_PROXY}/${proxyId}/push`);
  },
  getMappings: async() => {
    return await get(`${URL_PREFIX.POSITION_PROXY}/data/mappings`)
  },
  getProxyData: async() => {
    return await get(`${URL_PREFIX.POSITION_PROXY}`);
  },
  getMappedData: async (proxyId:any) => {
    return await get(`${URL_PREFIX.POSITION_PROXY}/${proxyId}`);
  },
  getExceptions: async (proxyId:any,id:any) => {
    return await get(`${URL_PREFIX.POSITION_PROXY}/${proxyId}/exceptions/${id}`)
  },
  patchPositionProxyData: async (proxyId:any, rowId: any, data: any, ) => {
    return await patch(`${URL_PREFIX.POSITION_PROXY}/${proxyId}/data/${rowId}`,data)
  },
  // 64332/mapping/
  postMapping: async (proxyId:any, data: any) => {
    return await post(`${URL_PREFIX.POSITION_PROXY}/${proxyId}/mapping/`,{})
  },
  cleaningPosition: async (proxyId:any) => {
    return await post(`${URL_PREFIX.POSITION_PROXY}/${proxyId}/position_clean`,{})
  },
  // /64332/preprocess/
  postPreprocess: async (proxyId:any, data: any) => {
    return await post(`${URL_PREFIX.POSITION_PROXY}/${proxyId}/preprocess/`,{})
  },
  // 64332/cleaning
  postCleaning: async (proxyId:any, data: any) => {
    return await post(`${URL_PREFIX.POSITION_PROXY}/${proxyId}/cleaning/`,{})
  },
  // /64332/push
  postPush: async (proxyId:any, data: any) => {
    return await post(`${URL_PREFIX.POSITION_PROXY}/${proxyId}/push/`,{})
  },
  ignoreException: async(proxyId:any, rowId:any, exceptionKey: any, data: any) => {
    return await patch(`${URL_PREFIX.POSITION_PROXY}/${proxyId}/exceptions/${rowId}/${exceptionKey}`,data)
  },
  deleteException: async(proxyId:any) => {
    return await del(`${URL_PREFIX.POSITION_PROXY}/${proxyId}`)
  },
  addingDatatoRefinary: async(key:any,data:any) =>{
    return await post(`${URL_PREFIX.POSITION_PROXY}/add_ref_data/${key}`,data)
  }
}

export const tableMappingAPI = {
  createTableMappingFromRefinery: async(refineryId: string, data: any) => {
    return await post(`${URL_PREFIX.TABLE_MAPPING}/refinery/${refineryId}`,data);
  },
  getTableMappingByCustodianId: async(custodianId: string) => {
    return await get(`${URL_PREFIX.TABLE_MAPPING}/custodian/${custodianId}`);
  },
  get_table_mapping_by_refineryId: async(refineryId: string) => {
    return await get(`${URL_PREFIX.TABLE_MAPPING}/refinery/${refineryId}`);
  },
  deleteTableMapping: async(id: any) => {
    return await del(`${URL_PREFIX.TABLE_MAPPING}/${id}`);
  },
  pushDataToTradesStatement: async(refineryId: string, tableName: string) => {
    return await post(`${URL_PREFIX.TABLE_MAPPING}/push/${refineryId}`, {
      table_name: tableName
    });
  }
}

export const tradeProxyAPI = {
  create: async (data: any) => {
    return await post(URL_PREFIX.TRADE_PROXY, data);
  },
  putProxyData: async(proxy_id:string, dataId: string, data: any) => {
    return await put(`${URL_PREFIX.TRADE_PROXY}/${proxy_id}/data/${dataId}`,data);
  },
  getproxyData: async() =>{
    return await get(URL_PREFIX.TRADE_PROXY);
  },
  getProxybyId: async(proxy_id: any) => {
    return await getProxybyId(`${URL_PREFIX.TRADE_PROXY}/${proxy_id}/data`)
  },
  regenerate: async (data: any) => {
    return await post(`${URL_PREFIX.TRADE_PROXY}/regenerate`,data)
  },
  getExceptionbyId: async(proxy_id:any)=> {
    return await getProxybyId(`${URL_PREFIX.TRADE_PROXY}/${proxy_id}`)
  }
}

export interface ExtractRefineryCreate {
  filename: string;
  custodian_id: string;
  client_id: string;
  client_name: string;
  statement: any;
  customer_key: any;
}

export interface StartProcess {
  report_date: string;
}

export const blotterDataAPI = {
  getBlotterData: async ({
    paging: {
      limit,
      ordering,
      offset,
    },
    token,
    customParams,
  }: any): Promise<any> => {
      return await transactionApi.get(`/trades/blotter/`, {
        params: {
          ...customParams,
          limit,
          ordering,
          offset,
        },
        headers: {
          "Authorization": token as unknown as string,
        },
    });
  },
  addBlotterData: async (data:any,token:any): Promise<any> => {
      return await transactionApi.post(`/trades/blotter/add/`, data,{
        headers: {
          "Authorization": token as unknown as string,
        },
    });
  },
  deleteBlotterRow: async (id:any,token:any): Promise<any> => {
      return await transactionApi.delete(`/trades/blotter/delete/${id}`,{
        headers: {
          "Authorization": token as unknown as string,
        },
    });
  },
  updateBlotterRow: async (id:any,data:any,token:any): Promise<any> => {
      return await transactionApi.patch(`/trades/blotter/update/${id}`, data,{
        headers: {
          "Authorization": token as unknown as string,
        },
    });
  }
}

export const processingStatusAPI = {
  postStatements: async ({
    data,
    token,
  }: any): Promise<any> => {
      return await transactionApi.post(`/statement/`, data,{
        headers: {
          "Authorization": token as unknown as string,
        },
    });
  },
  getProcessingStatus: async ({
    paging: {
      limit,
      ordering,
      offset,
    },
    token,
    customParams,
  }: any): Promise<any> => {
      return await transactionApi.get(`/processing_status/`, {
        params: {
          ...customParams,
          limit,
          ordering,
          offset,
        },
        headers: {
          "Authorization": token as unknown as string,
        },
    });
  },
}


export interface ListFXRate {
  fxdate__gte: string;
  fxdate__lte: string;
  ccy?:string
}

export const fxAPI = {
  listFxRate: async (queries: ListFXRate) => {
    console.log(queries)
    return await get(`${URL_PREFIX.FX_RATE}`, { params: queries });
  },
  updateFxRate: async (statementId: string, data: any) => {
    return await post(`${URL_PREFIX.FX_RATE}/${statementId}/fx/`, data);
  },
  getFXRate: async (statementId: string) => {
    return await get(`${URL_PREFIX.FX_RATE}/${statementId}/fx/`);
  },
  pushFxRate: async (statementId: string) => {
    return await post(`${URL_PREFIX.FX_RATE}/${statementId}/fx/push`, {});
  },
}

export interface IPatchStatement {
  report_date: string;
  custodian_id: string;
  client_id: string;
}

export enum StatementUploadStatus {
  DRAFT = "DRAFT",
  COMPLETED = "COMPLETED",
}



export const statementAPI = {
  getDetails: async (id?: string) => {
    return get(`${URL_PREFIX.STATEMENT}/${id}`);
  },
  postStatements: async ({
    data,
    token,
  }: any): Promise<any> => {
      return await transactionApi.post(`/statement/`, data,{
        headers: {
          "Authorization": token as unknown as string,
        },
    });
  },
  updateTrasactionStatus: async (statementId: string, status: StatementUploadStatus,token:string) => {
    return await transactionApi.patch(`/statement/${statementId}`, {upload_status: status},{
      headers: {
        "Authorization": token as unknown as string,
      },
  });
    // return await patch(`${URL_PREFIX.STATEMENT}/${statementId}`, { status });
  },
  patchTrasactionStatement: async (statementId: string, data: any,token:string) => {
    return await transactionApi.patch(`/statement/${statementId}`, data,{
      headers: {
        "Authorization": token as unknown as string,
      }})
    },
  updateStatus: async (statementId: string, status: StatementUploadStatus) => {
    return await patch(`${URL_PREFIX.STATEMENT}/${statementId}`, { status });
  },
  patchStatement: async (statementId: string, data: any) => {
    return await patch(`${URL_PREFIX.STATEMENT}/${statementId}`, data);
  },
  getStatements: async ({
    paging: {
      limit,
      ordering,
      offset,
    },
    token,
    customParams,
  }: any): Promise<any> => {
      return await transactionApi.get(`/statement/`, {
        params: {
          ...customParams,
          limit,
          ordering,
          offset,
        },
        headers: {
          "Authorization": token as unknown as string,
        },
    });
  },
  extract: async (id: string, data: ExtractRefineryCreate) => {
    return post(`${URL_PREFIX.STATEMENT}/${id}/extract`, data);
  },
  retryExtract: async (id: string) => {
    return get(`${URL_PREFIX.STATEMENT}/${id}/extract/retry`);
  },
  process: async (id: string, data: StartProcess) => {
    return post(`${URL_PREFIX.STATEMENT}/${id}/process`, data);
  },
  status: async (ids: string[] )=> {
    return get(`${URL_PREFIX.STATEMENT}/status/${ids.join('&')}`);
  },
  modify: async (row_id: string, proxy_id:string, data:any) =>{
    return put(`${URL_PREFIX.STATEMENT}/${proxy_id}/${row_id}/refinery`, data)
  },
  delete: async (row_id: string, proxy_id:string) =>{
    return del(`${URL_PREFIX.STATEMENT}/del/${proxy_id}/${row_id}/refinery`)
  },
  getPdf: async (payload:any,proxy_id:any) => {
    return post(`${URL_PREFIX.STATEMENT}/get_pdf/${proxy_id}`, payload)
  },
  addId: async (proxyId:any) => {
    return get(`${URL_PREFIX.STATEMENT}/${proxyId}/add_ids`);
  },
  addConfigurations: async (proxyId:string, payload: any) => {
    return post(`${URL_PREFIX.STATEMENT}/${proxyId}/add_mapping_config`, payload);
  },
}

export const preSignAPI = {
  statementPreSign: async ({
    data,
    token,
  }: any): Promise<any> => {
      return await transactionApi.post(`/statement/pre-sign/`, data,{
        headers: {
          "Authorization": token as unknown as string,
        },
    });
  },
  preSign: async ({
    data,
    token,
  }: any): Promise<any> => {
      return await transactionApi.post(`/pre-sign/`, data,{
        headers: {
          "Authorization": token as unknown as string,
        },
    });
  },
}


export const downloadFileAPI = {
  downloadFile: async ({
    file_path,
    token,
  }: any): Promise<any> => {
      return await transactionApi.post(`/download/`, {
        file_path:file_path
      },{
        headers: {
          "Authorization": token as unknown as string,
        },
    });
  },
}

export const deleteFileAPI = {
  deletFile: async ({
    file_key,
    token,
  }: any): Promise<any> => {
      return await transactionApi.post(`/delete-file/`, {
        file_key:file_key
      },{
        headers: {
          "Authorization": token as unknown as string,
        },
    });
  },
}

export const dropdownsAPI = {

  getSecurities: async({token,search,assetType}:any): Promise<any> => {
    return await wranglerApi.get(`/blotter/asset-add/`, {
      headers: {
        "x-api-key": token as unknown as string,
      },
      params: {
        search: search,
        asset_type: assetType
      }
  })
  },
  getClient: async ({
    token,
  }: any): Promise<any> => {
      return await transactionApi.get(`/client/`, {
        headers: {
          "Authorization": token as unknown as string,
        },
    });
  },
  getCustodian: async ({
    client_id,
    token,
  }: any): Promise<any> => {
      return await transactionApi.get(`/custodian/`, {
        params: {
              client_id: client_id
        },
        headers: {
          "Authorization": token as unknown as string,
        },
    });
  },
  getCustodianAccount: async({
    client_id,
    custodian_id,
    token,
  }:any): Promise<any> => {
    return await transactionApi.get(`/trades/blotter/account_nos`, {
      params: {
        client_id: client_id,
        custodian_id: custodian_id,
      },
      headers: {
        "Authorization": token as unknown as string,
      },
  });
  },
  getClientRelationship: async ({
    client_id,
    custodian_id,
    token,
  }: any): Promise<any> => {
      return await transactionApi.get(`/relationship/`, {
        params: {
              client_id: client_id,
              custodian_id: custodian_id,
        },
        headers: {
          "Authorization": token as unknown as string,
        },
    });
  },
} 


export const customerAPI = {
  getCustomers: async () => get(`${URL_PREFIX.CUSTOMER}`),
}

export const SecurityAPI ={
  getAllTickers: async()=> 
  get(`${URL_PREFIX.SECURITY}/security/`)
}

export const ParsingMappingAPI ={
  postTickerinParsingMapping: async(data:any)=>
  post(`${URL_PREFIX.PARSING}/parsing`,data),

  getTickerinParsingMapping: async(queries:any)=>
  get(`${URL_PREFIX.PARSING}/parsing/position`,{params: queries}),

  callForTickerParsing: async(queries :any ) =>
  get(`${URL_PREFIX.SECURITY}/security-master`,{params: queries}),


  // updateStatus : async(id:any) => 
  // patch(`${URL_PREFIX.PARSING}/ticker-confirm/${id}`),

  updateTicker: async(id:any,ticker:any) => 
  patch(`${URL_PREFIX.PARSING}/ticker-confirm/${id}`,ticker)
}


