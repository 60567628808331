import { DrawerForm, ProFormInstance, ProFormInstanceType, ProFormSelect, ProFormText } from '@ant-design/pro-components';
import { Input } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import React, { useRef, useState } from 'react'
import { ParsingMappingAPI, SecurityAPI } from '../../../api';
import CustodianFormSelect from '../../Refinary/Components/FormFields/ClientCustodianFormField';
import TradeType from './TradeType';


interface DrawerProps{
    drawerVisit: any,
    setDrawerVisit?: (value: boolean) => void;
    props2:any
}
function PositionDrawer(props: DrawerProps) {
  const {drawerVisit,setDrawerVisit,props2}=props;   
    const custodian_id= props2?.custodian_id; 
    const [data,setData]=useState<any>({
      custodian_name: props2?.custodian_name,
      trade_type:"",
      section:"",
      map_string:"",
      ticker:""
    })

    const ref= useRef<ProFormInstance>()

    

    
  return (
    <DrawerForm
    onOpenChange={setDrawerVisit}
    open={drawerVisit}
    onFinish={async (value) => {
      ParsingMappingAPI.postTickerinParsingMapping({...value,custodian_id})
      ref?.current?.resetFields()
        return true;
      }}
    width={350}
    formRef={ref}
    >
    <>
    <ProFormSelect name="custodian" placeholder={props2?.custodian_name} initialValue={props2.custodian_name} disabled/>

        {Object.keys(data).map((item:string)=> {
          if(item!=="custodian_name"){
            if(item!=="ticker"){
              if(item!==undefined){
                return (
                  <ProFormText 
                   name={item}
                 style={{marginTop:"1em"}}
                 status="error"
                 placeholder={item}
                 allowClear
                 required
                 rules={[{required: true,message: `Please select the ${item}` }]}
                 />
                )  
              }
            
            }  
          }
        }
        )}

        <ProFormSelect
      name="ticker"
      showSearch
      debounceTime={500}
      request={async () => {
        const response = await SecurityAPI.getAllTickers()
        return response.map((custodian: any) => ({
          value: custodian?.ticker,
          label: custodian?.ticker,
        }))
      }}
      style={{marginTop:"1em"}}
      // fieldProps={{
      //   onChange: onChange,
      // }}
      placeholder="Ticker"
      rules={[{required: true,message: 'Please select the Ticker' }]}
      allowClear
      />

    </>
    </DrawerForm>
  )
}

export default PositionDrawer