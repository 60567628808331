import { PlayCircleFilled, PlusOutlined, SmileFilled } from '@ant-design/icons';
import { ActionType, LightFilter, ModalForm, ProColumns, ProForm, ProFormDatePicker, ProFormText,ProFormDateRangePicker } from '@ant-design/pro-components';
import { Button, DatePicker, Form, message, Tag, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useStatePersist } from 'use-state-persist';
import Table from "../../common/Table";
// import { TRANSACTIONS_API_ROOT } from '../../helpers/utils';
import ClientFormSelect from '../../Components/FormFields/ClientFormField';
import CustodianFormSelect from '../../Components/FormFields/ClientCustodianFormField';
import moment from 'moment';
import { SortOrder } from 'antd/es/table/interface';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../redux/features/userSlice';
import { customerAPI, statementAPI } from '../../../../api';
import ProcessStatus, { PROCESS_STATUS } from '../ProcessStatus';
import {Link, useNavigate} from 'react-router-dom';
import ViewStatement from './ViewStatement';
import { useForm } from 'antd/es/form/Form';
import {Routes,Route} from 'react-router-dom';
// import { SortOrder } from 'antd';
// import PreLoader from 'components/PreLoader/PreLoader';


type StatementTableData = {
  _key?: number;
  table_names?: string[];
  table_length?: number;
  status_message?: string;
  id: number;
  statement_id: number;
  client_id: number;
  report_date: Date;
  upload_status: string;
  processing_status: string;
  relationship_no: string;
  client_name: string;
  custodian_name: string;
  custodian_id: string;
  portfolio_no: string;
  file_name: string;
  uploaded_at: Date;
  currency: string;
} 



const columns: ProColumns<StatementTableData>[] = [
  {
    dataIndex: '_key',
    title: "O",
    width: 80,
    fixed: 'left',
    key: '_key',
    search: false,
    render: (text, record, index) => <>
      <Tooltip title="View Statement">
        <ViewStatement key={record._key} text={text} record={record}/>
      </Tooltip>
    </>,
  }, {
    dataIndex: 'id',
    title: "ID",
    width: 40,
    fixed: 'left',
    key: 'id',
    search: false,
  },
  {
    dataIndex: 'client_name',
    title: "Client Name",
    width: 100,
    fixed: 'left',
    key: 'client_name',
    renderFormItem: (item, { onChange }) => <ClientFormSelect onChange={onChange} disabledLabel />,
  },
  {
    dataIndex: 'custodian_name',
    title: "Custodian Name",
    key: 'custodian_name',
    width: 150,
    renderFormItem: (item, { onChange }) => <CustodianFormSelect onChange={onChange} disabledLabel />,
  },
  {
    dataIndex: 'report_date',
    title: "Report Month",
    width: 140,
    sorter: true,
    // Format the date to be displayed in the table
    renderText: (text) => moment(text).format("MMM YYYY"),
    key: 'report_date',
    renderFormItem: (_item, _empty, form) => (
      <>
        <DatePicker.MonthPicker
          placeholder="Select Month"
          format="MMM-YY"
          onChange={(value) => {
            form.setFieldValue("report_date_from", value?.startOf('month').format("YYYY-MM-DD"));
            form.setFieldValue("report_date_to", value?.endOf("month").format("YYYY-MM-DD"));
          }}
        />
        <ProFormText name="report_date_from" hidden />
        <ProFormText name="report_date_to" hidden />
      </>
    ),
  },
  {
    dataIndex: 'uploaded_at',
    title: "Uploaded",
    width: 140,
    // Format the date to be displayed in the table
    renderText: (text) => moment(text).format("MMM DD, YYYY"),
    key: 'uploaded_at',
    sorter: true,
    search: false,
  },
  {
    key: 'processing_status',
    dataIndex: 'processing_status',
    title: "Status",
    width: 100,
    search: false,
    renderText: (text, row) => (<ProcessStatus status={text} message={row.status_message} />)
  },
  {
    key: 'table_names',
    dataIndex: 'table_names',
    title: "Table Names",
    width: 100,
    search: false,
    renderText: (tableNames: string[]) => {
      const combinedTableNames = tableNames.join(", ");
      return (
        <Tooltip title={combinedTableNames}>
          <Tag color="blue">{tableNames?.length}</Tag> <span>{(tableNames?.[0] || '-')}</span>
        </Tooltip>
      )
    }
  },
  {
    dataIndex: 'currency',
    title: "Currency",
    key: 'currency',
    width: 80,
    search: false,
  },
  {
    dataIndex: 'relationship_no',
    title: "Relationship No",
    key: "relationship_no",
    search: false,
  },
  {
    dataIndex: 'portfolio_no',
    title: "Portfolio No",
    key: 'portfolio_no',
    search: false,
  },
  {
    key: 'client_id',
    search: false,
    title: 'Refinary',
    dataIndex: ['client_id', 'client_name'],
    fixed: 'right',
    width: 150,
    renderText: (_text, row) => (
      <RefineryCell row={row} />
    ),
  },
];


interface StatementTableProps {
  openStatementCreateForm: () => void;
}

 const RefineryCell = (props: { row: StatementTableData }) => {
  const row = props.row;
  const key = row._key?.toString();
  const statementId = row.id?.toString();
  let customerKey : String;
  const selected= useSelector(selectUser);

  const checkSelected = async()=>{
    const fetchCustomerList = await customerAPI.getCustomers();
    fetchCustomerList.map((data:any)=>{
      if(data?.access_token==selected)
      {
        customerKey= data?._key
      }
    })
  }

  const extractStatement = async () => {
    await checkSelected()
    await statementAPI.extract(statementId, {
      client_id: row.client_id?.toString(),
      client_name: row.client_name,
      custodian_id: row.custodian_id?.toString(),
      statement: row,
      filename: row.file_name,
      customer_key: customerKey
    })
  }

  const retryExtract = async () => {
    if(key){
      await statementAPI.retryExtract(key);
    } else {
      message.error("Key does not exist");
    }
  };

  switch (props.row.processing_status) {
    case PROCESS_STATUS.PROCESSED:
    case PROCESS_STATUS.SUCCESS:
      return (
        <Button type="link" disabled={true}>
          <SmileFilled /> Processed
        </Button>
      )
    case PROCESS_STATUS.UPLOAD_FAILED:
      return (
        <Button type="link" onClick={() => retryExtract()}>
          Retry
        </Button>
      )
    case PROCESS_STATUS.UPLOADED:
    case PROCESS_STATUS.PROCESSING_FAILED:
      if(props.row?.table_length === 0) {
        <Button onClick={() => retryExtract()}>
          Retry
        </Button>
      }
      return (
        <ProcessModel statement={props.row} />
      )
    case PROCESS_STATUS.PENDING:
    case PROCESS_STATUS.UPLOAD_FAILED:
    default:
      return (
        <Button type="link" onClick={() => extractStatement()}>
          Initiate
        </Button>
      )
  }
}

export const ProcessModel = (props: { statement: any }) => {
  const [form] = Form.useForm();
  const [report_date] = useState(moment(
    props?.statement?.report_date || moment().subtract(1, 'month')
  ).endOf('month'));
  return (
    <ModalForm
      title="Process Statement"
      initialValues={{
        // Set the initial value of the form or last month end of the month
        report_date: report_date.format("YYYY-MM-DD"),
        month: report_date.format("MMM-YYYY"),
      }}
      form={form}
      trigger={
        <Button disabled={props?.statement?.table_length === 0} type="link">
          <PlayCircleFilled /> Start Trade Validation
        </Button>
      }
      onFinish={async (values) => {
        const {month, ...modifiedValues} = values
        // console.log(props)
        if(props?.statement?._key){
          await statementAPI.process(props?.statement?._key?.toString(), modifiedValues);
          return true
        } else {
          message.error("Key does not exist");
        }
      }}
    >
      <>
        <ProFormDatePicker.Month
          name="month"
          label="Report Month"
          fieldProps={{
            format: "MMM-YYYY",
            disabledDate: (current) => current && current > moment().endOf("day").subtract(1, "month"),
            onChange: (value) => {
              form.setFieldsValue({
                report_date: value?.endOf("month").format("YYYY-MM-DD"),
              });
            },
          }}
          rules={[{ required: true, message: 'Please select the month' }]}
          placeholder="Select Month"
        />
        <ProFormText name="report_date" hidden />
      </>
    </ModalForm>
  )
}

const StatementTable = (props: StatementTableProps) => {
  const navigate = useNavigate();
  const { openStatementCreateForm } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const pageSize = 10;
  const actionRef = useRef<ActionType>();
  const ethanToken = useSelector(selectUser);
  const [searchForm] = useForm();
  const [filterValue, setFilterValue] = useStatePersist<any>('@StatementTableFilterValue', {});
  useEffect(() => {
    actionRef.current?.reload();
  }, [ethanToken])

  useEffect(() => {
    if(actionRef?.current?.reset){
      actionRef.current.reset();
    }
  }, [filterValue])

  const setSearchFilter = async (_: any, allValues: any) => {
    // searchForm.setFieldValue("report_date_from",);
    // searchForm.setFieldValue("report_date_to", allValues?.month?.endOf("month").format("YYYY-MM-DD"));
    // console.log("allValues?.month",allValues)
  
    console.log(allValues)
    setFilterValue({
      ...allValues,
      // report_date_from: allValues?.month?.startOf('month').format("YYYY-MM-DD"),
      // report_date_to: allValues?.month?.endOf("month").format("YYYY-MM-DD"),
    });
    actionRef.current?.reload();
  }

  return (
    <>
      <Table<StatementTableData>
        columns={columns}
        actionRef={actionRef}
        cardBordered
        request={async (params = {}, sortObject: Record<string, SortOrder>) => {
          // console.log("params",params, sortObject, filterValue)
          const ordering = Object.keys(sortObject).map((key) => {
            const value = sortObject[key];
            return `${value === "ascend" ? "" : "-"}${key}`;
          })?.join();

          const { pageSize: pageSizeLimit, current, ...customParams } = params;
          const response = await statementAPI.getStatements({
            paging: {
              limit: pageSizeLimit,
              offset: (current as number - 1) * (pageSizeLimit as number),
              ordering,
            },
            token: ethanToken,
            customParams: {
              ...customParams,
              ...filterValue,
            },
          });
          const statementIds = response.data.results?.map((item: any) => {
            return item.id;
          });
          if (statementIds.length > 0) {
            const statusObject = await statementAPI.status(statementIds);
            //  Add the statusObject to the response
            response.data.results = response.data.results.map((item: any) => {
              return {
                ...item,
                processing_status: statusObject[item.id]?.status,
                _key: statusObject[item.id]?._key || null,
                status_message: statusObject[item.id]?.status_message?.toString(),
                table_names: statusObject[item.id]?.table_names || [],
                table_length: statusObject[item.id]?.table_names?.length || 0,
              };
            });
          }
          const obj = {
            total: response.data.count,
            data: response.data.results,
          }
          setIsLoading(false);
          return obj;
        }}
        editable={{
          type: 'multiple',
        }}
        columnsState={{
          persistenceKey: 'statement-table',
          persistenceType: 'localStorage',
        }}
        rowKey="id"
        options={{
          setting: {
            listsHeight: 400,
          },
        }}
        form={{
          syncToUrl: (values, type) => {
            if (type === 'get') {
              return {
                ...values,
                created_at: [values.startTime, values.endTime],
              };
            }
            return values;
          },
        }}
        pagination={{
          pageSize,
        }}
        dateFormatter="string"
        search={{
          filterType: 'light',
          collapsed: false,
          collapseRender: false,
        }}
        scroll={{ x: 1200 ,y:600}}  
        toolbar={{
          title: 'Statements',
          filter: (
            <LightFilter
              initialValues={{
                ...filterValue
              }}
              form={searchForm}
              preserve={true}
              onValuesChange={setSearchFilter}
            >
              <ClientFormSelect name="client" />
              <CustodianFormSelect name="custodian" />
              <ProFormDateRangePicker
                // placeholder="Report Month"
                name="month"
                label="Report Month"
                fieldProps={{
                  format: "MMM-YYYY",
                  disabledDate: (current) => current && current > moment().endOf("day").subtract(1, "month"),
                  onChange: (date:any) => {
                    console.log(date)
                    if(date === undefined || date === null){
                      searchForm.setFieldsValue({
                        month: null,
                        report_date_from: null ,
                        report_date_to: null,
                    });
                    setFilterValue({
                      ...filterValue,
                      month: null,
                      report_date_from: null ,
                      report_date_to: null,
                    });

                    }
                    else {
                      searchForm.setFieldsValue({
                        report_date_from: date[0]?.startOf('month').format("YYYY-MM-DD"),
                        report_date_to: date[1]?.endOf("month").format("YYYY-MM-DD"),
                    });
                    }
                  },
                }}
              />
              <ProFormText name="report_date_from" hidden />
              <ProFormText name="report_date_to" hidden />
            </LightFilter>
          ),
        }}
        toolBarRender={() => [
          <Button onClick={() => {
            openStatementCreateForm()
          }} key="button" icon={<PlusOutlined />} type="primary">
            Add Position Statement
          </Button>
        ]}
      />
    </>
  )
}

export default StatementTable;
