import { PageContainer } from '@ant-design/pro-components'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import PositionStatements from './Components/PositionStaments'
import { Button,message,Popconfirm } from 'antd'
import { positionProxyUrl, positionProxyAPI} from '../../api'

interface DataProps{
  custodianId: any
  resData: any
  setRefresh:(data:any)=>void,
}


const PositiondsValidation = (props: DataProps) => {
  const { key } = useParams();
  const [open, setOpen] = React.useState(false);
  const [status,setStatus] = React.useState<any>()
  const showPopconfirm = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
    // const [isPushed, setIsPushed] = React.useState(false);

    useEffect(() => {
      const fetchData = async () => {
        const res = await positionProxyAPI.getMappedData(key);
        setStatus(res.status);
      }
      fetchData();
    },[]);

  const pushToSystem = async() => {
    
      try{
        await positionProxyAPI.pushToSystem(key)
        const res = await positionProxyAPI.getMappedData(key);
        setStatus(res.status);
        setOpen(false);
        message.success('Pushed to system successfully')
      }catch(errorResponse: any){
        message.error(errorResponse?.response?.data?.detail);
      }
  }

  return (
    <PageContainer
      style={{
        marginTop: 20
      }}
      header={{
        title: `Position Validation Detail Page`,
      }}
      extra={[
        <Button onClick={() => {
          window.open(positionProxyUrl.download(key as string));
        }}>
          Download Excel
        </Button>,
        <Popconfirm
          open={open}
          title="Are you sure ? This will push the data to live system."
          onConfirm={pushToSystem}
          onCancel={handleCancel}
          okText="Yes"
          cancelText="No"
        >
          <Button type={"primary"} onClick={showPopconfirm}>
            {status === 'SUCCESS' ? "Retry" : "Push to Live System"}
          </Button>
        </Popconfirm>
      ]}
    >
        <PositionStatements custodianId={props.custodianId} resData={props.resData} setRefresh={props.setRefresh}/>
    </PageContainer>
  )
}

export default PositiondsValidation;