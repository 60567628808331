export const POSITION_COLUMNS = {
  "security_id": "security_id",
  "description": "description",
  "security_code": "security_code",
  "isin": "isin",
  "position_qty": "position_qty(Quantity)",
  "position_id": "position_id",
  "mtm_local_ccy": "mtm_local_ccy(Market value)",
  "mtm_rpt_ccy": "mtm_rpt_ccy(Reference Value)",
  "mtm_base_ccy": "mtm_base_ccy",
  "asset_class": "asset_class(Type of Security)",
  "custodian_account": "custodian_account",
  "ccy": "ccy",
  "rpt_ccy": "rpt_ccy",
  "account_id": "account_id",
  "sec_name": "sec_name",
  "mtm_price": "mtm_price(Local Currency Price)",
  "mtm_date": "mtm_date",
  "local_ccy_position_avg_price": "local_ccy_position_avg_price",
  "local_ccy_unrealisedpl": "local_ccy_unrealisedpl(Traded/Local Unrealised P/L)",
  "base_ccy_unrealisedpl": "base_ccy_unrealisedpl",
  "rpt_ccy_unrealisedpl": "rpt_ccy_unrealisedpl(Reference/Statement Unrealised P/L)",
  "local_ccy_accrued_interest": "local_ccy_accrued_interest",
  "rpt_ccy_accrued_interest": "rpt_ccy_accrued_interest",
  "base_ccy_accrued_interest": "base_ccy_accrued_interest",
  "country": "country",
  "region": "region",
  "section": "section",
  "loan_start_date": "loan_start_date",
  "loan_maturity_date": "loan_maturity_date",
  "percentage_of_portfolio": "percentage_of_portfolio(% of Assets/ Portfolio)",
  "cost_value": "cost_value",
  "account_no": "account_no",
  "relationship_number": "relationship_number",
  "fx_rate_base": "fx_rate_base",
  "fx_rate_current": "fx_rate_current",
  "fx_rate_purchase": "fx_rate_purchase",
  "price_date_current": "price_date_current",
  "trade_date": "trade_date",
  "expiry_date": "expiry_date",
  "purchase_date": "purchase_date",
  "current_dt": "current_dt",
  "total_value": "total_value",
  "strike_price": "strike_price",
  "coupon_rate": "coupon_rate",
  "coupon_date": "coupon_date",
  "frequency": "frequency",
  "frequency_interest_from": "frequency_interest_from",
  "sector": "sector",
  "industry": "industry",
  "loan_amount": "loan_amount",
  "loan_interest_rate": "loan_interest_rate",
  "interest_at_maturity_in_usd": "interest_at_maturity_in_usd",
  "interest_at_maturity_in_pos_ccy": "interest_at_maturity_in_pos_ccy",
  "statement_date": "statement_date",
  "client_id": "client_id",
  "custodian_id": "custodian_id",
  "custodian_name": "custodian_name",
  "client_name": "client_name",
}