import { ActionType, ProColumns } from '@ant-design/pro-components';
// import { Table } from 'antd'
import { SortOrder } from 'antd/es/table/interface';
import React, { useRef, useState } from 'react'
import Table from "../../Refinary/common/Table";
import request from 'axios';


type TickerTableData = {
    security_id: number,
    isin: String,
    sec_name: String,
    ticker: String,
    }
    
    const columns: ProColumns<TickerTableData>[] =[
        {
            dataIndex: 'security_id',
            title: "security_id",
            width: 100,
            key: 'security_id',
            search: false
        },
        {
            dataIndex: 'isin',
            title: " isin",
            copyable: true,
            key: "isin",
            search:false,
        },
        {
            dataIndex: 'sec_name',
            title:"sec_name",
            search: false,
            fixed: 'left',
            key: 'sec_name'
    
        },
        {
            dataIndex: 'ticker',
            title:"ticker",
            copyable: true,
            key: 'ticker'
        },
        
    ];

function PriceStatements() {
    const [isLoading,setIsLoading]=useState<Boolean>(true);
    const pageSize= 10;
    const Ref = useRef<ActionType>();


    return (
        <Table<TickerTableData>
            columns={columns}
            bordered
            actionRef={Ref}
        request={async (params = {}, sortObject: Record<string, SortOrder>) => {
            const ordering = Object.keys(sortObject).map((key) => {
              const value = sortObject[key];
              return `${value === "ascend" ? "" : "-"}${key}`;
            })?.join();
  
            const { pageSize: pageSizeLimit, current, ...customParams} = params;
            const response = await request.get(`api/securityMaster/ticker`, {
              params: {
                ...customParams,
                limit: pageSizeLimit,
                ordering,
                offset: ((current as number) - 1) * (pageSizeLimit as number),
              },
            })
              const obj = {
                total: response.data.length,
                data: response.data,
            }
            setIsLoading(false);
            return obj;
          }}
          editable={{
            type: 'multiple',
          }}
          columnsState={{
            persistenceKey: 'ticker-table',
            persistenceType: 'localStorage',
          }}
        //   rowKey="id"
          options={{
            setting: {
              listsHeight: 400,
            },
          }}
          form={{
            syncToUrl: (values, type) => {
              if (type === 'get') {
                return {
                  ...values,
                  created_at: [values.startTime, values.endTime],
                };
              }
              return values;
            },
          }}
          pagination={{
            pageSize,
          }}
          search={{
            filterType: 'light',
            collapsed: false,
            collapseRender: false,
          }}
        scroll={{ x: 1200 }}
       
            />
    )
}

export default PriceStatements