import { ActionType, ProDescriptions, ProForm } from '@ant-design/pro-components'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { tradeProxyAPI } from '../../../api';

interface FieldProps{
    idForMapping: Number;
    exceptions:any;
}


function FieldForms(props: FieldProps) {
    const {idForMapping,exceptions} = props
    const {id}= useParams();
    const [form, setForm] = useState<any>();
    const [formFields,setFormFields]=useState<[{
        label: String,
        value?: any,
    }]>();
    
   
    const fetchData= async()=>{
        const response = await (tradeProxyAPI.getProxybyId(id))
        const formFieldsArray: any[] = []
        response?.map((item:any)=> {
            if(item.id === idForMapping){
                Object.keys(item).map((field)=> {
                    formFieldsArray.push({
                        label: field,
                        value: item?.[field],
                    })
                })
                setForm(item);
            }
        })
        setFormFields(formFieldsArray as any);
    }


    const columns:any[]=[];

    formFields?.map((item:any)=>
        columns.push({
            title: item.label,
            key: item.label,
            dataIndex: item.label,
        })
    )

    useEffect(()=>{
        fetchData();
    },[idForMapping])
  
 
    const actionRef = useRef<ActionType>();
    return (
        <>
        <ProDescriptions
          actionRef={actionRef}
        //   bordered
          formProps={{
            onValuesChange: (e, f) => f,
          }}
          title="Trades"
          editable={{
            onSave:async(key: any, record: any, originRow: any) => {
                await tradeProxyAPI.putProxyData(id as string,record,record.id)
                await fetchData()
                await actionRef.current?.reload()
               
                // return Promise.resolve({
                //     success: true
                // })
            } 
          }}
          dataSource={{...form}}
          columns={columns}
          
    >
    </ProDescriptions>
        </>
    )
}

export default FieldForms