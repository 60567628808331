import { ActionType, ProTable } from "@ant-design/pro-components";
import { Table } from "antd";
import { SortOrder } from "antd/es/table/interface";
import { useEffect, useRef, useState } from "react";
import { ParsingMappingAPI } from "../../api";
import { ProColumns } from "@ant-design/pro-components"


const PositionParsingComponent = () => {
    const defaultParams:any= {status: 1} ;
    const [data,setData]= useState<any[]>([])
    const [loading,setLoading]=useState<boolean>(true)
    // const [newData,setNewData]=useState<any[]>[];
    const ref= useRef<ActionType>()
    interface Cols{
        id: any,
        custodian:any,
        custodian_id:any,
        section:any,
        trade_type:any,
        map_string:any,
        ticker:any
    } 
    const columns: ProColumns<Record<string, any>, "text">[] | { title: string; dataIndex: string; key: string; }[] | undefined =  [];

    let cols:ProColumns<Cols>[]=[];


   

    const fetchData=async()=>{
        const data = await ParsingMappingAPI.getTickerinParsingMapping(defaultParams)
        setData(await data)
        return data
    }
  

    const  coloumns: ProColumns<Cols>[] = [

        {
          title: "section",
          dataIndex: "section",
          key: "section",
          editable: false,
        },
        {
          title: "id",
          dataIndex: "id",
          key: "id",
          editable: false,
          search: false,
        },
        {
          title: "map_string",
          dataIndex: "map_string",
          key: "map_string",
          editable: false,
        },
        {
          title: "trade_type",
          dataIndex: "trade_type",
          key: "trade_type",
          editable: false,
        },
        {
          title: "custodian_id",
          dataIndex: "custodian_id",
          key: "custodian_id",
          editable: false,
        },
        {
          title: "custodian",
          dataIndex: "custodian",
          key: "custodian",
          editable: false,
        },
        {
          title: "ticker",
          dataIndex: "ticker",
          key: "ticker",
          editable: false,
        },
      ];

    useEffect(()=>{
        fetchData().then((res)=>setLoading(false))
    },[])


    return(
    <ProTable<any>
        loading={loading}
        columns={coloumns}
        actionRef={ref}
        dataSource={data}
        onSubmit={ async(params:any) => {
            setLoading(true)
            const cloneParams = {...params , ...defaultParams}
            Object.keys(cloneParams).map((key:any)=>{
                if(cloneParams[key] !== undefined){
                    if(key == "map_string"){
                        cloneParams[key+ '__ilike'] = cloneParams[key].toString()
                        delete cloneParams[key]
                    }else{
                        cloneParams[key] = cloneParams[key].toString()
                    }
                }
            })
            const data= await ParsingMappingAPI.getTickerinParsingMapping(cloneParams)
            setData(await data)
            setLoading(false)
        }
        }
        onReset={async()=> {
            setLoading(true)
            const data= await ParsingMappingAPI.getTickerinParsingMapping(defaultParams)
            setData(await data)
            setLoading(false)
        }}
        toolBarRender={false}
        search={{filterType:"query"}}
    />
    )
}

export default PositionParsingComponent;