import { ActionType, ProTable } from "@ant-design/pro-components";
import { Table } from "antd";
import { SortOrder } from "antd/es/table/interface";
import { useEffect, useRef, useState } from "react";
import { ParsingMappingAPI } from "../../api";
import { ProColumns } from "@ant-design/pro-components"


const TickerParsing = () => {
    const params:any= {} ;
    const [data,setData]= useState<any[]>([])
    // const [newData,setNewData]=useState<any[]>[];
    const ref= useRef<ActionType>()
    interface Cols{
        id: any,
        custodian:any,
        custodian_id:any,
        section:any,
        trade_type:any,
        map_string:any,
        ticker:any
    } 
    const columns: ProColumns<Record<string, any>, "text">[] | { title: string; dataIndex: string; key: string; }[] | undefined =  [];



    const colee= [
        "ticker",
        "security_id",
        "security_type",
        "region",
        "detailed_description",
        "isin",
        "sec_name",
        "industry",
        "asset_class",
    ]

    const fetchData=async()=>{
        const data= await ParsingMappingAPI.callForTickerParsing(params)
        
        setData(await data)
        return data
    }
    colee.map((key:any) => {
        // if(key.startsWith("_") === false){
          columns.push({
            title: key,
            dataIndex: key,
            key: key,
          })
        // }
      })
    
    

    useEffect(()=>{
        fetchData()
    },[])


    return(
    <ProTable<any>
    columns={columns}
    actionRef={ref}
    dataSource={data}
    onSubmit={ async(params:any) => {

        const cloneParams = {...params}
        Object.keys(cloneParams).forEach((key) => {
            if(cloneParams[key]!== undefined ){
                if(key == "ticker" || key == "sec_name"){
                cloneParams[key + '__ilike']= cloneParams[key]
                delete cloneParams[key]
            }else {
                cloneParams[key]= cloneParams[key]
            }
            }                      
        })

        const data= await ParsingMappingAPI.callForTickerParsing(cloneParams)
        setData(await data)
        
    }
    }          
    onReset={async()=> {
        const data= await ParsingMappingAPI.callForTickerParsing(params)
        setData(await data)
    }}
    toolBarRender={false}
    search={{filterType:"query"}}
    />
    )
}

export default TickerParsing;