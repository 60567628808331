import { ActionType, EditableProTable, ProTable } from "@ant-design/pro-components";
import { Button, Popconfirm, Table } from "antd";
import { SortOrder } from "antd/es/table/interface";
import { useEffect, useRef, useState } from "react";
import { ParsingMappingAPI } from "../../api";
import { ProColumns } from "@ant-design/pro-components"


const PositionParsingEdit = () => {
    const defaultParams:any= {status: 0} ;
    const [data,setData]= useState<any[]>([])
    // const [newData,setNewData]=useState<any[]>[];
    const ref= useRef<ActionType>()
    const [loading,setLoading]=useState<boolean>(true)
    interface Cols{
        id: any,
        custodian:any,
        custodian_id:any,
        section:any,
        trade_type:any,
        map_string:any,
        ticker:any
    } 
    const columns: ProColumns<Record<string, any>, "text">[] | { title: string; dataIndex?: string | undefined; key?: string;  }[] | undefined =  [];

    let cols:ProColumns<Cols>[]=[];


    const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);

    const fetchData=async()=>{
      setLoading(true)
        const data = await ParsingMappingAPI.getTickerinParsingMapping(defaultParams)
        setData(await data)
        return data
    }



    const coloumns: ProColumns<Cols>[] = [

        {
          title: "section",
          dataIndex: "section",
          key: "section",
          editable: false,
        },
        {
          title: "id",
          dataIndex: "id",
          key: "id",
          editable: false,
          search: false,
        },
        {
          title: "map_string",
          dataIndex: "map_string",
          key: "map_string",
          editable: false,
        },
        {
          title: "trade_type",
          dataIndex: "trade_type",
          key: "trade_type",
          editable: false,
        },
        {
          title: "custodian_id",
          dataIndex: "custodian_id",
          key: "custodian_id",
          editable: false,
          search: false,
        },
        {
          title: "custodian",
          dataIndex: "custodian",
          key: "custodian",
          editable: false,
        },
        {
          title: "ticker",
          dataIndex: "ticker",
          key: "ticker",
          // editable: false,
        },
        {
          title: "action",
          valueType: "option",
          render: (text, record, _, action) => [
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              key="editable"
              onClick={() => {
                action?.startEditable?.(record.id);
              }}
            >
              Edit
            </a>,
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
           <>
           <Popconfirm
           title="Are you sure ?"
           okText="Yes"
           cancelText="No"
           onConfirm={()=>{
            ParsingMappingAPI.updateTicker(record?.id,{})
                fetchData().then(()=> setLoading(false))
           }}
           disabled={record?.ticker === null}
           >
              <a>

                Approve
              </a>
              
            </Popconfirm>
           </>
            ,
          ],
        },
      ];




    useEffect(()=>{
        fetchData().then(()=> setLoading(false))
    },[])




    return(
    <EditableProTable<any>
    loading={loading}
    rowKey="id"
    recordCreatorProps={false}
    defaultValue={data}
    value={data}
    columns={coloumns}
    actionRef={ref}
    // onChange={setData}
    dataSource={data}
    onSubmit={ async(params:any) => {
        setLoading(true)

        const cloneParams = {...params, ...defaultParams }
        console.log(cloneParams)
        Object.keys(cloneParams).map((key:any)=>{
          console.log(key)
            if(cloneParams[key] !== undefined){
              if(key === 'map_string'){
                cloneParams[key+ '__ilike'] = cloneParams[key]
                delete cloneParams[key]
              }  else {
                cloneParams[key] = cloneParams[key]
              }
                // typeof cloneParams[key] === 'string' && cloneParams[key].length === 0 &&
        } 
        })
        const data= await ParsingMappingAPI.getTickerinParsingMapping(cloneParams)
        setData(await data)
        setLoading(false)
    }
    }
    onReset={async()=> {
      setLoading(true)
        const data= await ParsingMappingAPI.getTickerinParsingMapping(defaultParams)
        setData(await data)
        setLoading(false)
    }}
    toolBarRender={false}
    search={{filterType:"query"}}
    pagination={{pageSize: 10}}
    editable={{
      actionRender: (row, config, defaultDom) => [defaultDom.save, defaultDom.cancel],

        type: 'single',
        editableKeys,
        deleteText: "",
        onSave: async (rowKey, data, row) => {
          await ParsingMappingAPI.updateTicker(rowKey, {ticker: data?.ticker})
          fetchData().then(()=> setLoading(false))
        },
        
        onChange: setEditableRowKeys,
      }}
    />
    )
}

export default PositionParsingEdit;