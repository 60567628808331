import React from 'react'
import { useState } from 'react'
import  ClientStatementsTable from './ClientStatementsTable'

function ClientStatements() {
  const [refresh,setRefresh] =useState<Boolean>(); 
  return (
    <div>
        <ClientStatementsTable setRefresh={()=>setRefresh((prev)=>!prev)}/>
    </div>
  )
}

export default ClientStatements