
// {
//   "trade_id": 1,
//   "timestamp": "2021-01-01T09:30:00",
//   "buyer": "Alice",
//   "seller": "Bob",
//   "security": "ABC Company",
//   "price": 100,
//   "quantity": 100,
//   "fee": 0
// }

// Date
// Time
// Buyer
// Seller
// Security
// Price
// Quantity
// Fee
// 2021-01-01
// 9:30 AM
// Alice
// Bob
// ABC Company
// $100
// 100
// $0
// 2021-01-01
// 9:45 AM
// Charlie
// Dave
// DEF Company
// $50
// 50
// $5
// 2021-01-01
// 10:00 AM
// Emily
// Frank
// GHI Company
// $25
// 25
// $0



import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components"
import { BlotterCreateForm } from "./BlotterCreateForm";
import Table from "../Refinary/common/Table";
import EditBlotterModal from "./EditBlotterModal";
import DeleteBlotterRow from "./DeleteBlotterRow";
import { blotterDataAPI } from "../../api";
import { SortOrder } from "antd/es/table/interface";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/features/userSlice";
import { useRef } from "react";

type IBlotterListTableData = {
    trade_id?: number;
    timestamp?: string;
    buyer?: string;
    seller?: string;
    security?: string;
    price?: number;
    quantity?: number;
    fee?: number;
}


const BlotterListTable = () => {
    const ethanToken = useSelector(selectUser);
    const actionRef = useRef<ActionType>();
    const columns: ProColumns<IBlotterListTableData>[] = [
        {
            title: "Cust Acc",
            dataIndex: "custodian_account",
            valueType: "text",
            key: "custodian_account",
        },
        {
            title: "Ticker",
            dataIndex: "ticker",
            valueType: "text",
            key: "ticker",
        },
        {
            title: "Name",
            dataIndex: "sec_name",
            valueType: "text",
            key: "sec_name",
        },
        {
            title: "Qty",
            dataIndex: "qty",
            valueType: "text",
            key: "qty",
        },
        {
            title: "Price",
            dataIndex: "price",
            valueType: "text",
            key: "price",
        },
        {
            title: "Cost Price",
            dataIndex: "cost_price",
            valueType: "text",
            key: "cost_price",
        },
        {
            title: "Unrealized P/L",
            dataIndex: "unrealized_pl",
            valueType: "text",
            key: "unrealized_pl",
        },
        {
            title: "MTM local",
            dataIndex: "mtm_local",
            valueType: "text",
            key: "mtm_local",
        },
        {
            title: "Position ID",
            dataIndex: "position_id",
            valueType: "text",
            key: "position_id",
        },
        {
            title: "Client ID",
            dataIndex: "client_id",
            valueType: "text",
            key: "client_id",
        },
        {
            title: "CCY",
            dataIndex: "currency",
            valueType: "text",
            key: "currency",
        },
        {
            title: "Custodian",
            dataIndex: "custodian_name",
            valueType: "text",
            key: "custodian_name",
        },
        {
            title: "MTM TPY",
            dataIndex: "mtm_tpy",
            valueType: "text",
            key: "mtm_tpy",
        },
        {
            title: "Action",
            dataIndex: "actions",
            key: "actions",
            fixed: 'right',
            width: 100,
            render: (text, record) => {
    
                return (
                <>
                {
                  <>
                    <EditBlotterModal record={record} actionRef={actionRef}/>
                    <DeleteBlotterRow record={record} actionRef={actionRef}/>
                  </>
                }
                </>
                )
              }
        }
    ]
    return (
        <>
        <BlotterCreateForm actionRef={actionRef}/>
        <Table<IBlotterListTableData>
            actionRef={actionRef}
          columns={columns}
          search={{filterType:"query"}}
          onSubmit={ (params:any) => console.log(params)}
          request={async (params = {}, sortObject: Record<string, SortOrder>) => {
            const ordering = Object.keys(sortObject).map((key) => {
                const value = sortObject[key];
                return `${value === "ascend" ? "" : "-"}${key}`;
              })?.join();
            const { pageSize: pageSizeLimit, current, ...customParams } = params;
          const response = await blotterDataAPI.getBlotterData({
            paging: {
              limit: pageSizeLimit,
              offset: (current as number - 1) * (pageSizeLimit as number),
              ordering,
            },
            token: ethanToken,
            customParams: {
              ...customParams,
            },
          });
            return {

                  data: response.data.results,
                  success: true,
              }
          }}
        />
        </>
    )
}


export default BlotterListTable