import { PageContainer } from '@ant-design/pro-components'
import React from 'react'
import PriceStatements from './components/PriceStatements'

function PriceMapping() {
  return (
    <PageContainer
    style={{
        marginTop: 20
    }}
    

     header={{
        title: "Price Mapping",
      }}
    >
        <PriceStatements/>
   {/* <TickerStatements/> */}
    </PageContainer>
  )
  
}

export default PriceMapping