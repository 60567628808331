import { ProFormSelect, ProFormText } from "@ant-design/pro-components";
import { Form } from "antd";
import { BaseOptionType } from "antd/es/select";


interface IClientReportFormFieldProps {
  clientId?: string;
  disable?: boolean;
  disabledLabel?: boolean;
  dependencies?: string[];
  shouldHaveCustodianName?: boolean;
  required?: boolean;
  onChange?: (value: string, options: BaseOptionType) => void;
}

export const enum OPTION_LABEL{
    LABEL='monthly report'
}
const ClientReportType = (props: IClientReportFormFieldProps) => {
  const { disable,required, disabledLabel, dependencies, onChange, shouldHaveCustodianName } = props;
  const form = Form.useFormInstance();
  const changeStatementType = (value: string, options: BaseOptionType) => {
    if(shouldHaveCustodianName){
      form?.setFieldsValue({
        statement_type: options.value,
      })
    }
    onChange?.(value, options);
  }

  return (<>
    <ProFormSelect
      name="statement_type"
      label={disabledLabel ? null : "Statement Type"}
      disabled={disable}
      showSearch
      dependencies={dependencies}
      debounceTime={300}
      options={[
        {
            value: 'MONTHLY_REPORT',
            label: OPTION_LABEL.LABEL
        }
      ]}
      fieldProps={{
        onChange: changeStatementType,
      }}
      placeholder="Select statement type"
      rules={[{ required, message: 'Please select the Statement Type' }]}
    />
    {shouldHaveCustodianName && <ProFormText name="statement_type" label="Statement Type" hidden />}
  </>
  )

}

export default ClientReportType;