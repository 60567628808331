import { ProFormSelect } from "@ant-design/pro-components";
// import { TRANSACTIONS_API_ROOT } from "../../helpers/utils";
import request from "axios";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux/features/userSlice";
import { dropdownsAPI } from "../../../../api";

interface ICustodianFormFieldProps {
  clientId?: string;
  custodianId?: string;
  disable?: boolean;
  required?: boolean;
  dependencies?: string[];
}

interface IClientCustodianRelationship {
  relationship_number: string;
}


const ClientRelationshipFormSelect = (props: ICustodianFormFieldProps) => {
  const { disable, clientId, required, custodianId, dependencies } = props;
  const ethanToken = useSelector(selectUser);

  return (<ProFormSelect
    name="relationship_no"
    label="Relationship No"
    disabled={disable}
    showSearch
    dependencies={dependencies}
    // debounceTime={300}
    request={async () => {
      const response = await dropdownsAPI.getClientRelationship({
        client_id: clientId,
        custodian_id: custodianId,
        token: ethanToken,
      })
      return response.data.map((relationship: IClientCustodianRelationship) => ({
        value: relationship.relationship_number,
        label: relationship.relationship_number,
      }))
    }}
    placeholder="Select Relationship No"
    rules={[{ required, message: 'Please select the custodian' }]}
  />)

}

export default ClientRelationshipFormSelect;