import { ProFormSelect } from "@ant-design/pro-components";
import Options from "./utils/options.json"

interface ICustodianFormFieldProps {
  clientId?: string;
  custodianId?: string;
  disable?: boolean;
  required?: boolean;
  dependencies?: string[];
}

// interface IClientCustodianRelationship {
//   relationship_number: string;
// }
const valueEnum = {
  3 : { text : 'Position and Transaction' } ,   
  1 : { text : 'Position' } ,   
  2 : { text : 'Transaction' } ,   
 
};


const StatementType = (props: ICustodianFormFieldProps) => {
  const { disable, required, dependencies } = props;
  return (<ProFormSelect
    name="statement_type"
    label="Statement type"
    disabled={disable}
    showSearch
    dependencies={dependencies}
    debounceTime={300}
    valueEnum={valueEnum}
    // request={async () => {
    //   const ethanToken = getCookie("ethan_token");
    //   const response = await request.get(`${TRANSACTIONS_API_ROOT}/relationship/`, {
    //     params: {
    //       client_id: clientId,
    //       custodian_id: custodianId,
    //     },
    //     headers: {
    //       Accept: "*/*",
    //       "x-api-key": ethanToken as unknown as string,
    //     },
    //   })
    //   return response.data.map((relationship: IClientCustodianRelationship) => ({
    //     value: relationship.relationship_number,
    //     label: relationship.relationship_number,
    //   }))
    // }}
    // options={Options}
    placeholder="Select Statement Type"
    rules={[{ required, message: 'Please select the custodian' }]}
    // {shouldHaveClientName && <ProFormText name="client_name" label="Client Name" hidden />}
  />)

}

export default StatementType;