/* eslint-disable jsx-a11y/anchor-is-valid */
import { ActionType, idIDIntl, ProColumns } from '@ant-design/pro-components';
import { ProCard, ProTable,EditableProTable } from '@ant-design/pro-components';
import type { BadgeProps } from 'antd';
import { Badge, Button } from 'antd';
import  request from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { TRANSACTIONS_API_ROOT } from '../../Refinary/helpers/utils';
import users from "../mockdata.json";
import { SortOrder } from 'antd/es/table/interface';
import ErrorBadge from './ErrorBadge';
import { tradeProxyAPI, validationAPI } from '../../../api';
import DrawerDetail from './DrawerDetail';
import EditModalRef from '../../TradesValidation/components/EditModalRef';
// @ts-ignore



type NewType = {
  ticket_ref: String;
  blotter_id: String;
  trade_date: String;
  settlement_date:String;
  name: String;
  action:String;
  tran_type:String;
  ccy: string;
  ticker: String;
  qty: number;
  contract_type:String;
  trade_price: number;
  sett_amount: number;
  custodian: string;
  account_code: string;

  account_id: number;
  ccy_account_code:string;
  ccy_account_id:number;
  bank_narration: String;
  refno?:any ,
  client_id:number;
  isin:String;
  id:number;
  comments:string;
  // mtm_usd:number;
  // running_total:number;
};


export type IpListItem = NewType;


type DetailTableprops = {
  setRefresh: (data: any) => void;
  rowId: number;
  drawerVist:Boolean;
  onChange: (id: number) => void;
  openStatementCreateForm: (value: boolean)=> void;
  setDrawerVisit: (value: boolean) => void ;
};

const DetailTable: React.FC<DetailTableprops> = (props) => {
  const { onChange, rowId,setDrawerVisit,drawerVist } = props;


  const fetchData = async() => {
    const response = await (tradeProxyAPI.getProxybyId(`${key}`))
        const obj = {
          total: response,
          data: response,
      }
      return obj;
  }

const editableCols = ['ccy','qty','ticket_ref','blotter_id','trade_date','settlement_date','name','action','tran_type' ,'ticker' ,'contract_type' ,'trade_price' ,'sett_amount' ,'custodian' ,'account_code' ,'account_id' ,'ccy_account_code' ,'ccy_account_id' ,'bank_narration' ,'mtm_usd' ,'running_total' ,'refno' ,'client_id' ,'isin' ,'comments']

  const columns: ProColumns<IpListItem>[] = [
    {
        title: 'Ccy',
        key: 'ccy',
        dataIndex: 'ccy',
        width:50,
        ellipsis: true,
      },
      {
        title: 'Quantity',
        key: 'qty',
        width: 80,
        ellipsis: true,
        dataIndex: 'qty',
        
      },
      {
        title: 'Ticket',
        key: 'ticker_ref',
        width: 60,
        ellipsis: true,
        dataIndex: 'ticket_ref',
        
      },
      {
          title: 'Blotter Id',
          key: 'blotter_id',
          width: 80,
          ellipsis: true,
          dataIndex: 'blotter_id',
        
      },
      {
          title: 'Trade date',
          key: 'trade_date',
          width: 100,
          ellipsis: true,
          dataIndex: 'trade_date',
        
      },
      {
          title: 'Settlement date',
          key: 'settlement_date',
          width: 100,
          ellipsis: true,
          dataIndex: 'settlement_date',
        

      },
      {
          title: 'Name',
          key: 'name',
          width: 120,
          ellipsis: true,
          dataIndex: 'name',
      },
      {
          title: 'Action',
          key: 'action',
          width: 70,
          ellipsis: true,
          dataIndex: 'action',
      },
      {
        dataIndex:'tran_type' ,
        width: 80,
        ellipsis: true,
        title:'tran_type',
        key: 'tran_type',
    
      },
      {
        dataIndex:'ticker' ,
        width: 120,
        ellipsis: true,
        title:'ticker',
        key: 'ticker',
    
      },
      {
        dataIndex:'contract_type' ,
        width: 100,
        ellipsis: true,
        title:'contract_type',
        key: 'contract_type',
    
      },
      {
        dataIndex:'trade_price' ,
        width: 95,
        ellipsis: true,
        title:'trade_price',
        key: 'trade_price',
    
      },
      {
        dataIndex:'sett_amount' ,
        width: 100,
        ellipsis: true,
        title:'sett_amount',
        key: 'sett_amount',
    
      },
      {
        dataIndex:'custodian' ,
        width: 120,
        ellipsis: true,
        title:'custodian',
        key: 'custodian',
    
      },
      {
        dataIndex:'account_code' ,
        width: 120,
        ellipsis: true,
        title:'account_code',
        key: 'account_code',
    
      },
      {
        dataIndex:'account_id' ,
        width: 100,
        ellipsis: true,
        title:'account_id',
        key: 'account_id',
    
      },
      {
        dataIndex:'ccy_account_code' ,
        width: 100,
        ellipsis: true,
        title:'ccy_account_code',
        key: 'ccy_account_code',
    
      },
      {
        dataIndex:'ccy_account_id' ,
        width: 100,
        ellipsis: true,
        title:'ccy_account_id',
        key: 'ccy_account_id',
    
      },
      {
        dataIndex:'bank_narration' ,
        width: 120,
        ellipsis: true,
        title:'bank_narration',
        key: 'bank_narration',
    
      },
      {
        dataIndex:'mtm_usd' ,
        width: 80,
        ellipsis: true,
        title:'mtm_usd',
        key: 'mtm_usd',
    
      },
      {
        dataIndex:'running_total' ,
        width: 100,
        ellipsis: true,
        title:'running_total',
        key: 'running_total',
    
      },
      {
        dataIndex:'refno' ,
        width: 80,
        ellipsis: true,
        title:'refno',
        key: 'refno',
    
      },
      {
        dataIndex:'client_id' ,
        width: 75,
        ellipsis: true,
        title:'client_id',
        key: 'client_id',
    
      },
      {
        dataIndex:'isin' ,
        width: 80,
        ellipsis: true,
        title:'isin',
        key: 'isin',
        renderText: (text) => {

          return  (<ErrorBadge status={text} />)
        }
      },
      {
        dataIndex:'id' ,
        width: 70,
        ellipsis: true,
        title:'id',
        key: 'id',
    
      },
      {
        dataIndex:'comments' ,
        width: 50,
        ellipsis: true,
        title:'comments',
        key: 'comments',
    
      },
      {
        title: "Edit",
        dataIndex: "edit",
        key: "edit",
        fixed: 'right',
        width: 100,
        render: (text, record) => {

          return (
          <>
            <EditModalRef 
              record={record} 
              columns={editableCols} 
              setRefresh={props.setRefresh} 
              id={rowId}
              onSave={() => { onSave()}}
            />
          </>
          )
        }
      }
  ];

  const actionRef = useRef<ActionType>();
  const pageSize= 10;
  const {key}=useParams();
  const [dataSource, setDataSource] = useState<readonly IpListItem[]>([]);

  useEffect(()=>{
    actionRef?.current?.reload();
  },[key])

  useEffect(()=>{
    actionRef?.current?.reload();
  },[drawerVist])

  const tradeValidationsTableRequest = async () => {
    const response = await (tradeProxyAPI.getProxybyId(`${key}`))
    const obj = {
        total: response,
        data: response,
    }
    return obj;
  }

  const onSave = () => {
    actionRef.current?.reload();
  }

  return (
    
    <EditableProTable<IpListItem>
      columns={columns}
      request={async (params = {}, sortObject: Record<string, SortOrder>) => {
        return tradeValidationsTableRequest();
      }}
    onChange={setDataSource}
    options={
      {
        density: true,
      }
    }
    actionRef={actionRef}
      rowKey="id"
      recordCreatorProps={false}
      pagination={{
        pageSize
      }}
      scroll={{ x: 760 }}
      toolBarRender={() => {
        return [
          <Button
          type="primary"
          key="save"
          onClick={async()=>{  
            const response= await tradeProxyAPI.getproxyData();
            response.map((data:any)=> 
            {
              if(data._key== key){
              try {
                validationAPI.create({id: key, stage: data.stage});
              } catch (error) {
                        // console.log(error)
                   }
            }}
            )
            await actionRef.current?.reload()
            }}
        >
          Re-Evaluate 
        </Button>,

        ];
      }}
      search={false}
      // onRow={(record) => {
      //   return {
      //     onClick: () => {
      //       if (record.id) {
      //         onChange(record.id);
      //         setDrawerVisit(true);
      //       }
      //     },
      //   };
      // }}
      defaultSize="small"
      
    />
  );
};

interface DataProps {
  setRefresh:(data:any)=>void,
}
 
const ClientStatementsTable = (props: DataProps) => {
  const [id, setId] = useState(0);
  const [drawerVisit, setDrawerVisit] = React.useState<boolean>(false);
  
  const openStatementCreateForm = () => {
    setDrawerVisit(true);
  }


  return (
    <ProCard  style={{display: "flex"}}>
      <ProCard ghost >
        <DetailTable openStatementCreateForm={() => { openStatementCreateForm() }}  onChange={(item:any) => setId(item)} rowId={id} setDrawerVisit={setDrawerVisit} drawerVist={drawerVisit} setRefresh={props.setRefresh}/>
      </ProCard>
        {/* <DrawerDetail id={id} drawerVisit={drawerVisit} setDrawerVisit={setDrawerVisit}/> */}
    </ProCard>
  );
};

export default ClientStatementsTable;
