import React from "react";
import { BetaSchemaForm, DrawerForm, ProFormColumnsType } from "@ant-design/pro-components"
import { Button, message } from "antd"
import { tableMappingAPI } from "../../../api";
import { useParams } from "react-router-dom";
import { POSITION_COLUMNS } from "../../../constants/positionColumns";

interface RefineryConfigurationProps {
  columns: string[],
  tableName?: string,
  tableConfiguration: any[],
  onSave: (values: any) => void
}

const RefineryConfiguration = (props: RefineryConfigurationProps) => {
  const { key } = useParams()

  let cols = props.columns.filter((item: any) => { return item != "_id" })
  const positionColumns: ProFormColumnsType<any>[] = cols.map((column) => {
    return {
      title: column,
      dataIndex: ["config", column],
      valueType: 'select',
      fieldProps: {
        mode: 'multiple',
      },
      valueEnum: POSITION_COLUMNS,
      width: 'md',
      colProps: {
        xs: 24,
        md: 12,
      },
    }
  });
  const tradeColumns: ProFormColumnsType<any>[] = cols.map((column) => {
    return {
      title: column,
      dataIndex: ["config", column],
      valueType: 'select',
      fieldProps: {
        mode: 'multiple',
      },
      valueEnum: {
        'security_id': 'security_id',
        'asset_class': 'asset_class',
        'action': 'action',
        'narration': 'narration',
        'description': 'description',
        'ccy': 'ccy',
        'amount': 'amount',
        'quantity': 'quantity',
        'price': 'price',
        'transaction_date': 'transaction_date(trade_date)', // Trade Date
        'settlement_date': 'settlement_date(value_date)', // Value Date
        'debit': 'debit',
        'credit': 'credit',
        'reference_number': 'reference_number',
        'transaction_number': 'transaction_number',
      },
      width: 'md',
      colProps: {
        xs: 24,
        md: 12,
      },
    }
  });
  tradeColumns.push({
    title: 'Asset Class Override',
    dataIndex: 'asset_class_override',
    valueType: 'select',
    valueEnum: {
      'EQUITY': 'Equity',
      'FIXED_INCOME': 'Fixed Income',
      'CASH': 'Cash',
      'COMMODITY': 'Commodity',
      'FX': 'FX',
      'BONDS': 'Bonds',
    },
    width: 'md',
    colProps: {
      xs: 24,
      md: 12,
    },
  })

  const assetClassEnum = {
    "accumulator": "Accumulator",
    "decumulator": "Decumulator",
    "options": "Options",
    "structured": "Structured",
    "fx_structured": "Fx Structured Product",
    "bond": "Bond",
    "equity": "Equity",
    "equity_fund": "Equity Fund",
    "fixed_income_fund": "Fixed Income Fund",
    "loan": "Loan",
    "equity_structured_product": "Equity Structured Product",
    "fund": "Fund",
    "derivative": "Derivative",
    "deposit": "Deposit",
    "alternative": "Alternative",
    "commodity": "Commodity",
    "bond_fund": "Bond Fund",
    "other": "Other",
    "fixed_income": "Fixed Income",
    "reit": "Reit",
    "non_financial_asset": "Non Financial Asset",
    "insurance": "Insurance",
    "multi_asset_fund": "Multi Asset Fund",
    "private_credit": "Private Credit",
    "cash": "Cash",
    "fx": "Fx",
  }

  const columns: ProFormColumnsType<any>[] = [
    {
      title: 'Source Table',
      dataIndex: 'table_name',
      valueType: 'text',
      width: 'm',
      readonly: true,
    },
    {
      title: 'Select Type of Table',
      dataIndex: 'target',
      valueType: 'select',
      valueEnum: {
        'POSITIONS': 'Position',
        'TRADES': 'Trade',
      },
      width: 'm',
    },
    {
      valueType: 'dependency',
      name: ['target'],
      columns: ({ target }) => {
        if (target === 'POSITIONS') {
          return [

            {
              title: 'Select the sub type of table',
              dataIndex: 'sub_type',
              valueType: 'select',
              fieldProps:{
                mode: 'multiple',
              },
              width: 'm',
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: 'Required Subtype for Position',
                  },
                ],
              },
              valueEnum: {
                "MIXED": "Position(Mixed)",
                'EQUITY': 'Equity',
                "EQUITY_DERIVATIVE": "Derivatives",
                "EQUITY_SP": "Equity Structured Products",
                'FIXED_INCOME': 'Fixed Income',
                'CASH': 'Cash',
                'COMMODITY': 'Commodity', // Metals, Energy, etc.
                "COMMODITY_DERIVATIVE": "Commodity Derivatives",
                'FX': 'FX',
                'FX_DERIVATIVE': 'FX Derivatives',
                'BOND': 'Bonds',
                "LOAN": "Loans, Fixed Advance, etc.",
                'ETF': 'ETF', // Exchange Traded Funds
                "FUNDS": "Funds, Mutual Funds, Hedge Funds, Private Equity", // Mutual Funds, Hedge Funds, Private Equity, etc.
                "DEPOSIT": "Deposits", // Bank Deposits, CDs, etc.
                "PORTFOLIO_VALUATION": "PORTFOLIO_VALUATION",
                "MONEY_MARKET": "Money Market", // Money Market Funds, Commercial Paper, etc.
                "FX_MARGIN": "FX Margin", // Not sure,
                "INVESTMENTS": "Investments", // Not sure,
              },
            },
            {
              title: 'Column Mapping',
              valueType: 'group',
              dataIndex: "config",
              columns: [
                ...positionColumns
              ]
          }];
        }
        if (target === 'TRADES') {
          return tradeColumns;
        }
        return [];
      },
    },
    {
      valueType: 'dependency',
      name: ['target'],
      columns: ({ target }) => {
        if (target === 'POSITIONS') {
          return [ {
            title: 'Asset Regex Mapper',
            valueType: 'formList',
            dataIndex: 'asset_regex',
            colProps: {
              xs: 24,
              sm: 12,
            },
            columns: [
              {
                valueType: 'group',
                columns: [
                  {
                    title: 'Asset',
                    valueType: 'select',
                    dataIndex: 'asset_type',
                    width: 'xs',
                    valueEnum: assetClassEnum,
                  },
                  {
                    title: 'Regex',
                    valueType: 'text',
                    dataIndex: 'regex',
                    colProps: {
                      xs: 24,
                      sm: 12,
                    },
                    width: 'xs',
                  }
                ],
              },
            ],
          }
        ];
        }
        return [];
      },
    },
  ]

  //using let to avoid pipeline error for now
  const [initialValues, setInitialValues] = React.useState<any>({})

  React.useEffect(() => {
    if (props?.tableConfiguration?.length > 0) {
      const { source, target, sub_type, ...rest } = props?.tableConfiguration?.[0];
      const config = rest?.config;
      const configKeys = Object.keys(rest)
      configKeys.forEach((key) => {
        if (config[key] === null) {
          config[key] = [];
        }
      });
      const regexObject = rest?.asset_regex || {};
      const asset_regex = Object?.keys(regexObject)?.map((key) => {
        return {
          asset_type: key,
          regex: regexObject[key],
        }
      }) || [];
      setInitialValues({
        table_name: source,
        target: target,
        sub_type: sub_type,
        asset_regex,
        config,
      })
    } else {
      setInitialValues({
        table_name: props?.tableName,
      })
    }
  }, [props?.tableConfiguration])


  return (
  <DrawerForm
    title={`Refinery Configuration`}
    trigger={
      <Button type="ghost">
        Configure Table
      </Button>
    }
    initialValues={initialValues}
    onFinish={async (values) => {
      if (values?.asset_regex?.length > 0) {
        //  Make a object from array of objects
        const regexMapper = values.asset_regex.reduce((acc: any, curr: any) => {
          acc[curr.asset_type] = curr.regex;
          return acc;
        }, {});
        values.asset_regex = regexMapper;
      }

      try {
        const response = await tableMappingAPI.createTableMappingFromRefinery(
          key as string,
          values
        )
        props.onSave(response);
        message.success('Configuration Added')
      } catch (errorResponse: any) {
        message.error(errorResponse?.response?.data?.detail);
      }
    }}
  >
    <BetaSchemaForm<typeof columns>
      columns={columns}
      layoutType="Embed"
      rowProps={{
        gutter: [16, 0],
      }}
      colProps={{
        span: 6,
      }}
    />
  </DrawerForm>
  )
}
export default RefineryConfiguration;