// Collect the data from the form and send it to the backend
// Compare this snippet from frontend/src/Routes/Refinary/Components/Statement/StatementCreateForm.tsx

import { DrawerForm, ProDescriptions, ProForm, ProFormCheckbox, ProFormList, ProFormText } from "@ant-design/pro-components";
import { Button, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import { fxAPI, statementAPI } from "../../api";
import CurrencyFormSelect from "./CurrencyFormSelect";
import { EditOutlined } from "@ant-design/icons";
interface IFxRate {
  currency: string;
  rate: string;
  xrate: string;
}

interface IStatementFxRate {
  reportingCurrency: string;
  fxRates: IFxRate[];
}


const handleCreate = async (statementId: string, values: IStatementFxRate) => {
  const hide = message.loading('Action in progress..');
  try {
    await fxAPI.updateFxRate(statementId, values);
    hide();
    message.success('Action successful.');
    return true;
  } catch (error) {
    hide();
    message.error('Action failed, please try again.');
    return false;
  }
}

interface IProps {
  statementId: string;
}

const FxRatesDrawerForm = (props: IProps) => {
  const { statementId } = props;
  const [initialValues, setInitialValues] = React.useState<IStatementFxRate>();
  const getStatementById = async (statementId: string) => {
    const payload = await fxAPI.getFXRate(statementId);
    setInitialValues(payload as IStatementFxRate);
  }
  React.useEffect(() => {
    if (statementId) {
      getStatementById(statementId);
    }
  }, [statementId]);

  return (
    <>
    <DrawerForm<IStatementFxRate>
      title="Update FX Rates"
      initialValues={initialValues}
      trigger={
        <Button>
          FX Rates
          <EditOutlined /> 
        </Button>
      }
      onFinish={async (values) => {
        const success = await handleCreate(statementId, values);
        if (success) {
          fxAPI.getFXRate(statementId);
          return true;
        }
        return false;
      }}
    >
      <CurrencyFormSelect
        name="reportingCurrency"
        label="Reporting Currency"
        rules={[
          {
            required: true,
            message: 'Reporting Currency is required',
          },
        ]}
      />
      <ProFormList

        name="fxRates"
        label="FX Rates"
        creatorButtonProps={{
          position: 'top',
        }}
        alwaysShowItemLabel
        itemContainerRender={(doms) => {
          return <ProForm.Group>{doms}</ProForm.Group>;
        }}
      >
         {(f, index, action) => {
          return (
            <>
              <CurrencyFormSelect
                rules={[
                  {
                    required: true,
                    message: 'Currency is required',
                  },
                ]}
              />
              <ProFormText
                name="rate"
                label="Rate"
                rules={[
                  {
                    required: true,
                    message: 'Rate is required',
                  },
                ]}
              />
              <ProFormCheckbox
                name="reverse"
                label="Reverse"
              />
            </>
          )
         }}
         </ProFormList>
    </DrawerForm>
    </>
  );
};

export default FxRatesDrawerForm