import { ProFormSelect, ProFormText } from "@ant-design/pro-components";
import request from "axios";
// import { TRANSACTIONS_API_ROOT } from "../../helpers/utils";
import React from "react";
import { Form } from 'antd';
import { BaseOptionType } from "antd/es/select";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/features/userSlice";
import { dropdownsAPI } from "../../api";

export interface ISecuritiesFormFieldProps {
    name?: string;
    shouldHaveClientName?: boolean;
    disabledLabel?: boolean;
    required?: boolean;
    onChange?: (value: string, options: BaseOptionType) => void;
    assetType?: string;
    dependencies?: string[];
    disable?: boolean;
  }

const SecurityFormSelect = (props:ISecuritiesFormFieldProps) => {
  const form = Form.useFormInstance();
  const {shouldHaveClientName,onChange,dependencies,assetType,disable} = props;
  const changeSecurityName = (value: string, options: BaseOptionType) => {
    if(shouldHaveClientName){
      form?.setFieldsValue({
        client_name: options.label,
      })
    }
    onChange?.(value, options);
  }
  const ethanToken= useSelector(selectUser);


  return (<>
    <ProFormSelect
      name={"security"}
      label={"Security"}
      dependencies={dependencies}
      disabled={disable}
      width="lg"
      showSearch
      debounceTime={1000}
      request={async (params) => {
        if(params?.keyWords){
          const response = await dropdownsAPI.getSecurities({
            token: ethanToken,
            search: params?.keyWords,
            assetType: assetType,
          });
          return response.data?.asset_list?.map((security:any) => {
          return {
            value: security,
            label: security,
          }})
        }
        return [];
      }}
      fieldProps={{
        onChange: changeSecurityName,
      }}
      placeholder="Search for a security"
      rules={[
        {
          required: true,
          message: "Security is required",
        },
      ]}
    />
  </>)
}

export default SecurityFormSelect;