import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/userSlice';
import tabSlice from '../features/tabSlice';
import tableDataSlice from '../features/Tables/tableDataSlice';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
}


const rootReducer = combineReducers({ user: userReducer, tab: tabSlice, tableData: tableDataSlice }); 

const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
  reducer: persistedReducer,
})

export const persistor = persistStore(store)
export type AppDispatch = typeof store.dispatch

