/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
import {
  DrawerForm,
  ProFormDatePicker,
  ProFormDependency,
  ProFormGroup,
  ProFormText,
  ProFormUploadDragger,
} from '@ant-design/pro-components';

import {
  Form, message, Upload
} from 'antd';
import moment from 'moment';
import CustodianFormSelect from '../../Components/FormFields/ClientCustodianFormField';
import ClientFormSelect from '../../Components/FormFields/ClientFormField';
import ClientRelationshipFormSelect from '../../Components/FormFields/ClientRelationshipFormField';
import request, { AxiosProgressEvent } from 'axios';
// import { TRANSACTIONS_API_ROOT } from '../../helpers/utils';
import React from 'react';
import { removePropertiesFromObject } from '../../common/removeProperty';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../redux/features/userSlice';
import { preSignAPI,deleteFileAPI,statementAPI, StatementUploadStatus } from "../../../../api";
import Textfield from '../FormFields/Textfield';
import StatementType from '../FormFields/StatementType';
interface IUploadFileToS3 {
  file: File;
  onProgress?: (event: UploadProgressEvent) => void;
  onError?: (event: UploadRequestError | ProgressEvent) => void;
  onSuccess?: (body: string, xhr?: XMLHttpRequest) => void;
}


interface IStatementCreateFormProps {
  drawerVisit: boolean;
  setDrawerVisit?: (value: boolean) => void;
}

interface IPresignedPostResponseData {
  url: string;
  fields: {
    key: string;
    AWSAccessKeyId: string;
    policy: string;
    signature: string;
  }
}

export interface UploadProgressEvent extends Partial<ProgressEvent> {
  percent?: number;
}
export declare type UploadRequestMethod = 'POST' | 'PUT' | 'PATCH' | 'post' | 'put' | 'patch';
export interface UploadRequestError extends Error {
  status?: number;
  method?: UploadRequestMethod;
  url?: string;
}

// interface IUploadFileToS3 {
//   file: File;
//   onProgress?: (event: UploadProgressEvent) => void;
//   onError?: (event: UploadRequestError | ProgressEvent) => void;
//   onSuccess?: (body: string, xhr?: XMLHttpRequest) => void;
// }



export default  (props: IStatementCreateFormProps) => {
  const { drawerVisit, setDrawerVisit } = props;
  //NOTE: We are using let instead of useState because uploadFileToS3 runs before useState (data is still in pipeline when func is called )
  let signedUploadResponse: IPresignedPostResponseData;
  let fileKey: string;
  // const [signedUploadResponse, setSignedUploadResponse] = React.useState<IPresignedPostResponseData>();
  // const [fileKey, setFileKey] = React.useState<string>();
  const [form] = Form.useForm();
  const uploadFileToS3 = (presignedPostData: IPresignedPostResponseData | null) => 
    async (fileUpload: IUploadFileToS3): Promise<boolean> => {
      const { file, onSuccess, onError, onProgress } = fileUpload;
      if(!presignedPostData){
        return false;
      }
      const formData = new FormData();
      // Map all the fields
      (Object.keys(presignedPostData.fields) as (keyof typeof presignedPostData.fields)[]).forEach((key) => {
        const value = presignedPostData?.fields[key];
        formData.append(key as string, value as string);
      });
      formData.append('file', file);
      const response = await request.post(presignedPostData.url,formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (event: AxiosProgressEvent) => {
          //
          if (event.bytes) {
            onProgress?.({
              percent: (event.loaded / event.bytes) * 100,
            });
          }
        }
      });
      if(response.status === 204) {
        onSuccess?.("Ok");
        form.setFieldsValue({
          file: presignedPostData.fields.key,
        })
        return true;
      }
      const error = new Error("Error uploading the file");
      onError?.(error);
      return false;
    };
   
    const ethanToken = useSelector(selectUser);
    

  return (
    <DrawerForm<{
      name: string;
      company: string;
    }>
      onOpenChange={setDrawerVisit}
      title="Upload Statement"
      form={form}
      open={drawerVisit}
      initialValues={{
        month: moment().subtract(1, 'months').endOf('month'),
        report_date: moment().subtract(1, 'months').endOf('month').toISOString(),
      }}
      
      autoFocusFirstInput
      // drawerProps={{
      //   destroyOnClose: true,
      //   onClose: async() => {
      //     if(fileKey){
      //       // Delete file
      //       await deleteFileAPI.deletFile({
      //         token: ethanToken,
      //         file_key:fileKey
      //       });
      //     }
      //   }
      // }}
      submitTimeout={2000}
      // onChangeCapture={(e)=>console.log(e)}
      // onFieldsChange={async(changedValues:any,allValues:any)=>{
      //   const name= changedValues[0].name?.map((item:any)=>  item)
      //   console.log(form.getFieldValue(name[0]))

      //   const formData = removePropertiesFromObject(allValues, ["upload", "month"]);
      //   // console.log(changedValues,allValues)
      //   const requiredFields = ["client", "custodian", "report_date", "statement_type"];
        
      //   const hasRequiredFields = requiredFields.every((field) => allValues[field]);
      //   if (hasRequiredFields) {
      //     console.log("first")
      //     // if (allValues.id){
      //     //   console.log(allValues,changedValues)
      //     //   if(changedValues.upload){
      //     //     const data = await statementAPI.patchTrasactionStatement(allValues.id,{file_name: fileKey },ethanToken)
      //     //     const data2 = await statementAPI.patchTrasactionStatement(allValues.id,{original_file_name: allValues.original_file_name },ethanToken)
      //     //   }else{
      //     //       const data = await statementAPI.patchTrasactionStatement(allValues.id,changedValues,ethanToken)
      //     //   }
      //     } else{
      //     //   // Create
      //     //   const data = statementAPI.postStatements({
      //     //     token: ethanToken,
      //     //     data: allValues
      //     //   })
      //     //   const res= await data;
      //     //   // console.log(res)
      //     //   form.setFieldsValue({
      //     //     id: res?.data?.id
      //     //   })
      //     // }
      //   }
      // }}

      onBlur={async (e:any)=>{
        const allValues = form.getFieldsValue();

        const requiredFields = ["client", "custodian", "report_date", "statement_type"];
        
          const hasRequiredFields = requiredFields.every((field) => allValues[field]);
          if (hasRequiredFields) {
            if (allValues.id){
              if(allValues.upload){
                const data = await statementAPI.patchTrasactionStatement(allValues.id,{file_name: fileKey },ethanToken)
                const data2 = await statementAPI.patchTrasactionStatement(allValues.id,{original_file_name: allValues.original_file_name },ethanToken)
              }else{
                  const data = await statementAPI.patchTrasactionStatement(allValues.id,allValues,ethanToken)
              }
            } else{
              // Create
              const data = statementAPI.postStatements({
                token: ethanToken,
                data: allValues
              })
              const res= await data;
              // console.log(res)
              form.setFieldsValue({
                id: res?.data?.id
              })
            }
          }
      }}
      // onValuesChange={async (changedValues, allValues) => {
        
      // }}
      onFinish={async (values) => {
        const formData:any = removePropertiesFromObject(values, ["upload", "month"]);
        // console.log(formData)
        try{
          const newData= await statementAPI.patchTrasactionStatement(formData?.id,formData,ethanToken)

          if(newData.status===200){
            const {data, status} = await statementAPI.updateTrasactionStatus( formData?.id,StatementUploadStatus.COMPLETED,ethanToken)
          if (status !== 200){
            message.error(data.error);
            return false;
          }
          message.success('Success');
          return true;
          }

          // console.log(newData.status)
          
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }catch(error: any){
          message.error(error?.response?.data?.error || "Unexpected Error");
          return false;
        }
      }}
    
    >
      <ProFormGroup>
        <ProFormText name="id" hidden />
        <ProFormText name="original_file_name" hidden />
        <ProFormText name="report_date" hidden />
        <ClientFormSelect shouldHaveClientName />
        <ProFormDependency name={['client']}>
          {({ client }) => <CustodianFormSelect dependencies={['client']} shouldHaveCustodianName  clientId={client} disable={!client} />}
        </ProFormDependency>
      </ProFormGroup>
      <ProFormGroup
        style={{
          margin: '32px 0px',
        }}
      >
        <ProFormDependency name={['client', 'custodian']}>
          {({ client, custodian }) => (
            <>
              <ProFormDatePicker
                name="report_date"
                disabled={!client || !custodian}
                label="Date"
                rules={[{ required: true, message: 'Please select the Date' }]}
                placeholder="Select Date"
                dataFormat='ISOString'
                fieldProps={{
                  onChange: (value)=> 
                  {
                    console.log(value)
                    form.setFieldsValue({
                      report_date: value?.toISOString()
                    })
                  }
                }}
              />
            </>
          )}
        </ProFormDependency>
        <ProFormDependency name={['client', 'custodian']}>
          {({ client, custodian }) => <StatementType dependencies={['client','custodian']} clientId={client} custodianId={custodian} disable={!client || !custodian } />}
        </ProFormDependency>
      </ProFormGroup>

    <ProFormGroup 
    style={{
      margin:"32px 0px"
    }}>


<ProFormDependency name={['client', 'custodian','statement_type']}>
          {({ client, custodian,statement_type }) => <ClientRelationshipFormSelect dependencies={['client','custodian']} clientId={client} custodianId={custodian} disable={!client || !custodian || !statement_type} />}
        </ProFormDependency>

        <ProFormDependency name={['client', 'custodian','statement_type']} style={{marginTop:"1em"}}>
          {({ client, custodian ,statement_type}) => <Textfield label="Portfolio No." name="portfolio_no" dependencies={['client','custodian','statmentType']}  disable={!client || !custodian ||!statement_type } />}
        </ProFormDependency>
        <ProFormDependency name={['client', 'custodian','statement_type']} style={{marginTop:"1em"}}>
          {({ client, custodian ,statement_type}) => <Textfield label="Cash Account No." name="cash_account_no" dependencies={['client','custodian','statmentType']}  disable={!client || !custodian  ||!statement_type } />}
        </ProFormDependency>
    </ProFormGroup>


      <ProFormDependency name={['client_name', 'custodian_name', 'report_date',"statement_type"]}>
        {({client_name: clientName, custodian_name: custodianName, report_date , statement_type}) => (
          <>
            <ProFormUploadDragger
              disabled={!clientName || !custodianName || !statement_type}
              name="upload"
              style={{width:"100%"}} max={1} 
              label="Upload"
              title="Upload Statement"
              description={(
                <>
                  Support for a single.
                  <br/>
                  Client and custodian information must be provided
                </>
              )}
              fieldProps={{
                multiple: false,
                customRequest: async ({file, onProgress, onSuccess, onError}) => {
                  uploadFileToS3(signedUploadResponse || null)({
                    file: file as File,
                    onProgress,
                    onSuccess,
                    onError,
                  })
                },
                beforeUpload: async (file) => {
                  form.setFieldsValue({
                    original_file_name: file?.name,
                  });
                  const isPDF = file.type === 'application/pdf';
                  if (!isPDF) {
                    message.error(`${file.name} is not a pdf file`);
                  }
                  const fileDataResponse = {
                    file_name: `${clientName}_${custodianName}_${moment().toISOString()}.pdf`,
                    file_path: `${moment(report_date).format("YYYY-MMM")}/${clientName}/${custodianName}/`
                  }
                  const response: { data: IPresignedPostResponseData } = await preSignAPI.preSign({
                    data: fileDataResponse,
                    token: ethanToken,
                  })
                  signedUploadResponse= response.data;
                  fileKey=response?.data?.fields?.key;
                  // setSignedUploadResponse(response.data);
                  // setFileKey(response?.data?.fields?.key);
                  return isPDF || Upload.LIST_IGNORE;
                },

                data: () => (fileKey ? { key: fileKey } : {}),
              }}
            />
            <ProFormText name="file" hidden />
          </>
        )}
      </ProFormDependency>
    </DrawerForm>
  );
};