

import { ActionType, CaptFieldRef, ProFormSelect, ProFormText } from "@ant-design/pro-components";
import { Form } from "antd";
import { BaseOptionType } from "antd/es/select";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux/features/userSlice";
import { dropdownsAPI } from "../../../../api";

interface ICustodianFormFieldProps {
  name?: string;
  clientId?: string;
  disable?: boolean;
  disabledLabel?: boolean;
  dependencies?: string[];
  shouldHaveCustodianName?: boolean;
  required?: boolean;
  onChange?: (value: string, options: BaseOptionType) => void;
}

interface ICustodian {
  custodian_id: string;
  custodian_name: string;
  custodian_code: string;
}


const CustodianFormSelect = (props: ICustodianFormFieldProps) => {
  const { name ,disable, clientId, required, disabledLabel, dependencies, onChange, shouldHaveCustodianName } = props;
  const form = Form.useFormInstance();
  const ethanToken =useSelector(selectUser);
  const changeCustodianName = (value: string, options: BaseOptionType) => {
    if(shouldHaveCustodianName){
      form?.setFieldsValue({
        custodian_name: options.label,
      })
    }
    onChange?.(value, options);
  }
  return (<>
    <ProFormSelect
      name={name ? name : "custodian"}
      label={disabledLabel ? null : "Custodian"}
      disabled={disable}
      showSearch
      dependencies={dependencies}
      debounceTime={300}
      request={async () => {
        const response = await dropdownsAPI.getCustodian({
          client_id: clientId,
          token: ethanToken,
        })
        return response.data.map((custodian: ICustodian) => ({
          value: custodian.custodian_id,
          label: `${custodian?.custodian_code} - ${custodian?.custodian_name}`,
        }))
      }}
        
      fieldProps={{
        onChange: changeCustodianName,
      }}
      placeholder="Select custodian"
      rules={[{ required, message: 'Please select the custodian' }]}
    />
    {shouldHaveCustodianName && <ProFormText name="custodian_name" label="Custodian Name" hidden />}
  </>
  )

}

export default CustodianFormSelect;



