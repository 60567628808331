import { PlusOutlined } from "@ant-design/icons";
import { ActionType, DrawerForm, ProFormDatePicker, ProFormDependency, ProFormGroup, ProFormSelect, ProFormSwitch, ProFormText, ProFormTextArea } from "@ant-design/pro-components";
import { Button,Input } from "antd";
import { useForm } from "antd/es/form/Form";
import ClientFormSelect from "../Refinary/Components/FormFields/ClientFormField";
import CustodianFormSelect from "../Refinary/Components/FormFields/ClientCustodianFormField";
import SecurityFormSelect from "./SecurityFormSelect";
import CustodianAccFormSelect from "./CustodianAccFormSelect";
import {selectUser} from "../../redux/features/userSlice";
import {useSelector} from "react-redux";
import { blotterDataAPI } from "../../api";
import { useEffect, useState } from "react";
import { useDebounce } from 'use-debounce';

interface IBlotterCreateFormProps {
  actionRef: React.MutableRefObject<ActionType | undefined>;
}

export const BlotterCreateForm = (props:IBlotterCreateFormProps) => {
  const ethanToken= useSelector(selectUser);
  const { actionRef } = props;
  const [form] = useForm();

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 1000);

  useEffect(
    () => {
        console.log(debouncedSearchValue);
    },  [debouncedSearchValue]);
  return (
    <DrawerForm<{
      name: string;
      company: string
    }>
      form={form}
      title="Create a trade"
      onFinish={async (values) => {
        console.log(values);
        await blotterDataAPI.addBlotterData(values,ethanToken);
        actionRef.current?.reload();
        return true;
      }}
      trigger={
        <Button type="primary">
          <PlusOutlined />
          New
        </Button>
      }
    >
      <ProFormGroup>
      <ClientFormSelect shouldHaveClientName />
      <ProFormDependency name={['client']}>
          {({ client }) => <CustodianFormSelect dependencies={['client']} shouldHaveCustodianName  clientId={client} disable={!client} />}
        </ProFormDependency>
        <ProFormDependency name={['client', 'custodian']}>
          {({ client, custodian}) => <CustodianAccFormSelect dependencies={['client','custodian']} clientId={client} custodianId={custodian} disable={!client || !custodian} />}
        </ProFormDependency>
      </ProFormGroup>
      <ProFormGroup>
        <ProFormSelect 
          rules={[{
            required: true,
            message: "Asset type is required",
          }]}
          name="asset_type"
          label="Asset Type"
          placeholder="Enter Asset Type"
          options={[
            {
              value: "Common Stock",
              label: "Equity",
            },
            { 
              value: "Bond",
              label: "Bond",
            },
          ]}

        />
        <ProFormDependency name={['asset_type']}>
          {({ asset_type }) => <SecurityFormSelect dependencies={['asset_type']} assetType={asset_type} disable={!asset_type} />}
        </ProFormDependency>
      </ProFormGroup>  
      <ProFormGroup>
        <ProFormSelect
          rules={[
            {
              required: true,
              message: "Type is required",
            },
          ]}
          name="type"
          label="Type"
          placeholder="Enter Type"
          options={[
            {
              value: "Buy",
              label: "Buy",
            },
            {
              value: "Sell",
              label: "Sell",
            },
          ]}
        />
        <ProFormDatePicker 
          rules={[
            {
              required: true,
              message: "Trade date is required",
            },
          ]}
          name="trade_date"
          label="Trade Date"
          placeholder="Enter Trade Date"

        />
        <ProFormDatePicker 
          rules={[
            {
              required: true,
              message: "Settlement date is required",
            },
          ]}
          name="settlement_date"
          label="Settlement Date"
          placeholder="Enter Settlement Date"

        />
      </ProFormGroup>
      <ProFormGroup>
      <ProFormText
          rules={[
            {
              required: true,
              message: "QTY is required",
            },
          ]}
          name="qty"
          label="QTY"
          placeholder="Enter QTY"
        />
        <ProFormText
          rules={[
            {
              required: true,
              message: "Price is required",
            },
          ]}
          name="price"
          label="Price"
          placeholder="Enter Price"
        />
        <ProFormText
          rules={[
            {
              required: true,
              message: "settlement amount is required",
            },
          ]}
          name="settlement_amount"
          label="Settlement Amount"
          placeholder="Enter Settlement Amount"
        />
      </ProFormGroup>
    </DrawerForm>
  );
}