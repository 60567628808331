import { ProDescriptions, ProTable } from "@ant-design/pro-components";
import { Button, message, Tag } from "antd";
import moment from "moment";
import React from "react";
import { fxAPI } from "../../../api";


interface IFxRate {
  currency: string;
  rate: string;
  xrate: string;
  reverse: boolean;
  marketValue?: string;
  marketCrossRate?: string;
  difference?: number;
}

interface IStatementFxRate {
  reportingCurrency: string;
  fxRates: IFxRate[];
}

interface IProps {
  statementId: string;
  reportDate?: string;
}

const FxRateDetails = (props: IProps) => {
  const { statementId, reportDate } = props;
  const [data, setData] = React.useState<IStatementFxRate>();
  const [fxData, setFxData] = React.useState<[]>();
  console.log(data)

  const getStatementById = async (statementId: string) => {
    const payload: IStatementFxRate = await fxAPI.getFXRate(statementId);
    const fxDataPayload = await fxAPI.listFxRate({
      fxdate__gte: moment(reportDate).startOf("D").toISOString(),
      fxdate__lte: moment(reportDate).endOf("D").toISOString(),
      ccy: payload?.reportingCurrency
    });
    for (let i = 0; i < payload.fxRates.length; i++) {
      const fxRate = payload.fxRates[i];
      fxDataPayload.find((item: any) => {
        if(item.ccy === fxRate.currency && item.xccy === payload.reportingCurrency){
          fxRate.marketValue = item.rate;
          fxRate.marketCrossRate = item?.xrate || (1 / item?.rate) ;
          // Difference in percentage
          if(fxRate?.marketValue && fxRate?.marketCrossRate){
            const diff = (parseFloat(fxRate.rate) - parseFloat(fxRate?.marketValue));
            fxRate.difference = Math.abs(diff / parseFloat(fxRate?.marketValue) * 100);
            if(fxRate.reverse){
              const diff = (parseFloat(fxRate.rate) - parseFloat(fxRate?.marketCrossRate));
              fxRate.difference = Math.abs(diff / parseFloat(fxRate?.marketCrossRate) * 100);
            }
          }
        }
      });
    }
    setFxData(fxDataPayload);
    setData(payload);
  }

  const pushFxRate = async (statementId: string) => {
    try{
      await fxAPI.pushFxRate(statementId);
      message.success("Successfully pushed FX Rates");
    }catch(err: any){
      message.error(err?.response?.data?.detail || "Failed to push FX Rates");
    }
  }

  React.useEffect(() => {
    getStatementById(statementId);
  }, [statementId]);

  // console.log("fxData",fxData);

  // IStementFxRate data in a card
  // Display the data in a table for IFxRate
  return (
    <>
      <ProDescriptions<IStatementFxRate>
          title="FX Rates"
          column={2}
          dataSource={data}
        >
          <ProDescriptions.Item label="Reporting Currency" dataIndex="reportingCurrency" />
          <ProDescriptions.Item>
            <Button onClick={() => { 
              pushFxRate(statementId);
            }}>Push</Button>
          </ProDescriptions.Item>
        </ProDescriptions>
      <ProTable<IFxRate>
        columns={[
          {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
          },
          {
            title: 'Statement Rate',
            dataIndex: 'rate',
            key: 'rate',
          },
          {
            title: 'Statement Cross',
            dataIndex: 'xrate',
            key: 'xrate',
          },
          {
            title: 'Reversed?',
            dataIndex: 'reverse',
            renderText: (reverse: boolean) => { return reverse ? 'Yes' : 'No'; },
            key: 'reverse',
          },
          {
            title: 'Market Value',
            dataIndex: 'marketValue',
            key: 'marketValue',
          },
          {
            title: 'Market Cross Rate',
            dataIndex: 'marketCrossRate',
            key: 'marketCrossRate',
          },
          {
            title: 'Difference',
            dataIndex: 'difference',
            key: 'difference',
            renderText: (difference: number) => {
             const displayValue =  difference ? difference.toFixed(2) + '%' : '';
             let color = 'green';
             if(difference && difference > 4){
                color = 'red';
             } else if(difference && difference > 2){
                color = 'orange';
             } else if(difference && difference > 1.5){
                color = 'yellow';
              }
             return(<Tag color={color}>{displayValue}</Tag>)
            },
          },
        ]}
        dataSource={data?.fxRates}
        search={false}
        options={false}
        rowKey="currency"
        pagination={false}
      />
    </>
  )
}

export default FxRateDetails;