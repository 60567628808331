import { DrawerForm, ProFormInstance } from '@ant-design/pro-components';
import React from 'react'
import type { ActionType, ProColumns } from '@ant-design/pro-components';
import {  ProTable } from '@ant-design/pro-components';
import  { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {tradeProxyAPI} from "../../../api"
import FieldForms from './FieldForms';

interface IStatementCreateFormProps {
    id:number;
    drawerVisit?: boolean;
    setDrawerVisit?: (value: boolean) => void;
  }
  
  
  type ExceptionArrayType ={
    message: String,
    function: String,
    id: String, 
    key: String,
  } 
  
  type TableListItem = {
      id: string,
      exception: ExceptionArrayType[];
  };

  interface DetailProps {
    id2: Number
    changeException:(data:any)=>void;
  }

  const DetailList: React.FC<DetailProps> = (props) => {
    const {id2,changeException}= props;
    const [exception,setException]= useState<any []>()
  
    const columns: ProColumns<TableListItem>[] = [
      {
        title: 'Id',
        key: 'rowId',
        dataIndex: 'id',
        width:10,
        render: (_,data) => data.id,
        search:false
 
      },
      {
        title: 'Exception',
        key: 'exception',
        width: 80,
        dataIndex: "message",
        search:false
      },
      {
        title: 'Function',
        key: 'function',
        width: 80,
        dataIndex: "function",
        search:false
      },
    ];
    const {id}=useParams();
    const ref=useRef<ActionType>();

   

    useEffect(()=>{
      ref.current?.reload();
  },[id2])

    return (
      <ProTable<TableListItem>
        
        columns={columns}
        actionRef={ref}
        request={async()=>{
            const exception= (await tradeProxyAPI.getExceptionbyId(id));
            const newException= exception.exceptions;
            const exceptions: any[] = []
            const nameOfException :any = new Set();
            Object.keys(newException).map((type) => {
              const typeOfException = newException[type];
              Object.keys(typeOfException).map((exceptionId: any)=>{
                if(exceptionId === id2){
                  const exceptionObject = typeOfException[exceptionId];
                  Object.keys(exceptionObject).map((key) => {
                    if(!nameOfException.has(key)){
                      nameOfException.add(key)
                    }
                    
                    exceptions.push({
                      id: exceptionId, 
                      key, 
                      ...newException[type][exceptionId][key][0]
                    })
                  })
                }
              })
            });
            const obj={
              total: exceptions.length,
              data: exceptions
            }
            // console.log(obj.data);
        changeException(nameOfException)
          return obj;
        }}
        
        pagination={{
          pageSize: 5,
          showSizeChanger: false,
        }}
        rowKey="key"
        search={false}
        toolBarRender={false}
        />
        );
      };
  
function DrawerDetail(props: IStatementCreateFormProps) {
    const { drawerVisit, setDrawerVisit,id } = props;
    const ref= useRef<ProFormInstance>()
    const [ids,setIds]=useState<any>(props.id)
    const [exception, setException] = useState<any>();

    const onDataChange = (d:any) => {
      setException(d);
    }
    

    useEffect(()=>{
      ref.current?.resetFields();
      setIds(props.id)
    },[id])

    return (
    <>
        <DetailList id2={ids} changeException={(d:any)=> onDataChange(d)}/>
        <FieldForms idForMapping={ids} exceptions={exception}/>
    </>

  )
}

export default DrawerDetail