import { ProFormSelect, ProFormText } from "@ant-design/pro-components";
import request from "axios";
// import { TRANSACTIONS_API_ROOT } from "../../helpers/utils";
import React from "react";
import { Form } from 'antd';
import { BaseOptionType } from "antd/es/select";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux/features/userSlice";
import { dropdownsAPI } from "../../../../api";
export interface IClientFormFieldProps {
  name?: string;
  shouldHaveClientName?: boolean;
  disabledLabel?: boolean;
  required?: boolean;
  onChange?: (value: string, options: BaseOptionType) => void;
}

export interface IClient {
  client_id: string;
  name: string;
}


const ClientFormSelect = (props: IClientFormFieldProps) => {
  const {shouldHaveClientName, required, disabledLabel, onChange, name} = props;
  const form = Form.useFormInstance();
  const changeClientName = (value: string, options: BaseOptionType) => {
    if(shouldHaveClientName){
      form?.setFieldsValue({
        client_name: options.label,
      })
    }
    onChange?.(value, options);
  }
  const ethanToken= useSelector(selectUser);


  return (<>
    <ProFormSelect
      name={name? name : "client"}
      label={disabledLabel ? null : "Client"}
      showSearch
      debounceTime={300}
      request={async () => {
        const response = await dropdownsAPI.getClient({
          token: ethanToken,
        });
        return response.data.map((client: IClient) => ({
          value: client.client_id,
          label: client.name,
        }))
      }}
      fieldProps={{
        onChange: changeClientName,
      }}
      placeholder="Select a client"
      rules={[{ required, message: 'Please select the client' }]}
    />
    {shouldHaveClientName && <ProFormText name="client_name" label="Client Name" hidden />}
  </>)
}

export default ClientFormSelect;