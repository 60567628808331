import { PageContainer } from "@ant-design/pro-components";
import ClientMonthly from "./components/ClientMontly";

const ClientMonthlyPage = () => (<PageContainer
    style={{
      marginTop: 80,
    }}
    fixedHeader
    header={{
      title: 'Client Monthly',
    }}
  >
    <ClientMonthly />
  </PageContainer>
  )
  
  
  export default ClientMonthlyPage;