/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
import {
    DrawerForm,
    ProFormDatePicker,
    ProFormDependency,
    ProFormGroup,
    ProFormText,
    ProFormUploadDragger,
  } from '@ant-design/pro-components';
  
  import {
    Form, message, Upload
  } from 'antd';
  import moment from 'moment';
  import ClientIdFormSelect from '../FormFields/ClientIdFormField';
  import ClientReportType from '../FormFields/ClientReportType'; 
  import request from 'axios';
  // import { TRANSACTIONS_API_ROOT } from '../../Refinary/helpers/utils';
  import React from 'react';
  import { removePropertiesFromObject } from '../common/removeProperty';
  import { useSelector } from 'react-redux';
  import { selectUser } from '../../../redux/features/userSlice';
  import {processingStatusAPI,deleteFileAPI,preSignAPI} from '../../../api'
  
  interface IStatementCreateFormProps {
    drawerVisit: boolean;
    setDrawerVisit?: (value: boolean) => void;
  }
  
  interface IPresignedPostResponseData {
    url: string;
    fields: {
      key: string;
      AWSAccessKeyId: string;
      policy: string;
      signature: string;
    }
  }
  
  export interface UploadProgressEvent extends Partial<ProgressEvent> {
    percent?: number;
  }
  export declare type UploadRequestMethod = 'POST' | 'PUT' | 'PATCH' | 'post' | 'put' | 'patch';
  export interface UploadRequestError extends Error {
    status?: number;
    method?: UploadRequestMethod;
    url?: string;
  }
  
  interface IUploadFileToS3 {
    file: File;
    onProgress?: (event: UploadProgressEvent) => void;
    onError?: (event: UploadRequestError | ProgressEvent) => void;
    onSuccess?: (body: string, xhr?: XMLHttpRequest) => void;
  }
  
  
  
  export default  (props: IStatementCreateFormProps) => {
    const { drawerVisit, setDrawerVisit } = props;
    const [signedUploadResponse, setSignedUploadResponse] = React.useState<IPresignedPostResponseData>();
    const [fileKey, setFileKey] = React.useState<string>();
    const [form] = Form.useForm();
    const uploadFileToS3 = (presignedPostData: IPresignedPostResponseData | null) => 
      async (fileUpload: IUploadFileToS3): Promise<boolean> => {
        const { file, onSuccess, onError, onProgress } = fileUpload;
        if(!presignedPostData){
          return false;
        }
        const formData = new FormData();
        // Map all the fields
        (Object.keys(presignedPostData.fields) as (keyof typeof presignedPostData.fields)[]).forEach((key) => {
          const value = presignedPostData?.fields[key];
          formData.append(key as string, value as string);
        });
        formData.append('file', file);
        const response = await request.post(presignedPostData.url,formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          // onUploadProgress: (event) => {
          //   //
          //   if (event.lengthComputable) {
          //     onProgress?.({
          //       percent: (event.loaded / event.total) * 100,
          //     });
          //   }
          // }
        });
        if(response.status === 204) {
          onSuccess?.("Ok");
          form.setFieldsValue({
            download_url: presignedPostData.fields.key,
          })
          return true;
        }
        const error = new Error("Error uploading the file");
        onError?.(error);
        return false;
      };
      const ethanToken = useSelector(selectUser);
  
    const formatFileType = (clientName: string, fileType: string) => {
      switch(fileType){
        case 'application/pdf':
          return `${clientName}.pdf`;
        case 'application/vnd.ms-powerpoint':
          return `${clientName}.ppt`;
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          return `${clientName}.pptx`;
        default:
          return;  
      }
    }
    return (
      <DrawerForm<{
        name: string;
        company: string;
      }>
        onOpenChange={setDrawerVisit}
        title="Upload Statement"
        form={form}
        open={drawerVisit}
        initialValues={{
          month: moment().subtract(1, 'months').endOf('month'),
          update_date: moment().subtract(1, 'months').endOf('month').toISOString(),
        }}
        autoFocusFirstInput
        drawerProps={{
          destroyOnClose: true,
          onClose: async() => {
            if(fileKey){
              await deleteFileAPI.deletFile({
                file_key:fileKey,
                token:ethanToken
              })
            }
          }
        }}
        submitTimeout={2000}
        onFinish={async (values) => {
          const formData = removePropertiesFromObject(values, ["client_name", "month"]);
          try{
            const {data, status} = await processingStatusAPI.postStatements({
              data:formData,
              token:ethanToken
            })
            if (status !== 201){
              message.error(data.error);
              return false;
            }
            message.success('Success');
            return true;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          }catch(error: any){
            message.error(error?.response?.data?.error || "Unexpected Error");
            return false;
          }
        }}
      >
        <ProFormGroup>
          <ClientIdFormSelect shouldHaveClientName />
          <ProFormDependency name={['client_id']}>
            {({ client_id }) => <ClientReportType dependencies={['client_id']} shouldHaveCustodianName  clientId={client_id} disable={!client_id} />}
          </ProFormDependency>
        </ProFormGroup>
        <ProFormGroup 
          style={{
            margin: '32px 0px',
          }}
        >
          <ProFormDependency name={['client_id','statement_type']}>
            {({ client_id,statement_type}) => (
              <>
                <ProFormDatePicker.Month
                  name="processing_month"
                  disabled={!client_id || !statement_type}
                  label="Processing Month"
                  fieldProps={{
                    format: "YYYY-MM-DD",
                    disabledDate: (current) => current && current > moment().endOf("day").subtract(1, "month"),
                    onChange: (value) => {
                      form.setFieldsValue({
                        update_date: value?.toISOString(),
                      });
                    },
                  }}
                  rules={[{ required: true, message: 'Please select the month' }]}
                  placeholder="Select Month"
                />
                <ProFormText name="update_date" hidden />
              </>
            )}
          </ProFormDependency>
        </ProFormGroup>
  
        <ProFormDependency name={['client_name','statement_type','processing_month']}>
          {({client_name: clientName,processing_month,statement_type }) => (
            <>
              <ProFormUploadDragger   
                required
                disabled={!clientName || !statement_type || !processing_month}
                name="upload"
                style={{width:"100%"}} max={4} 
                label="Upload"
                title="Upload Statement"
                description={(
                  <>
                    Support for a single.
                    <br/>
                    Client and statement type information must be provided
                  </>
                )}
                fieldProps={{
                  multiple: false,
                  customRequest: async ({file, onProgress, onSuccess, onError}) => {
                    uploadFileToS3(signedUploadResponse || null)({
                      file: file as File,
                      onProgress,
                      onSuccess,
                      onError,
                    })
                  },
                  beforeUpload: async (file) => {
                    const isFile = ['application/pdf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'].includes(file.type);
                    if (!isFile) {
                      message.error(`${file.name} is not a pdf or a pptx`);
                      return;
                    }
                    const fileDataResponse = {
                      clientName: clientName,
                      month: processing_month
                    }

                    const response: { data: IPresignedPostResponseData } = await preSignAPI.statementPreSign({
                      data: fileDataResponse,
                      token: ethanToken
                    });
                    setSignedUploadResponse(response.data);
                    setFileKey(formatFileType(response?.data?.fields?.key,file.type));
                    return isFile || Upload.LIST_IGNORE;
                  },
                  data: () => {
                    return fileKey ? { key: fileKey } : {}
                  },
                }}
              />
              <ProFormText name="download_url" hidden />
            </>
          )}
        </ProFormDependency>
      </DrawerForm>
    );
  };