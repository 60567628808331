import {  createSlice } from '@reduxjs/toolkit';
  import type { PayloadAction } from '@reduxjs/toolkit'
  import { createSelector } from '@reduxjs/toolkit';
  export interface CounterState {
    user: any
  }
  const initialState: CounterState = {
    user: null,
  }
export const userSlice  = createSlice({
  name: 'user',
  initialState,
  reducers: {
    client: (state , action:PayloadAction<number> ) => {
      state.user = action.payload;
    },
  },
});

export const { client } = userSlice.actions;

 const User = (state: any) => state.user;

 export const selectUser = createSelector([User],(data)=> data.user);
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default userSlice.reducer;