import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { Popconfirm } from "antd"
import { blotterDataAPI } from "../../api";
import { selectUser } from "../../redux/features/userSlice";
import { useSelector } from "react-redux";
import { ActionType } from "@ant-design/pro-components";

interface IDeleteBlotterRowProps {
    record: any;
    actionRef: React.MutableRefObject<ActionType | undefined>;
}

const DeleteBlotterRow = (props:IDeleteBlotterRowProps) => {
    const ethanToken= useSelector(selectUser);
    const {record,actionRef} = props;

    const deleteRow = async() => {
        await blotterDataAPI.deleteBlotterRow(record?.trade_id,ethanToken);
        actionRef.current?.reload();
    }
    return(
        <Popconfirm
        title="Are you sure ? "
        onConfirm={deleteRow}
        okText="Yes"
        cancelText="No"
        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
  >
   <DeleteOutlined style={{marginLeft:"1em", color:"red"}} />
  </Popconfirm>
    )
}

export default DeleteBlotterRow;