import { ProFormSelect } from "@ant-design/pro-components";
// import { TRANSACTIONS_API_ROOT } from "../../helpers/utils";
import request from "axios";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/features/userSlice";
import { dropdownsAPI } from "../../api";

interface ICustodianFormFieldProps {
  clientId?: string;
  custodianId?: string;
  disable?: boolean;
  required?: boolean;
  dependencies?: string[];
}


const CustodianAccFormSelect = (props: ICustodianFormFieldProps) => {
  const { disable, clientId, required, custodianId, dependencies } = props;
  const ethanToken = useSelector(selectUser);

  return (<ProFormSelect
    name="custodian_account"
    label="Custodian Account"
    disabled={disable}
    showSearch
    dependencies={dependencies}
    debounceTime={300}
    request={async () => {
      const response = await dropdownsAPI.getCustodianAccount({
        client_id: clientId,
        custodian_id: custodianId,
        token: ethanToken,
      })
      return response.data?.data?.map((account:any) => ({
        value: account,
        label: account
      }))
    }}
    placeholder="Select Custodian Account"
    rules={[{ required, message: 'Please select the custodian' }]}
  />)

}

export default CustodianAccFormSelect;