import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm } from 'antd';
import { SortOrder } from 'antd/es/table/interface';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { positionProxyAPI, statementAPI } from '../../api';
import { selectUser } from '../../redux/features/userSlice';
import {ProcessModel} from "../Refinary/Components/Statement/StatementTable"

type StatementTableData = {
  _key?: number;
  table_names?: string[];
  table_length?: number;
  status_message?: string;
  id: number;
  statement_id: number;
  client_id: number;
  report_date: Date;
  upload_status: string;
  processing_status: string;
  relationship_no: string;
  client_name: string;
  custodian_name: string;
  custodian_id: string;
  portfolio_no: string;
  file_name: string;
  uploaded_at: Date;
  currency: string;
} 

function RefineData() {
  const {key}=useParams()
  const [data,setData]=useState<StatementTableData>();

  const request= async () => {
    // const params = {}
    // const sortObject : Record<string, SortOrder>= {}
    // const ordering = Object.keys(sortObject).map((key) => {
    //   const value = sortObject[key];
    //   return `${value === "ascend" ? "" : "-"}${key}`;
    // })?.join();

    // const { ...customParams } = params;
    const response = await statementAPI.getDetails(key)
    // const obj = {
    //   total: response.data.count,
    //   data: response.data.results,
    // }
    // setIsLoading(false);
    setData(await response)
    // console.log(data)
    return await data
  }
  const retryExtract = async () => {
    if(key){
      await statementAPI.retryExtract(key);
    } else {
      message.error("Key does not exist");
    }
  };
  useEffect(()=>{
    request()
  },[])



  return (
      <>
    <ProcessModel statement={data}/>
    <Popconfirm
    title={<>
    This action is not reversible<p>Are you sure ?</p>
    </>
    }
    onConfirm={retryExtract}
    okText="Yes"
    cancelText="No"
    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
  >
    {/* <DeleteOutlined style={{marginLeft:"1em", color:"red"}} /> */}
    <Button danger style={{color:"red"}} >RETRY PROCESS</Button>
   </Popconfirm>
</>
  )
}

export default RefineData

