/* eslint-disable array-callback-return */
import { PageContainer,ProCard} from "@ant-design/pro-components";
import {message} from 'antd';
import {useEffect,useState} from 'react';
import Board from "react-trello";
import { lanes } from "./utils/lanes.utils";
import { positionProxyAPI} from "../../api";
import CustomCard from "../TradesValidation/components/CustomCard";

const data = {
  lanes
  }

type ArrayType={
    file: any,
    status_message: string,
    status: string,
    fx_rate: any,
    custodian_name: string,
    custodian_id: string,
    client_name: null,
    client_id: string,
    mapping: any | null,
    data: any | null,
    report_date: null,
    processing_error: null,
    statement: any,
    statement_id: any,
    _key: string
}




const PositionsValidationPage = () => {
    const [showBoard,setShowBoard]=useState<boolean>(true);
    const [tradesData,setTradesData] = useState<ArrayType[]>();

    const components = {
      Card: CustomCard
    }

    const maaping= (tradeData: ArrayType[])=>{
      data.lanes.map((data)=> data.cards=[]);
      data.lanes.map((data)=>{
         tradeData.map((position)=>{
          if(data.id === 'START'){
            data.cards.push({id: `${position._key}`, title: `${position.client_id}`, label: `${position.custodian_name}`, description:`${position.custodian_name}` , data:position.data} );
          }
        })
      })
    }
    const fetchproxyData=async()=>{
     try {
      const response = positionProxyAPI.getProxyData();
      return (await response);
     } catch (error) {
     }
    }


    
    useEffect(()=>{
      fetchproxyData().then((res)=>{
        setTradesData(res)
        return maaping(res)
      }).then(()=>setShowBoard(true));
    },[])

 

return (<PageContainer
    style={{
      marginTop: 60,
    }}
    fixedHeader
    header={{
      title: 'Positions Validation',
    }}
  >
    {showBoard &&
    <ProCard
    style={{
        marginTop: '2em'
    }}
    title="Board"
    bordered
    >
        <Board
        draggable 
        data={data}
        hideCardDeleteIcon={ true}
        // handleDragEnd={handleDragEnd}
        components={components}
        style={
            {
                backgroundColor: 'transparent',
            }
        }/>
    </ProCard>
    }
  </PageContainer>)
}
  
  
  export default PositionsValidationPage;