/* eslint-disable react-hooks/rules-of-hooks */
import { PlusOutlined } from '@ant-design/icons';
import { ProColumns , ActionType } from '@ant-design/pro-components';
import React, { useRef,useEffect} from 'react';
import { Button} from 'antd';
import Table from '../../Refinary/common/Table';
// import { TRANSACTIONS_API_ROOT } from '../../Refinary/helpers/utils';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/features/userSlice';
import ClientFormSelect from '../../Refinary/Components/FormFields/ClientFormField';
import { SortOrder } from 'antd/es/table/interface';
import { OPTION_LABEL } from '../FormFields/ClientReportType';
import moment from 'moment';
import DownloadStatement from './DownloadStatement';
import { processingStatusAPI } from '../../../api';


export type ClientMonthlyTableData = {
    id: number;
    update_date: string;
    client_id: number;
    client_name: string;
    processing_month: string;
    type_of_process: string;
    num_processed: number;
    num_expected: number;
    process_stage: string;
    processing_status: string;
    download_url: string;
    reason: string;
    status_update_date: string;
    statement_type: string;
  }
  
  const columns: ProColumns<ClientMonthlyTableData>[] = [
    {
      dataIndex: 'client_name',
      title: "Client Name",
      width: 100,
      fixed: 'left',
      key: 'client_name',
      renderFormItem: (item, {onChange}) => <ClientFormSelect onChange={onChange} disabledLabel/>,
    },
    {
      dataIndex: 'statement_type',
      renderText: () => OPTION_LABEL.LABEL,
      title: "Statement Type",
      key: 'statement_type',
      width: 150,
      search: false,
    },
    {
      dataIndex: 'processing_month',
      renderText: (text) => moment(text).format("MMMM") === "Invalid date" ? text : moment(text).format("MMMM"),
      title: "Month",
      key: 'processing_month',
      width: 150,
      search: false,
    },
    {
      dataIndex: 'download_url',
      title: "Download",
      key: 'download_url',
      search: false,
      width: 150,
      render: (value) => <DownloadStatement value={value}/>
    }
  ];
  interface StatementTableProps {
    openStatementCreateForm: () => void;
  }
  const ClientMonthlyTable = (props: StatementTableProps) => {
    const {  openStatementCreateForm } = props;

    const pageSize = 10;
    const actionRef = useRef<ActionType>();
    const ethanToken = useSelector(selectUser);
    useEffect(()=>{
      actionRef.current?.reload();
    },[ethanToken])
    return (
      <>
        <Table<ClientMonthlyTableData>
          columns={columns}
          actionRef={actionRef}
          cardBordered
          eslint-disable-next-line default-param-last
          request={async (params = {}, sortObject: Record<string, SortOrder>) => {
            const ordering = Object.keys(sortObject).map((key) => {
              const value = sortObject[key];
              return `${value === "ascend" ? "" : "-"}${key}`;
            })?.join();
  
            
            const { pageSize: pageSizeLimit, current, ...customParams} = params;
            const response = await processingStatusAPI.getProcessingStatus({
              paging: {
                limit: pageSizeLimit,
                offset: (current as number - 1) * (pageSizeLimit as number),
                ordering,
              },
              token: ethanToken,
              customParams
            });
            const obj = {
              total: response.data.count,
              data: response.data.results,
            }
            return obj;
          }}

          editable={{
            type: 'multiple',
          }}
          columnsState={{
            persistenceKey: 'statement-table',
            persistenceType: 'localStorage',
          }}
          rowKey="id"
          options={{
            setting: {
              listsHeight: 400,
            },
          }}
          form={{
            syncToUrl: (values, type) => {
              if (type === 'get') {
                return {
                  ...values
                };
              }
              return values;
            },
          }}
          pagination={{
            pageSize,
          }}
          dateFormatter="string"
          search={{
            filterType: 'light',
            collapsed: false,
            collapseRender: false,
          }}
          scroll={{ x: 1200 }}
         
          toolBarRender={() => [
            <Button onClick={() => {
              openStatementCreateForm()
            }} key="button" icon={<PlusOutlined />} type="primary">
              Upload Statement
            </Button>
          ]}
        />
      </>
    );
  
  }
  
  export default ClientMonthlyTable;