// Remove property from object

export const removePropertiesFromObject = (obj: object, props: string[]): object => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newObj: any = { ...obj };
    Object.keys(newObj).forEach((key) => {
      if (props.includes(key)) {
        delete newObj[key];
      }
    });
    return newObj;
  }

export const addPropertyToObject = (obj: object): object => {
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newObj: any = {...obj};
  newObj['name'] = `${newObj.custodian_name}-${newObj.client_name}`
  return newObj
}  