export const lanes= [
    {
        id: "START",
        title: "START",
        label: "20/70",
        style: {
          "width": 280
        },
        cards: []
    },
    {
      id: "STAGE_1",
      title: "STAGE 1",
      label: "20/70",
      style: {
        "width": 280
      },
      cards: []
  },
  {
    id: "STAGE_2",
    title: "STAGE 2",
    label: "20/70",
    style: {
      "width": 280
    },
    cards: []
},
    {
        id: "DONE",
        title: "DONE",
        label: "10/20",
        style: {
          "width": 280
        },
    
        "cards": [
          {
            "id": "Wip1",
            "title": "Clean House",
            "label": "30 mins",
            "description": "Soap wash and polish floor. Polish windows and doors. Scrap all broken glasses" ,
            "data":[]
          }
        ]
    },
]

