import { EditOutlined } from '@ant-design/icons';
import {
  ModalForm,
  ProForm,
  ProCard,
  ProFormText,
} from '@ant-design/pro-components';
import { Button, Form, message } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { statementAPI} from '../../../api';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import { rotatePlugin } from '@react-pdf-viewer/rotate';

const StatementDrawer = (props: any) => {

  const rotatePluginInstance = rotatePlugin();
  const { RotateBackwardButton, RotateForwardButton } = rotatePluginInstance;
    const [form] = Form.useForm<any>();
    const [url,setUrl] = useState<any>('');
    const [hasPdf,setHasPdf] = useState<any>(false);
    const {key} = useParams();


    const getStatement = async() => {
      const {file} = await statementAPI.getDetails(key);
      const data = {
        file_path: file.path
      }
      // console.log(data);
      // console.log("file",file);
      const {url} = await statementAPI.getPdf(data,key);
      if(url){
        // const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
        // const pdfDoc = await PDFDocument.load(existingPdfBytes)
        // const pdfDoc = await PDFDocument.load(url)
        // const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
        // const pages = pdfDoc.getPages()
        // const firstPage = pages[0]
        // const { width, height } = firstPage.getSize();
        // firstPage.translateContent(0,height)
        // const pdfBytes = await pdfDoc.save()
        setUrl(url);
        setHasPdf(true);
      }
    }

    useEffect(() =>{
      getStatement();
    }, [])


    return (
        <ModalForm<{
          name: string;
          company: string;
          _id: string
        }>
          width='90%'
          style={{
            height:'100%',
          }}
          title="Edit Refinery Data"
          trigger={
              <Button danger style={{
                color:'green',
                border:'1px solid green'
              }}>View Statement</Button>
          }
          form={form}
          autoFocusFirstInput
          modalProps={{
            onCancel: () => console.log('run'),
          }}
          submitTimeout={2000}
          onFinish={async (values) => {
            await statementAPI.modify(values?._id,`${key}`,values)
            await props?.setRefresh()
            return true;
          }}
        >
          <ProCard title = "Statement View" >
            {
              hasPdf && <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
              <div
            className="rpv-core__viewer"
            style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '4px',
                }}
            >
                <div style={{ padding: '0px 2px' }}>
                    <RotateBackwardButton />
                </div>
                <div style={{ padding: '0px 2px' }}>
                    <RotateForwardButton />
                </div>
            </div>
            <div
                style={{
                    flex: 1,
                    overflow: 'hidden',
                }}
            >

                <Viewer fileUrl={url} plugins={[rotatePluginInstance]}/>
            </div>
        </div>
            </Worker>
            }
          </ProCard>
        </ModalForm>
    );
}
export default StatementDrawer;