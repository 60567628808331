import ClientMonthlyTable from "./ClientMonthlyTable";
import UploadCreateForm from "./UploadCreateForm";
import { useState } from "react";

const ClientMonthly = () => {
  const [drawerVisit, setDrawerVisit] = useState<boolean>(false);
  const openStatementCreateForm = () => {
    setDrawerVisit(true);
  }
   return (
    <>
      <ClientMonthlyTable openStatementCreateForm={() => { openStatementCreateForm() }} />
      <UploadCreateForm drawerVisit={drawerVisit} setDrawerVisit={setDrawerVisit}/>
    </>
    
   )
};
  export default ClientMonthly;