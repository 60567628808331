
export const defaultMenus = [
    {
      path: '/statements',
      name: 'Refinery',
      icon: 'smile',
    },
    {
      path: '/client-monthly',
      name: "Upload Client Monthly",
    },
    {
      path: '/blotter',
      name: "Blotter",
    },
    {
      path:'/parsing-mapping',
      name:'Parsing Mapping'
      //Master Data of all tickers
      // path: '/ticker',
      // name: "Ticker ",
    //  routes: [{
    //   path:"/ticker-mapping",
    //   name:"Ticker-Mapping"
    //  },
    //   {
    //     path:"/price-mapping",
    //     name:"Price-Mapping"
     
    //  }]
    },
    {
      path: '/security-mapping',
      name:'Security Master'
    }
];