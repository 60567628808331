import React from 'react'

function TradeParsing() {
  return (
    <div><h1>
        Trade Parsing
        </h1>
        </div>
  )
}

export default TradeParsing