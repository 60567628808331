import * as React from "react";
import { ReactGrid, Column, Row, CellChange, DefaultCellTypes } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import { Button, Row as AntRow, Col, Space } from "antd";
import { ProCard } from "@ant-design/pro-components";
import { useSelector } from "react-redux";
import { TableDataSelector } from "../../../redux/features/Tables/tableDataSlice";

const getRows = (rowList: any[], columns: Column[] ): Row[] => {
  const cells: DefaultCellTypes[] = columns.map((col: Column) => ({
    type: "text",
    text: col.columnId as string,
  }))
  return [
    {
      rowId: "header",
      cells,
    },
    ...rowList.map<Row>((row, idx) => ({
      rowId: idx,
      cells: columns.map((col: Column) => ({
        type: "text",
        text: String(row?.[col?.columnId] || ""),
        style: {
          backgroundColor: (row?.exception?.[col?.columnId]) ? "#ff7875" : "white",
        }
      })) as DefaultCellTypes[],
    }))
  ]
};


const applyChangesToPosition = (
  changes: CellChange<any>[],
  prevPosition: any
): any[] => {
  let tempPosition: any = [...prevPosition];
  changes.forEach((change) => {
    const positionIndex = change.rowId;
    const fieldName = change.columnId;
    tempPosition[positionIndex][fieldName] = change.newCell.text;
  });
  return tempPosition;
};

interface PositionGridTableProps {
  columns: Column[];
  dataSource: any[];
  saveData: (data: any) => void;
}


export const PositionGridTable = (props: PositionGridTableProps) => {
  const [positionList, setPositionList] = React.useState<any[]>(props.dataSource);
  const [rows, setRows] = React.useState<Row[]>(getRows(positionList, props.columns));
  const [columns, setColumns] = React.useState<Column[]>(props.columns);
  const gridReactRef = React.useRef<ReactGrid>(null);

  React.useEffect(() => {
    setRows(getRows(positionList, props.columns));
    setColumns(props.columns);
  }, [positionList, props.columns])

  const handleChanges = (changes: CellChange<any>[]) => {
    setPositionList((prevPosition) => applyChangesToPosition(changes, prevPosition));
  };
  const tableData = useSelector(TableDataSelector)

  React.useEffect(() => {
    gridReactRef?.current?.forceUpdate();
  }, [tableData])
  return (
    <ProCard
      title="Positions Table View"
      extra={
        <Space>
          <Button
            type="primary"
            onClick={() => props.saveData(positionList)}
          >
            Save
          </Button>
        </Space>
      }
    >
    <AntRow>
      <Col span={24}>
    <ReactGrid
      rows={rows}
      
      columns={columns}
      onCellsChanged={handleChanges}
      stickyTopRows={1}
      enableFullWidthHeader={true}
      ref={gridReactRef}

    />
    </Col>
    </AntRow>
    </ProCard>
  );
}