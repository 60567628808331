
import { useCallback, useEffect, useState } from "react";
import { DndContext, DragEndEvent, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";
import {  AxiosError } from 'axios'

import * as _ from "radash";
import { Column, DraggableElement, IElement } from "./components";
import styled from "styled-components";
import { IColumnConfig } from "./components/Column";
import { ProCard } from "@ant-design/pro-components";
import { Button, message } from "antd";
import { EditOutlined, ReloadOutlined, SettingOutlined } from "@ant-design/icons";
import { positionProxyAPI, statementAPI, validationAPI } from "../../api";
import { useNavigate } from "react-router-dom";


const COLUMN_OBJECT: { [key: string]: IColumnConfig } = {
  "START": {
    name: "Start",
    color: "#F94892",
  },
  "PRE_PROCESSING": {
    name: "Pre-Processing",
    color: "#5800FF",
  },
  "CLEANING": {
    name: "Cleaning",
    color: "#5800FF",
  },
  "VALIDATION": {
    name: "Validation",
    color: "#ffb300",
  },
  "DONE": {
    name: "Done",
    color: "#24A19C",
  },
  "FAIL": {
    name: "Fail",
    color: "#FF0000",
  },
}

export const DEFAULT_COLUMN = "START";

export const EthanBoard = () => {
  const [data, setData] = useState<IElement[]>([]);

  const fetchPositionProxyData = async () => {
    const responseList = await positionProxyAPI.getProxyData();
    const dataList = responseList.map((payload: any) => {
      return {
        id: payload._key,
        content: payload._key + " - " + payload.custodian_name + " " + payload.client_name,
        column: DEFAULT_COLUMN,
        data: payload
      }
    })
    setData(dataList);
  }

  useEffect(() => {
    fetchPositionProxyData();
  }, []);


  const triggerAction = async (element: IElement, column: string) => {
    const payload = element.data;
    // console.log("triggerAction: payload",payload)
    switch (column) {
      case "PRE_PROCESSING":
      
      positionProxyAPI.postPreprocess(payload._key, {}).catch((reason: AxiosError)=>{
          if (reason.response!.status !== 200) {
            // Handle 400
            message.warning("Failed Please try Again")
          }
      });
        break;
      case "CLEANING":
        positionProxyAPI.postCleaning(payload._key, {}).catch((reason: AxiosError)=>{
          if (reason.response!.status !== 200) {
            // Handle 400
            message.warning("Failed Please try Again")
          }
      });;
        break;
      case "VALIDATION":
        validationAPI.positionValidation({
          id: payload._key,
          stage: "STAGE_1"
        }).catch((reason: AxiosError)=>{
          if (reason) {
            message.error("Failed Please try Again")
          }
      });;
        break;
      default:
        break;
    }
  }


  const handleOnDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      const elementId = active.id;
      const deepCopy = [...data];

      const updatedState = deepCopy.map((elm): IElement => {
        if (elm.id === elementId) {
          const column = over?.id ? String(over.id) : elm.column;
          triggerAction(elm, column);
          return { ...elm, column };
        }
        return elm;
      });

      setData(updatedState);
    },
    [data, setData]
  );

  const navigate= useNavigate();
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: {
      delay: 100,
      tolerance: 10,
    }})
  );

  
  return (
    <DndContext onDragEnd={handleOnDragEnd}
      sensors={sensors} 
    >
      <MainWrapper>
        {Object.keys(COLUMN_OBJECT).map((columnKey) => (
          <Column
            key={`column-${columnKey}`}
            heading={COLUMN_OBJECT[columnKey].name}
            columnConfig={COLUMN_OBJECT}
            cardRender={(element) => {
              return (
                <ProCard
                  title={element.content}
                  bordered layout="center"
                  actions={[
                    <ReloadOutlined key="Retry" />,
                    <EditOutlined key="Details" onClick={() => {
                      navigate(`/${element.data._key}/details`)

                    }} />,
                  ]}>
                </ProCard>
              )
            }}
            elements={_.select(
              data,
              (elm) => elm,
              (f) => f.column === columnKey
            )}
          />
        ))}
      </MainWrapper>
    </DndContext>
  );
};

const MainWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  background-color: #fff;
  height: 90vh;
`;