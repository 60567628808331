import {  createSlice } from '@reduxjs/toolkit';
  import type { PayloadAction } from '@reduxjs/toolkit'
  import { createSelector } from '@reduxjs/toolkit';
  export interface CounterState {
    tab: any
  }
  const initialState: CounterState = {
    tab: null,
  }
export const TabSlice  = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    tab: (state , action:PayloadAction<any> ) => {
      state.tab = action.payload;
    },
  },
});

export const { tab } = TabSlice.actions;

 const Tab = (state: any) => state.tab;

 export const selectTab = createSelector([Tab],(data)=> data);

export default TabSlice.reducer;