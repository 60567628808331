
import React from "react";
import StatementTable from "./StatementTable";
import StatementCreateForm from "./StatementCreateForm";

const Statement = () => {
  const [drawerVisit, setDrawerVisit] = React.useState<boolean>(false);
  const openStatementCreateForm = () => {
    setDrawerVisit(true);
  }
  return (
    <>
      <StatementTable
          openStatementCreateForm={() => { openStatementCreateForm() }}
      />
      <StatementCreateForm
          drawerVisit={drawerVisit}
          setDrawerVisit={setDrawerVisit}
      />
    </>
  );
};
export default Statement;