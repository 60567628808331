  import { ActionType, ProColumns, ProTable, EditableProTable, ProCard } from "@ant-design/pro-components";
import { Button, Descriptions, message } from "antd";
import { useEffect, useRef, useState } from "react";
import DataModal from "./DataModal";
import DeleteRow from "./DeleteRow";
import CreateRefineryDataForm from "./CreateRefineryDataForm";
import RefineryConfiguration from "../RefineryConfiguration/RefineryConfiguration";
import Typography from "antd/es/typography/Typography";
import { tableMappingAPI } from "../../../api";
import { useParams } from "react-router-dom";


  interface DataProps {
    data?: any ,
    tableName: string,
    configuration?: any,
    outputTable?: any,
    setRefresh:(data:any)=>void,
  }

  const DataListTable = (props: DataProps) => {
    const {outputTable} = props;
    const [deleted, setDeleted] = useState<Boolean>(false);
    const columns: ProColumns<Record<string, any>, "text">[] | { title: string; dataIndex: string; key: string; }[] | undefined =  [];
    const ref= useRef<ActionType>()
    const {key} = useParams();
    // const [refresh,setRefresh]=useState<Boolean>(false);
    const arrayOfKeysInAllRows:any[] = []
    let uniqueColumns:any[] = []
    let uniqueOutputColumns:any[] = []
    // // Creating columns
    props.data?.Data?.map((rows:any) => {
      const arrayOfKeysInRows = Object.keys(rows);
      arrayOfKeysInAllRows.push(...arrayOfKeysInRows);
      uniqueColumns = Array.from(new Set(arrayOfKeysInAllRows));
    })
  // Adding columns
  uniqueColumns.map((key:any) => {
    if(key.startsWith("_") === false){
      columns.push({
        title: key,
        dataIndex: key,
        key: key,
        // readonly: true,
      })
    }
  })
  columns.push({
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    fixed: 'right',
    width: 100,
    render: (text, record) => {

      return (
      <>
      {
        record?._id && 
        <>
      <DataModal record={record} columns={uniqueColumns} setRefresh={props.setRefresh} />
      <DeleteRow record={record} setDeleted={setDeleted} setRefresh={props.setRefresh}/>
        </>
      }
      </>
      )
    }
  }  
  )

  const outputTableColumns: ProColumns<Record<string, any>, "text">[] = [];
  props.outputTable?.map((rows:any) => {
    const arrayOfKeysInAllRows:any[] = []
    const arrayOfKeysInRows = Object.keys(rows);
    arrayOfKeysInAllRows.push(...arrayOfKeysInRows)
    uniqueOutputColumns = Array.from(new Set(arrayOfKeysInAllRows));
    console.log("uniqueOutputColumns",uniqueOutputColumns);
  })
  uniqueOutputColumns.map((key:any) => {
      outputTableColumns.push({
        title: key,
        dataIndex: key,
        key: key,
        // readonly: true,
      })
  })



  const tableConfiguration =  props?.configuration?.filter((item:any) => {
    return (item?.source === props?.tableName)
  })

  const pushDataToTradesStatement = async () => {
    try{
      await tableMappingAPI.pushDataToTradesStatement(key as string, props?.tableName);
      message.success("Data pushed successfully");
    }
    catch(err){
      message.error("Error while pushing data");
    }

  }

  return (
    <>
      <ProCard title="Refinery Table" bordered>
        <ProTable<any>
          scroll={{y: 500,x: 2000}}
          columns={columns}
          dataSource={props.data.Data}
          rowKey="id"
          search={false}
          pagination={false}
          options={false}
          actionRef={ref}
          toolBarRender={() => [
            <CreateRefineryDataForm
              columns={uniqueColumns}
              tableName={props?.tableName as string}
              data={props.data.Data}
            />,
            <RefineryConfiguration
              columns={uniqueColumns}
              tableName={props?.tableName as string}
              tableConfiguration={tableConfiguration}
              onSave={props.setRefresh}
            />,
          ]}
        />
      </ProCard>
      <ProCard 
        title="Output Table" 
        bordered
        style={{marginTop: 20}}
        extra={
          <Button
            type="primary"
            onClick={() => {
              pushDataToTradesStatement();
            }}
            >Push Data</Button>
          }
      >
        <ProTable<any>
          scroll={{y: 500,x: outputTableColumns?.length * 200}}
          columns={outputTableColumns}
          dataSource={outputTable}
          search={false}
          pagination={false}
          options={false}
          actionRef={ref}
        />
      </ProCard>
    </>
  )
  }
  export default DataListTable;