import { PageContainer } from "@ant-design/pro-components";

import Statement from "./Components/Statement/Statement";

const UploadStatement = () => {

return (<PageContainer
  style={{
    marginTop: 20,
  }}
  fixedHeader
  header={{
    title: 'SAIML',
  }}
>
  <Statement />
</PageContainer>
)
}
export default UploadStatement;