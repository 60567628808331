import React from "react";
import { BetaSchemaForm, DrawerForm, ProFormColumnsType } from "@ant-design/pro-components"
import { Button } from "antd"
import { positionProxyAPI } from "../../../api";
import { useParams } from "react-router-dom";

interface CreateRefineryDataFormProps {
  columns: string[],
  tableName: string,
  data: any[]
}

const CreateRefineryDataForm = (props: CreateRefineryDataFormProps) => {
  const {key}=useParams()
  let cols= props.columns.filter((item:any)=> {return  item!="_id"} )
  // console.log(cols)
  const columns: ProFormColumnsType<any>[] = cols.map((column) => {
    return {
      title: column,
      dataIndex: column,
      valueType: 'text',
      width: 'md',
      colProps: {
        xs: 24,
        md: 12,
      },
    }
  });

  return (<DrawerForm
    title={`Create Refinery Data for ${props.tableName}`}
    trigger={
      <Button type="primary" disabled={props.data.length < 1}>
        Create Refinery Data
      </Button>
    }
    onFinish={async()=>{ return true}}
  >
    <BetaSchemaForm<typeof columns>
        columns={columns}
        rowProps={{
          gutter: [16, 0],
        }}
        colProps={{
          span: 6,
        }}
        onFinish={async (values) => {
          positionProxyAPI.addingDatatoRefinary(key,{table_name: props.tableName, ...values})
          // console.log(values);
        }}
      />
  </DrawerForm>)
}
export default CreateRefineryDataForm;