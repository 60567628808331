import { useState } from "react";
import { ProCard } from "@ant-design/pro-components";
import { message } from "antd";
import Button from "antd/es/button";
import { useNavigate } from "react-router-dom";
import {tradeProxyAPI} from "../../../api";

type CustomCardProps = {
    id?: string ;
    title?: string;
    label?: string;
    description?: string;
    data?: any;
}

const CustomCard = (props:CustomCardProps) => {
    const navigate= useNavigate();

    const [hasData,setHasData] = useState<boolean>(true);

    const {id,data} = props;

    // const res = tradesData.find((trade: { _key: string; }) => trade._key === id);
    // if(res.data === null){
    //     setHasData(false);
    // }
    return(
        <ProCard
        headerBordered
        bordered
        title={props.title}
        extra={props.label}
        size="small"
        style={{
            margin: "0.3em 0"
        }}
        >
            <div>{props.description}</div>
            <Button type="primary" 
            size="small" 
            onClick={async() => {
                const submit = {
                    key: id
                }
                try{
                    tradeProxyAPI.regenerate(submit);
                    message.success('successful')
                }catch(err){
                    message.error('please try again')
                }
            }}
            >
                Retry
            </Button>
            {
                data?.length > 0 && 
                <Button type="primary" 
                size="small" 
                style={{marginLeft: "100px"}}
                onClick={async() => navigate(`/${id}/details`) }
                >
                    Details
            </Button>
            }
            {
                data?.Data && data?.Data.length > 0 && 
                <Button type="primary" 
                size="small" 
                style={{marginLeft: "100px"}}
                onClick={async() => navigate(`/positions-validation/${id}/`) }
                >
                    Details
            </Button>
            }
        </ProCard>
    )
}

export default CustomCard;