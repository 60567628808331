import { getDownloadPath } from "../common/getDownloadPath"
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/features/userSlice";
import { Button, Tooltip } from 'antd';


const DownloadStatement = (value: any) => {
    const ethanToken = useSelector(selectUser);
    return(
        <Tooltip placement="top" title={value.value}>
          <Button onClick={async() => getDownloadPath(value.value as string, ethanToken as unknown as string)}>
            Download
          </Button>
        </Tooltip>
    )
}

export default DownloadStatement;