/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useState} from 'react'
import { statementAPI } from '../../../api'
import { useParams } from 'react-router-dom';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';

interface DeleteRowProps {
  record: any;
  setDeleted: any;
  setRefresh: (data?: any) => void;

}

function DeleteRow(props: DeleteRowProps) {
    const {key} = useParams();

    const deleteData=async()=>{
        // console.log(props?.record)
        await statementAPI.delete(props?.record?._id,`${key}`);
        props.setDeleted(true);
        await props?.setRefresh()
        return true
    }
   
  return (
    <Popconfirm
    title="Are you sure ? "
    onConfirm={deleteData}
    okText="Yes"
    cancelText="No"
    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
  >
    {/* <a href="#">Delete</a> */}
    {/* <Button type="primary" style={{width:"100%", marginTop:"0.5em"}}> */}
    {props.record?._id && <DeleteOutlined style={{marginLeft:"1em", color:"red"}} />}
     {/* Delete */}
    {/* </Button> */}
  </Popconfirm>
  )
}

export default DeleteRow