import { Button, message } from 'antd';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { statementAPI } from '../../../../api';

interface ViewProps{
record: any,
text:any,
key:any
}

function ViewStatement(props: ViewProps) {
  const {key,text,record} =props
  // const record._key= key;
    const navigate= useNavigate();
    return (
    <div onClick={()=> navigate(`/${text}/details`)}>
      <Button>
        {text}
      </Button>
    </div>
    
  )
}

export default ViewStatement