import { Children, FC, useMemo } from "react";
import { Draggable } from "../premitives";

interface IDraggableElement {
  identifier: string;
  content: string;
  children: JSX.Element;
}

export const DraggableElement: FC<IDraggableElement> = ({
  identifier,
  children
}) => {
  const itemIdentifier = useMemo(() => identifier, [identifier]);
  return (
    <Draggable id={itemIdentifier}>
      {children}
    </Draggable>
  );
};
