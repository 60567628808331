import { PageContainer } from '@ant-design/pro-components'
import React from 'react'
import ClientStatements from './components/ClientStatements';

function RefinaryExe() {
    return (
    <PageContainer
    style={{
        marginTop: 20
    }}
    

     header={{
        title: `Trades Validation Details`,
      }}
    >
   <ClientStatements/>
    </PageContainer>
  )
}

export default RefinaryExe