/**
 * Exception from procs
 * Here will get a list from the procs as a list of json
 * We display in the frontend as a table with the following columns
 * 
 * 
  class ProcessingExceptions(PerpDatabaseBase):
      __tablename__ = "processing_exceptions"

      id = Column(Integer, primary_key=True, index=True)
      client_id = Column(Integer)
      custodian_id = Column(Integer)
      statement_date = Column(String)
      ticket_ref = Column(String)
      exception_code = Column(String)
      exception_source = Column(String)
      exception_source_module = Column(String)
      exception_description = Column(String)
      source_data = Column(String)
      status = Column(Integer)
      created_at = Column(String)
      created_by = Column(String)
      updated_at = Column(String)
      updated_by = Column(String)
 * Here we are creat
 */

import { PlusOutlined } from "@ant-design/icons";
import { ProTable } from "@ant-design/pro-components";
import { Button } from "antd";
import { useState } from "react";
import PositionDrawer from "./PositionDrawer/PositionDrawer";

export type ProcessingExceptions = {
  id: number;
  client_id: number;
  custodian_id: number;
  statement_date: string;
  ticket_ref: string;
  exception_code: string;
  exception_source: string;
  exception_source_module: string;
  exception_description: string;
  source_data: string;
  status: number;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
};


const columns = [
  {
    title: "Client ID",
    dataIndex: "client_id",
    key: "client_id",
  },
  {
    title: "Custodian ID",
    dataIndex: "custodian_id",
    key: "custodian_id",
  },
  {
    title: "Statement Date",
    dataIndex: "statement_date",
    key: "statement_date",
  },
  {
    title: "Ticket Ref",
    dataIndex: "ticket_ref",
    key: "ticket_ref",
  },
  {
    title: "Exception Code",
    dataIndex: "exception_code",
    key: "exception_code",
  },
  {
    title: "Exception Source",
    dataIndex: "exception_source",
    key: "exception_source",
  },
  {
    title: "Exception Source Module",
    dataIndex: "exception_source_module",
    key: "exception_source_module",
  },
  {
    title: "Exception Description",
    dataIndex: "exception_description",
    key: "exception_description",
  },
  {
    title: "Source Data",
    dataIndex: "source_data",
    key: "source_data",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Created At",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "Created By",
    dataIndex: "created_by",
    key: "created_by",
  },
  {
    title: "Updated At",
    dataIndex: "updated_at",
    key: "updated_at",
  },
  {
    title: "Updated By",
    dataIndex: "updated_by",
    key: "updated_by",
  },
];


interface ProcessingExceptionsProps {
  processingMessages: ProcessingExceptions[];
  payload:any
}



const ProcessingExceptionTable = (props: ProcessingExceptionsProps) => {
  const [drawervisit,setDrawerVisit]= useState<Boolean>(false);

  const onOpenDrawer=()=>{
    setDrawerVisit((prev)=> !prev)
  }

  return (
    <>
    <ProTable<ProcessingExceptions>
      style={{
        overflowX: "auto"
      }}
      columns={columns}
      dataSource={props?.processingMessages}
      rowKey="id"
      search={false}
      pagination={false}
      options={false}
      toolBarRender={() => [
        <Button onClick={() => {
          onOpenDrawer()
        }} key="button" icon={<PlusOutlined />} type="primary">
            Position Mapping
        </Button>
      ]}
    />
    <PositionDrawer drawerVisit={drawervisit} setDrawerVisit={setDrawerVisit} props2={props?.payload} />
    </>
  )
}
export default ProcessingExceptionTable;