/* eslint-disable array-callback-return */
import { PageContainer,ProCard} from "@ant-design/pro-components";
import {message} from 'antd';
import {useEffect,useState} from 'react';
import Board from "react-trello";
import { lanes } from "./utils/lanes.utils";
import { tradeProxyAPI,validationAPI} from "../../api";
import CustomCard from "./components/CustomCard";

const data = {
  lanes
  }

type ArrayType={
    created_at: string;
    data: any;
    exceptions: null;
    type: string;
    custodian_name: string;
    updated_at: string | null;
    name: string;
    client_id: string;
    stage: string;
    status: string;
    client_name: string | null;
    custodian_id:string;
    _key: string;
}




const TradesValidation = () => {
    const [showBoard,setShowBoard]=useState<boolean>(false);
    const [tradesData,setTradesData] = useState<ArrayType[]>();

    const components = {
      Card: CustomCard
    }

    const maaping= (tradeData: ArrayType[])=>{
      data.lanes.map((data)=> data.cards=[]);
      
      data.lanes.map((data)=>{
        
         tradeData.map((trade)=>{
          if(trade.stage===data.id){
            data.cards.push({id: `${trade._key}`, title: `${trade.client_id}`, label: `${trade.custodian_name}`, description:`${trade.name}` , data:trade.data} );
          }
        })
      })      
    }

    
    const fetchproxyData=async()=>{
     try {
      const response= tradeProxyAPI.getproxyData();
      return (await response);
     } catch (error) {
     }
    }

    const handleDragEnd = (key: any,sourceLaneId: any, targetLaneId: any, position: any, cardDetails: any) => {
      const submitData = {
        id:key,
        stage: targetLaneId
      }
      try{
        validationAPI.create(submitData);
      }catch(err){
        message.error('failed');
      }
    }

    
    useEffect(()=>{
      fetchproxyData().then((res)=>{
        setTradesData(res)
        return maaping(res)
      }).then(()=>setShowBoard(true));
    },[])

 

return (<PageContainer
    style={{
      marginTop: 60,
    }}
    fixedHeader
    header={{
      title: 'Trades Validation',
    }}
  >
    
    {showBoard &&
    <ProCard
    style={{
        marginTop: '2em'
    }}
    title="Board"
    bordered
    >
        <Board
        draggable 
        data={data}
        hideCardDeleteIcon={ true}
        handleDragEnd={handleDragEnd}
        components={components}
        style={
            {
                backgroundColor: 'transparent',
            }
        }/>
    </ProCard>
    }
  </PageContainer>)
}
  
  
  export default TradesValidation;