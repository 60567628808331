import { PageContainer } from '@ant-design/pro-components'
import React from 'react'
import TickerStatements from './components/TickerStatements'

function TickerMapping() {
  return (
    <PageContainer
    style={{
        marginTop: 20
    }}
    

     header={{
        title: "Ticker Mapping",
      }}
    >

   <TickerStatements/>
    </PageContainer>
  )
}

export default TickerMapping