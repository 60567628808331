export const CUSTODIAN_MAPPING = [
  {
    custodian_id: 3,
    custodian_name: "Bank Julius Baer & Co. Ltd",
    custodian_code: "BJB",
  },
  {
    custodian_id: 4,
    custodian_name: "Bank of Singapore, Singapore",
    custodian_code: "BOS",
  },
  {
    custodian_id: 5,
    custodian_name: "Bendura Bank Ag. Liechtenstein",
    custodian_code: "Bendura",
  },
  {
    custodian_id: 6,
    custodian_name: "Deutsche Bank Singapore",
    custodian_code: "DB",
  },
  {
    custodian_id: 7,
    custodian_name: "Credit Suisse Private Banking Singapore",
    custodian_code: "CS",
  },
  {
    custodian_id: 8,
    custodian_name: "Standard Chartered Singapore",
    custodian_code: "SCB",
  },
  {
    custodian_id: 9,
    custodian_name: "Saxo",
    custodian_code: "Saxo",
  },
  {
    custodian_id: 10,
    custodian_name: "Sarasin",
    custodian_code: "Sarasin",
  },
  {
    custodian_id: 11,
    custodian_name: "AMC",
    custodian_code: "AMC",
  },
  {
    custodian_id: 12,
    custodian_name: "LGT Bank (Singapore) Ltd",
    custodian_code: "LGT",
  },
  {
    custodian_id: 13,
    custodian_name: "HSBC",
    custodian_code: "HSBC",
  },
  {
    custodian_id: 14,
    custodian_name: "Union Bancaire Privee Singapore",
    custodian_code: "UBP",
  },
  {
    custodian_id: 15,
    custodian_name: "EFG Bank AG Singapore",
    custodian_code: "EFG",
  },
  {
    custodian_id: 16,
    custodian_name: "CA Indosuez Singapore",
    custodian_code: "CAI",
  },
  {
    custodian_id: 17,
    custodian_name: "Banque Pictet & Cie SA Switzerland",
    custodian_code: "BP",
  },
  {
    custodian_id: 18,
    custodian_name: "BNP Paribas Wealth Management Hong Kong Branch",
    custodian_code: "BNPHK",
      },
  {
    custodian_id: 19,
    custodian_name: "HSBC Singapore",
    custodian_code: "HSBCDG",
  },
  {
    custodian_id: 20,
    custodian_name: "Julius Baer Singapore",
    custodian_code: "JBS",
  },
  {
    custodian_id: 33,
    custodian_name: "Vangaurd",
    custodian_code: "VANGAURD",
  },
  {
    custodian_id: 34,
    custodian_name: "Citibank IPB Singapore",
    custodian_code: "CITIIPBSG",
  },
  {
    custodian_id: 35,
    custodian_name: "Morgan Stanley US",
    custodian_code: "MSUS",
  },
  {
    custodian_id: 36,
    custodian_name: "Deutsche Bank, FX",
    custodian_code: "DBFX",
  },
  {
    custodian_id: 37,
    custodian_name: "REYL Finance (MEA) Ltd",
    custodian_code: "REYL",
  },
  {
    custodian_id: 38,
    custodian_name: "Goldman Sachs International",
    custodian_code: "GSI",
  },
  {
    custodian_id: 39,
    custodian_name: "Goldman Sachs Company",
    custodian_code: "GSC",
  },
  {
    custodian_id: 40,
    custodian_name: "DBS Singapore",
    custodian_code: "DBS",
  },
  {
    custodian_id: 41,
    custodian_name: "BNP Paribas Wealth Management XX",
    custodian_code: "BNPXX",
  },
  {
    custodian_id: 42,
    custodian_name: "Interactive Brokers",
    custodian_code: "ITB",
  },
]

