import { EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Button, Form, message,} from 'antd';
import { tradeProxyAPI } from '../../../api';
import { useParams } from 'react-router-dom';
import DrawerDetail from '../../RefinaryInitiate/components/DrawerDetail';
import {
  ModalForm,
  ProForm,
  ProCard,
  ProFormText,
} from '@ant-design/pro-components';


interface DataModalProps {
    columns: any;
    record: any; 
    setRefresh: (data: any) => void;
    onSave?: () => void;
    id:any
}
const EditModalRef = (props: DataModalProps) => {
    const {key} = useParams();
    const [form] = Form.useForm<any>();
    const [columns, setColumns] = useState<string[]>(props.columns);
    return (
        <ModalForm<{
          name: string;
          company: string;
          _id: string
        }>
          width='90%'
          style={{
            height:'100%',
          }}
          title="Edit Refinery Data"
          trigger={
               <EditOutlined  style={{marginLeft:"1rem", color:"#00cefff7"}}/> 
          }
          form={form}
          autoFocusFirstInput
          modalProps={{
            // onCancel: () => console.log('run'),
          }}
          submitTimeout={2000}
          onFinish={async (values) => {
            // console.log('key',key,props.record.id,values)
            await tradeProxyAPI.putProxyData(key as string,props.record.id,values)
            if(props?.onSave){
                props.onSave();
            await props?.setRefresh(values)
            return true;
          }}}
        >
           <ProCard split='vertical'>
              <ProCard title = "Edit Data" colSpan="60%">  
                <ProForm.Group>
                  <ProFormText width="sm" name="id" label="ID" disabled={true} initialValue={props.record.id} />
                    {columns?.length === 0 ? null : (
                      columns?.map?.((column:any) => {
                        return (
                          <ProFormText width="sm" name={column} label={column} initialValue={props.record[column]}/>
                         )
                    })
                 )}
                </ProForm.Group>
              </ProCard>
              <ProCard colSpan="40%">
              <DrawerDetail id={props.id} />
              </ProCard>
            </ProCard>
        </ModalForm>
    );
}

export default EditModalRef;