import React from 'react';
import { HeartOutlined, SmileOutlined } from '@ant-design/icons';
import {  MenuDataItem} from '@ant-design/pro-components';
import { ProLayout } from '@ant-design/pro-components';
import {Routes,Route, useNavigate} from 'react-router-dom'
import ClientMonthlyPage from './Routes/ClientMonthly';
// import PositionParsing from './Routes/PositionParsing/PositionParsing.componenent';
import Logo from './logo';
import UploadStatement from './Routes/Refinary/statements';
import { defaultMenus } from './routes';
import PrivateRoutes from './Routes/PrivateRoutes';
import SelectCustomer from './Routes/landingPage/SelectCustomer';
import RefinaryExe from './Routes/RefinaryInitiate/RefinaryExe';
import TickerMapping from './Routes/TickerMapping/TickerMapping';
import PriceMapping from './Routes/PriceMapping/PriceMapping';
import TradesValidation from './Routes/TradesValidation/TradesValidation';
import PositionValidationPage from './Routes/PositionsValidation/PositionValidationPage';
import TradeParsing from './Routes/TradeParsing/TradeParsing';
import DetailsPage from './Routes/Details/DetailsPage';
import { EthanBoard } from './Routes/PositionBoard';
import BlotterListTable from './Routes/BlotterList/BlotterListTable';
import TickerParsing from './Routes/TickerParsing/TickerParsing';
import PositionParsing from './Routes/PositionParsing/PositionParsing';

const IconMap : any = {
  "smile": <SmileOutlined />,
  "heart": <HeartOutlined />,
};


const loopMenuItem = (menus: any[]): MenuDataItem[] =>
  menus.map(({ icon, routes, ...item }) => ({
    ...item,
    icon: icon && IconMap[icon],
    children: routes && loopMenuItem(routes),
}));

const App = () => {
  const navigate = useNavigate();
  
  return (<ProLayout
    style={{
      height: "100vh",
      minHeight: 500,
      backfaceVisibility: "hidden",
      
    }}
    logo={Logo}
    title="EYE"
    fixSiderbar
    menu={{ request: async () => {
            return loopMenuItem(defaultMenus);
    }}}
    menuItemRender={(item, dom) => (
      <div
        onClick={() => {
          navigate(item.path || '/statements');
        }}
      >
        {dom}
      </div>
    )}

    menuExtraRender={({collapsed})=> 
      <>
      { !collapsed && 
          <SelectCustomer show={false}/>
      }
      </>

  }
  >
    <Routes>
      <Route element={<PrivateRoutes />}>
      <Route path="/client-monthly" element={<ClientMonthlyPage />}/>
      <Route path="/parsing-mapping" element={<PositionParsing   />} />
      <Route index path="/statements" element={<UploadStatement />} />
      <Route index path="/trade-parsing" element={<TradeParsing />} />
      <Route index path="/ticker-mapping" element={<TickerMapping />} />
      <Route path="/price-mapping" element={<PriceMapping/>} />
      <Route path="/trades-validation" element={<TradesValidation />}/>
      <Route path="/position-board" element={<EthanBoard />}/>
      <Route path="/blotter" element={<BlotterListTable />} />
      <Route path="/positions-validation-page" element={<PositionValidationPage />}/>
      {/* <Route path="/trade-validation/:id" element={<RefinaryExe/>} /> */}
      <Route path="/:key/details" element={<DetailsPage />}/>
      <Route path="/security-mapping" element={<TickerParsing />}/>
      </Route>
      <Route path="/no-client-selected" element={<SelectCustomer show={true}/>} />
    </Routes>
  </ProLayout>
);
      }
export default App;